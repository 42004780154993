import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { MenuItem, TextField, TextFieldProps, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
import { FormLoader, SectionContent } from '../components';
import { red, green } from '@mui/material/colors';
import { AuthenticatedContext } from "../contexts/authentication";
import { updateValue, useRest } from '../utils';
import { GameData } from '../project/types';
import * as DbApi from '../project/api';
import * as AuthenticationApi from '../api/authentication';
import { ACCESS_TOKEN } from "../api/endpoints";
import { TeamContext } from '../project/SchoolContext';
import { Outlet } from 'react-router-dom';
import React from 'react';

export const Item = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '60px'
}));

export const HomeItem = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: green[500],
  height: '60px'
}));

export const GuestItem = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: red[500],
  height: '60px'
}));

export const sendActionCmd: Function = (gameId: number, action: string, params: string = "") => {
  // Call sendKeyEvent with the action, gameId and params
  console.debug("Sending action: %s with params %s for game %d", action, params, gameId);
  DbApi.sendKeyEvent(gameId, action, params);
};

export interface SelectedItemValue {
  item: number;
}
const selectedItemValue: SelectedItemValue = { item: 0 };
export const SelectedItemContext = React.createContext<SelectedItemValue>(selectedItemValue);

// TODO: This isn't used. Leaving it for now to allow us to see the access token as the page is loaded.
const reqHeaders = new Headers();
reqHeaders.append('Content-Type', 'application/json');
reqHeaders.append('Authorization', 'Bearer ' + AuthenticationApi.getAccessToken());

reqHeaders.forEach((value, key) => console.log(key + ': ' + value));

const ControllerLayout: FC = () => { 
  const { selectedTeam } = useContext(TeamContext);
  let teamId = selectedTeam?.id;
  if (teamId === 0 || teamId === undefined) { 
    // Read selectedTeamId from local storage
    teamId = parseInt(localStorage.getItem('selectedTeamId') || '0');
  }
  const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadData, saving, data, errorMessage
  } = useRest<GameData[]>({ read: DbApi.readGameList, param1: teamId.toString() });
  const [gameId, setGameId] = useState(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { me, token } = useContext(AuthenticatedContext);
  console.log("Controller Layout: " + me.name + " " + token);
  localStorage.setItem(ACCESS_TOKEN, token);

  const gamesField = useRef<TextFieldProps>(null);
  const changeGame = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.log("Game changed to %s", event.target.value);
      setGameId(event.target.value);
    }
  };

  const schoolMap = useMemo (() => new Map<number, GameData>(), []);

  useEffect(() => {
    console.log("Game Main data changed - in useEffect.");
    console.log("Me = ", me);
    if (data) {
      var i = 0;
      var idSet = false;
      for (i = 0; i < data?.length; i++) {
        if (!gameId && !idSet) {
          setGameId(data[i].id);
          idSet = true;
          console.log("Setting data to " + data[i].id);
        }
        schoolMap.set(data[i].id, data[i]);
      }
    }
    else {
      console.log("No data yet");
    }
  }, [data, schoolMap, gameId, me]);


  const listGames = (game: GameData) => {
    return (
      <MenuItem
        key={game.id}
        value={game.id}
      >
        {new Date(game.gameDate).toDateString() + " - " + game.homeName + " " +  (game.homeMascot || '') + " vs. " + game.guestName + " " + (game.guestMascot || '')}
      </MenuItem>
    );
  };
  
  if (!data) {
    return (<FormLoader onRetry={loadData} errorMessage={errorMessage} />);
  }
  if (!Array.isArray(data)) {
      return (<FormLoader onRetry={loadData} errorMessage={errorMessage} />);
  }

  return (

  <SectionContent title=''>
    <SelectedItemContext.Provider value={{item: gameId}} >
      <TextField
        inputRef={gamesField}
        name="gamesList"
        label="Select Game"
        fullWidth
        select
        onChange={changeGame}
        // variant="standard"
        value={gameId || ''}
        margin="normal"
      >
        {data.map(listGames)}
      </TextField>
      <Outlet />
    </SelectedItemContext.Provider>
  </SectionContent>
)};

export default ControllerLayout;
