import React, { FC, RefObject, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
// icons
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// localization providers for datetime pickers
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from '@azure/msal-react';
import { CustomNavigationClient } from "./utils/NavigationClient";
import { AuthenticationContext } from './contexts/authentication';

import CustomTheme from './CustomTheme';
import AppRouting from './AppRouting';


export interface AppProps {
  msalInstance: PublicClientApplication;
}

const App: FC<AppProps> = ({msalInstance}) => {
  const notistackRef: RefObject<any> = React.createRef(); 
  // Configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  // NOTE: We can remove these 3 lines if we never let MSAL redirect between pages in our app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  msalInstance.setNavigationClient(navigationClient);
  const authenticationContext = useContext(AuthenticationContext);

  const onClickDismiss = (key: string | number | undefined) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  // Handle the redirect flows
  msalInstance.handleRedirectPromise()
    .then((tokenResponse) => {
      // Handle redirect response
      console.debug('MSAL handleRedirectPromise() tokenResponse: ', tokenResponse);
      if (tokenResponse !== null && tokenResponse.accessToken !== null) {
        // Call our signIn() function to update the signed in user and redirect to the home page
        if (authenticationContext.signIn !== undefined) {
          authenticationContext.signIn(tokenResponse.accessToken);
          msalInstance.setActiveAccount(tokenResponse.account);
        }
      }
    })
    .catch((error) => {
    // Handle redirect error
      console.log('MSAL handleRedirectPromise() error: ', error);
    });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs.utc}>
      <MsalProvider instance={msalInstance}>
        <CustomTheme>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            ref={notistackRef}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)} size="small">
                <CloseIcon />
              </IconButton>
            )}
          >
            <AppRouting />
          </SnackbarProvider>
        </CustomTheme>
      </MsalProvider>
    </LocalizationProvider>
  );
};

export default App;
