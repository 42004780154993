import React, { FC, useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useLayoutTitle, FormLoader } from '../components';
import { GameRecord, CompetitorData } from './types';
import { GameContext, TeamContext, DatabaseAccessContext } from './SchoolContext';
// import GameAddForm from './GameAddForm';
import { AuthenticatedContext } from '../contexts/authentication';
import { ACCESS_TOKEN } from "../api/endpoints";

const GameMain: FC = () => {
  useLayoutTitle("Manage Games");
  const navigate = useNavigate();
  const { selectedTeam } = useContext(TeamContext);
  const { enqueueSnackbar } = useSnackbar();
  const authenticatedContext = useContext(AuthenticatedContext);
  const db = useContext(DatabaseAccessContext).competitorDb;
  // const gameDb = useContext(DatabaseAccessContext).gameDb;

  localStorage.setItem(ACCESS_TOKEN, authenticatedContext.token);

  const [selectedGame, setSelectedGame] = useState<GameRecord>();

  const selectGame = useCallback(async (game: GameRecord) => {
    setSelectedGame(game);
    if (authenticatedContext.me.admin && game.id > 0) {
      navigate('setup/' + game.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, authenticatedContext.me.admin]);

  const deselectGame = useCallback(() => {
    setSelectedGame(undefined);
  }, []);

  const competitorMap = useMemo (() => new Map<number, CompetitorData>(), []);

  // #region [Effects]
  // Initial load of list of items
  /*
  useEffect(() => {
    (async () => {
    console.log("*Main: Initial load of items. tid: ", selectedTeam?.id.toString() || "0");
    await db.getList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  */

  useEffect(() => {
    // If db.status or db.resultMessage changes, set a snackbar message to display the resultMessage
    if (db.status && db.resultMessage) {
      enqueueSnackbar(db.resultMessage, { variant: db.status as any });
      db.clearStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.status, db.resultMessage]);

  // Update the list of competitors when selectedTeam changes
  useEffect(() => {
    console.log("GameMainForm: selectedTeam changed: ", selectedTeam);
    if (selectedTeam) {
      db.getList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam]);

  useEffect(() => {
    console.log("Game Main data changed - in useEffect.");
    if (db.list) {
      var i = 0;
      for (i = 0; i < db.list?.length; i++) {
        competitorMap.set(db.list[i].id, db.list[i]);
      }
    }
  }, [db.list, competitorMap]);
  // #endregion

  const content = () => {
    if (!db.list) {
      return (<FormLoader onRetry={() => db.getList()} errorMessage={ db.resultMessage ? "Retry loading list of competitors ..." : undefined } />);
    }

    return (
      <GameContext.Provider
        value={{
          selectedGame,
          selectGame,
          deselectGame,
          competitorMap: competitorMap
        }}
      >
        <>
          <Outlet />
        </>
      </GameContext.Provider>
    );
  };

  return (
    <>
    {content()}
    </>
    );

};

export default GameMain;
