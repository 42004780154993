import { FC, useCallback, useContext, useEffect } from 'react';
import { Navigate, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';

import * as AuthenticationApi from './api/authentication';
import { AXIOS } from './api/endpoints';
import { Layout, RequireAdmin } from './components';

import { EventType, EventMessage, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from '@azure/msal-react';
import { b2cPolicies } from './authConfig';
import { AuthenticatedContext } from "./contexts/authentication";
import { initDB } from 'react-indexed-db-hook';
import { DBConfig } from './project/DbDefs';
import { Me } from './types';
import RequireRole from './components/routing/RequireRole';

// Path roots
import TeamMain from './project/TeamMain';
import OpponentMain from './project/OpponentMain';
import GameMain from './project/GameMain';
import PlayerMain from './project/PlayerMain';
import SponsorMain from './project/SponsorMain';
import Security from './framework/security/Security';
import ControllerLayout from './Layouts/ControllerLayout';
// Pages
import TeamListForm from './project/TeamListForm';
import OpponentListForm from './project/OpponentListForm';
import OpponentSetupForm from './project/OpponentSetupForm';
import PlayerListForm from './project/PlayerListForm';
import PlayerSetupForm from './project/PlayerSetupForm';
import PlayerBulkAddForm from './project/PlayerBulkAddForm';
import SponsorListForm from './project/SponsorListForm';
import SponsorSetupForm from './project/SponsorSetupForm';
import GameListForm from './project/GameListForm';
import GameSetupForm from './project/GameSetupForm';
import ManageUsersForm from './framework/security/ManageUsersForm';
import SecuritySettingsForm from './framework/security/SecuritySettingsForm';
import ScoreController from './Pages/Controllers/ScoreController';
import FootballScoreController from './Pages/Controllers/FootballScoreControl';
import HomeMain from './Pages/Home/HomeMain';

/**
 * Compare the token issuing policy with a specific policy name
 * @param {object} idTokenClaims - Object containing the claims from the parsed token
 * @param {string} policyToCompare - ID/Name of the policy as expressed in the Azure portal
 * @returns {boolean}
 */
export function compareIssuingPolicy(idTokenClaims: any, policyToCompare: string) {
  let tfpMatches = idTokenClaims.hasOwnProperty('tfp') && idTokenClaims['tfp'].toLowerCase() === policyToCompare.toLowerCase();
  let acrMatches = idTokenClaims.hasOwnProperty('acr') && idTokenClaims['acr'].toLowerCase() === policyToCompare.toLowerCase();
  return tfpMatches || acrMatches;
}

initDB(DBConfig);

const AuthenticatedRouting: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const msal = useMsal();
  const { me } = useContext(AuthenticatedContext);
  let noRole: Me = {
    sub: '', advScbd: false, gameMgr: false, sponMgr: false, stats: false,
    rostMgr: false
  };

  useEffect(() => {
    const callbackId = msal.instance.addEventCallback(async (event: EventMessage) => {
        if (
            (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
            event.payload && "account" in event.payload
        ) {
            /**
             * For the purpose of setting an active account for UI update, we want to consider only the auth
             * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
             * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
             * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if ("idTokenClaims" in event.payload && compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
              // Handle the profile edit flow
                // retrieve the account from initial sign-in to the app
                const originalSignInAccount = msal.instance
                    .getAllAccounts()
                    .find(
                        (account) =>
                            account.idTokenClaims !== undefined && event.payload && "idTokenClaims" in event.payload &&
                            event.payload.idTokenClaims !== undefined && "oid" in event.payload.idTokenClaims
                            && account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                            account.idTokenClaims.sub === event.payload.idTokenClaims.sub && 
                            compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)        
                    );

                let signUpSignInFlowRequest = {
                    authority: b2cPolicies.authorities.signUpSignIn.authority,
                    account: originalSignInAccount,
                };

                // silently login again with the signUpSignIn policy
                msal.instance.ssoSilent(signUpSignInFlowRequest);
            }

            /**
             * Below we are checking if the user is returning from the reset password flow.
             * If so, we will ask the user to reauthenticate with their new password.
             * If you do not want this behavior and prefer your users to stay signed in instead,
             * you can replace the code below with the same pattern used for handling the return from
             * profile edit flow
             */
            if ("idTokenClaims" in event.payload && compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)
                    && msal.inProgress === InteractionStatus.None) {
                let signUpSignInFlowRequest = {
                    authority: b2cPolicies.authorities.signUpSignIn.authority,
                    scopes: [ ...['offline_access']],
                };
                if (msal.inProgress !== InteractionStatus.None) {
                  console.log("ERROR!: MSAL Interaction in progress: " + msal.inProgress);
                }
                await msal.instance.handleRedirectPromise();
                msal.instance.loginRedirect(signUpSignInFlowRequest);
            }
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
            // Check for forgot password error
            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
            /*
            if (event.error && "errorMessage" in event.error && event.error.errorMessage.includes('AADB2C90118')) {
                const resetPasswordRequest = {
                    authority: b2cPolicies.authorities.forgotPassword.authority,
                    scopes: ['openid', 'offline_access'],
                };
                // msal.instance.loginRedirect(resetPasswordRequest);
            }
            */
        }
    });

    return () => {
        if (callbackId) {
            msal.instance.removeEventCallback(callbackId);
        }
    };
    // eslint-disable-next-line
}, [msal.instance]);


  const handleApiResponseError = useCallback((error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      AuthenticationApi.storeLoginRedirect(location);
      navigate("/unauthorized");
    }
    return Promise.reject(error);
  }, [location, navigate]);

  useEffect(() => {
    const axiosHandlerId = AXIOS.interceptors.response.use((response) => response, handleApiResponseError);
    return () => AXIOS.interceptors.response.eject(axiosHandlerId);
  }, [handleApiResponseError]);

  return (
    <Layout>
      <Routes>
        <Route path="/home" element={<HomeMain />} />
        <Route path="/myteams/*" element={<TeamMain />} >
          <Route path="list" element={<TeamListForm />} />
          <Route path="setup/:id" element={<RequireRole children={<OpponentSetupForm />} role={{...noRole, gameMgr: true }} />} />
          <Route path="add/:id" element={<RequireRole children={<OpponentSetupForm />} role={{...noRole, gameMgr: true }} />} />
          <Route path="*" element={<Navigate replace to="list" />} />
        </Route>
        <Route path="/opponents/*" element={<OpponentMain />} >
          <Route path="list" element={<OpponentListForm />} />
          <Route path="setup/:id" element={<RequireRole children={<OpponentSetupForm />} role={{...noRole, gameMgr: true }} />} />
          <Route path="add/:id" element={<RequireRole children={<OpponentSetupForm />} role={{...noRole, gameMgr: true }} />} />
          <Route path="*" element={<Navigate replace to="list" />} />
        </Route>
        <Route path="/games/*" element={<GameMain />} >
          <Route path="list" element={<GameListForm />} />
          <Route path="setup/:id" element={<RequireRole children={<GameSetupForm />} role={{...noRole, gameMgr: true }} />} />
          <Route path="add/:id" element={<RequireRole children={<GameSetupForm />} role={{...noRole, gameMgr: true }} />} />
          <Route path="*" element={<Navigate replace to="list" />} />
        </Route>
        <Route path="/players/*" element={<PlayerMain />} >
          <Route path="list" element={<PlayerListForm />} />
          <Route path="setup/:id" element={<RequireRole children={<PlayerSetupForm />} role={{...noRole, rostMgr: true }} />} />
          <Route path="add/:id" element={<RequireRole children={<PlayerSetupForm />} role={{...noRole, rostMgr: true }} />} />
          <Route path="bulkadd" element={<RequireRole children={<PlayerBulkAddForm />} role={{...noRole, rostMgr: true }} />} />
          <Route path="*" element={<Navigate replace to="list" />} />
        </Route>
        <Route path="/sponsors/*" element={<SponsorMain />} >
          <Route path="list" element={<SponsorListForm />} />
          <Route path="setup/:id" element={<RequireRole children={<SponsorSetupForm />} role={{...noRole, sponMgr: true }} />} />
          <Route path="add/:id" element={<RequireRole children={<SponsorSetupForm />} role={{...noRole, sponMgr: true }} />} />
          <Route path="*" element={<Navigate replace to="list" />} />
        </Route>
        <Route path="/control" element={<ControllerLayout />} >
          <Route path="score" element={<ScoreController />} >
            <Route path="football" element={<FootballScoreController />} />
          </Route>
          <Route path="*" element={<Navigate replace to="score" />} />
        </Route>
        {me.admin && (
          <Route path="/security/*" element={<RequireAdmin><Security /></RequireAdmin>}>
            <Route path="users" element={<ManageUsersForm />} />
            <Route path="settings" element={<SecuritySettingsForm />} />
            <Route path="*" element={<Navigate replace to="users" />} />
          </Route>
        )}
        <Route path="/*" element={<Navigate to={AuthenticationApi.getDefaultRoute(me)} />} />
      </Routes>
    </Layout>
  );
};

export default AuthenticatedRouting;
