type UpdateEntity<S> = (
  state: ((prevState: Readonly<S>) => S)
) => void;

export const numberValue = (value: number) => isNaN(value) ? "" : value.toString();

export const numbersOnly = (value: string) => { 
  var numOnly = value.replace(/\D/g,'');
  console.log("Numbers: ", numOnly);
  return isNaN(parseInt(numOnly)) ? "" : value.toString();}

export const extractEventValue = (event: React.ChangeEvent<HTMLInputElement>) => {
  switch (event.target.type) {
    case "number":
      return event.target.valueAsNumber;
    case "checkbox":
      return event.target.checked;
    default:
      return event.target.value;
  }
};

export const updateValue = <S>(updateEntity: UpdateEntity<S>) => (
  (event: React.ChangeEvent<HTMLInputElement>) => {
    updateEntity((prevState) => ({
      ...prevState,
      [event.target.name]: extractEventValue(event)
    }));
  }
);
