import { FC, useContext, useEffect, useState } from 'react';

import { Button, TextField, Typography } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

import { updateValue } from '../utils';
import { BrandData, ImageLocationItem } from './types';
import { TeamContext, DatabaseAccessContext, DatabaseAccessContextValue } from './SchoolContext';
// import { useSnackbar } from 'notistack';
// import { AuthenticatedContext } from "../contexts/authentication";
import { ImageSelectorProps } from './ImageSelector';
import ImageSelectorComponent from './ImageSelector';
// import { RenderTeamProps } from './TeamLayout';
import { isEqual } from 'lodash';

export interface BrandEditorProps {
  brand: BrandData;
  uuid: string;
  open: boolean;
  setOpen: (value: boolean) => void;
  onUpdateNotify: (item: BrandData) => void;
};

const updateBrandReferencesInCache = async (brand: BrandData, db: DatabaseAccessContextValue) => {
  const competitors = await db.competitorDb.getList();
  const teams = await db.teamDb.getList();
  if (competitors) {
    competitors.forEach((competitor) => async () => {
      if (competitor.brandId === brand.id) {
        await db.competitorDb.update({ ...competitor, brand: brand }, false, "", true);
      }
    });
    db.competitorDb.clearStatus();
  };
  if (teams) {
    teams.forEach((team) => async () => {
      if (team.competitor.brandId === brand.id) {
        await db.teamDb.update({ ...team, competitor: {...team.competitor, brand: brand}}, false, "", true);
      }
    });
    db.teamDb.clearStatus();
  }
};

export async function SaveOrUpdateBrand(brand: BrandData, db: DatabaseAccessContextValue, cid: number) {
  console.debug("SaveOrUpdateBrand: ", brand);
  let newBrand = { ...brand };
  if (brand.id === 0) {
    // Set the cid to the user's cid, and save the new brand to the database
    brand.cid = cid || 0;
    newBrand = await db.brandDb.create(brand, false);
  } else {
    newBrand = await db.brandDb.update(brand, false);
    // Now we need to update any competitors that are using this brand using the useDatabase.update method with the updateCacheOnly option.
    await updateBrandReferencesInCache(newBrand, db);
  }
  db.brandDb.clearStatus();
  return newBrand;
};

const BrandEditor: FC<BrandEditorProps> = ({brand, uuid, open, setOpen, onUpdateNotify}) => {
  const { selectedTeam } = useContext(TeamContext);
  // const db = useContext(DatabaseAccessContext);
  const [initialized, setInitialized] = useState(false);
  const brandDb = useContext(DatabaseAccessContext).brandDb;
  const db = useContext(DatabaseAccessContext);
  // const brandDb = db.brandDb;
  // Because we are editing an arbitray brand while another may be selected in the OpponentSetupForm, we need to keep track of the brand we are editing
  // separately from the useDatabase hook.
  // startingBrand is the initial brand object passed in to the component. It is used to initialize and compare to the localBrand object.
  const [startingBrand, setStartingBrand] = useState<BrandData>(brand);
  // localBrand is the brand object we are editing. It is initialized to the brand object passed in, and updated as the user makes changes.
  const [localBrand, setLocalBrand] = useState<BrandData>(brand);
  const isModified = !isEqual(localBrand, startingBrand);

  const id = open ? 'brand-editor-dialog' : undefined;

  // const { me } = useContext(AuthenticatedContext);
  // const { enqueueSnackbar } = useSnackbar();

  console.debug("BrandEditor: ", brandDb, brand, "open: ", open, "initialized: ", initialized);

  // #region [Side Effects]
  // If selectedTeam changes navigate back to list
  useEffect(() => {
    console.debug("BrandEditor: selectedTeam changed: ", selectedTeam, initialized);
    if (initialized) {
      // Should we do anything here, or leave it for the hosting component?
    };
    return () => {
      // Do any needed cleanup work
    };
   }, [selectedTeam, initialized]);

  useEffect(() => {
    if (!initialized) {
      if (brand && localBrand !== brand) {
        setLocalBrand(brand);
        if (brand.id === 0) {
          console.debug("BrandEditor: useEffect: NEW brand initialized: ", brand);
          setStartingBrand({...brand, name: "New Team", mascot: "Mascot"});
        } else {
          console.debug("BrandEditor: useEffect: Existing brand initialized: ", brand);
          setStartingBrand(brand);
        }
      }
      // TODO: Modify this to look at whether startingBrand == brand. When the component is first loaded the brand is null
      // We should mark the dialog as initialized when the brand is not null and the startingBrand is set to be the same as the brand.
      console.debug("BrandEditor: useEffect: brand initialized: ", brand, localBrand);
      if (open && localBrand !== null && localBrand === brand)
        setInitialized(true);
    }
  }, [initialized, setInitialized, brand, localBrand, open]);
  // #endregion

  // #region [Event Handlers]
  const updateBrand = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("BrandEditor: updateBrand: ", event.target.name, event.target.value, initialized);
      // let newBrand = { ...localBrand, [event.target.name]: event.target.value };
      setLocalBrand({...localBrand, [event.target.name]: event.target.value});
      // setIsModified(true);
    }
  };

  const saveBrand = async (event: any) => {
    console.debug("BrandEditor: saveBrand: ", localBrand);
    // let newBrand = { ...localBrand };
    let newBrand = await SaveOrUpdateBrand(localBrand, db, selectedTeam?.cid || 0);
    if (onUpdateNotify !== null && onUpdateNotify !== undefined) {
      onUpdateNotify(newBrand);
    }
    if (localBrand.id === 0) {
      // Set the cid to the user's cid, and save the new brand to the database
      if (newBrand.id > 0) {
        setStartingBrand(newBrand);
        setLocalBrand(newBrand);
        // enqueueSnackbar("Brand created successfully", { variant: 'success' });
      }
    } else {
      // NOTE: If the brand update fails in useDatabase, the update method will return the selected brand, not the updated brand.
      // As a result, th isEqual comparison will fail if the update fails.
      if (isEqual(newBrand, localBrand)) {
        setStartingBrand(newBrand);
        setLocalBrand(newBrand);
      }
    }
  }
  // #endregion

  // #region [New Brand Creation]
  // Event handler for creating a new brand. This is only half of the update. Here we trigger the update of the Branding table.
  // The other half is in the useEffect below, which updates the Competitor object with the new brand id. That change to the Competitor
  // is not immediately saved to the database. The user must click the Save button on the Competitor section to save the changes to 
  // the Competitor object.
  /*
  const createNewBrand = useCallback(async (event: any) => {
    console.debug("Creating new brand as ", db.brandDb.data);
    setNewBrandRequested(true);
    db.brandDb.setData({ ...db.brandDb.data, id: 0 });
    db.brandDb.setAction('create');
  }, [db.brandDb.data, db.brandDb.setData, db.brandDb.setAction]);

  // This useEffect is triggered when the brandDb.data is updated and the newBrandRequested flag is set. After the new brand is created
  // and the database returns the new brand id, we need to update the Competitor object with that id.
  useEffect(() => {
    if (db.competitorDb.data && db.brandDb.data && db.competitorDb.data.brandId !== db.brandDb.data.id && newBrandRequested) {
      console.debug("BrandEditor: useEffect: brandId changed: ", db.competitorDb.data, db.brandDb.data);
      db.competitorDb.setData({ ...db.competitorDb.data, brandId: db.brandDb.data.id, brand: db.brandDb.data });
      setNewBrandRequested(false);
    }
  }, [db.brandDb.data, db.competitorDb.data, newBrandRequested]);
  */
  // #endregion

  // #region [UI Component Props]
  const imageSelectProps = {
    folder: "teams",
    label: "Team Logo",
    image: localBrand?.logo,
//     filter: {key: "", value: ""},
    onSelect: (item: ImageLocationItem) => { 
      setLocalBrand({ ...localBrand, logo: item.name });
    },
  } as ImageSelectorProps;

  // #endregion

  // #region [Brand Content UI]
  const brandEditorContent = () => {
    console.debug("BrandEditor: rendering brandContent: ", localBrand);
    if (!localBrand) {
      return (
        <></>
      );
    }
    return (
      <Dialog sx={{zIndex:150}} open={open} id={id} maxWidth={'xs'}>
        <DialogTitle id="edit-brand-dialog-title">Edit Brand Details</DialogTitle>
        <DialogContent>
          <Typography maxWidth={300} variant="h6" color="textSecondary" component="p">
            for {startingBrand.name + " " + startingBrand.mascot}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            NOTE: Changes you make to the branding below will impact all teams using the 
              { } {startingBrand.name} {startingBrand.mascot}
              { } brand. If you want to create a new brand close this dialog and click the Copy botton.
          </Typography>
          <TextField
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            value={localBrand.name || ""}
            onChange={updateBrand}
            margin="normal"
          />
          <TextField
            name="mascot"
            label="Mascot"
            fullWidth
            variant="outlined"
            value={localBrand.mascot || ""}
            onChange={updateBrand}
            margin="normal"
          />
          <TextField
            name="color"
            type="color"
            label="Color"
            fullWidth
            variant="outlined"
            value={localBrand.color || "#000000"}
            onChange={updateBrand}
            margin="normal"
          />
          <ImageSelectorComponent {...imageSelectProps} />
        </DialogContent>
        <DialogActions>
            <Button
              startIcon={<CancelIcon />}
              disabled={brandDb.saving}
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {setOpen(false); setLocalBrand(null); setInitialized(false);}}
            >
              Cancel
            </Button>
            <Button
              startIcon={<SaveIcon />}
              disabled={brandDb.saving || !isModified}
              variant="contained"
              color="primary"
              type="submit"
              onClick={(event) => {
                saveBrand(event);
              }}
            >
              Save
            </Button>
          </DialogActions>
      </Dialog>
    );
  }
  // #endregion

  // #region [Content]
  return (
    <>
      {brandEditorContent()}
    </>
);
  // #endregion
};

export default BrandEditor;
