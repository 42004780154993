import { LogLevel } from "@azure/msal-browser";

const redirectUri = process.env.REACT_APP_MSAL_REDIRECT_URL ? process.env.REACT_APP_MSAL_REDIRECT_URL : 'https://app.evanscreekmedia.com/signin';

export const b2cPolicies = {
  names: {
      signUpSignIn: 'B2C_1_AdminSISU',
      forgotPassword: 'B2C_1_Reset',
      editProfile: 'B2C_1_Profile',
  },
  authorities: {
      signUpSignIn: {
          authority: 'https://evanscreekmedia.b2clogin.com/evanscreekmedia.onmicrosoft.com/B2C_1_AdminSISU',
      },
      forgotPassword: {
          authority: 'https://evanscreekmedia.b2clogin.com/evanscreekmedia.onmicrosoft.com/B2C_1_Reset',
      },
      editProfile: {
          authority: 'https://evanscreekmedia.b2clogin.com/evanscreekmedia.onmicrosoft.com/B2C_1_Profile',
      },
  },
  authorityDomain: 'evanscreekmedia.b2clogin.com',
};

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig = {
  auth: {
      clientId: '3d152069-3848-4e7a-9ab9-107567b8132e', // This is the ONLY mandatory field that you need to supply.
      authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
      knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
      redirectUri: redirectUri, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: redirectUri, // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
      cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
      loggerOptions: {
          LogLevel: LogLevel.Verbose,
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      return;
              }
          },
      },
  },
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = {
  apiTodoList: {
      endpoint: 'http://localhost:2020/api',
      scopes: {
          read: ['https://evanscreekmedia.onmicrosoft.com/ecm/Rtc.read'],
          write: ['https://evanscreekmedia.onmicrosoft.com/ecm/Rtc.write'],
      },
  },
};

/**
 * Scopes listed here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  // scopes: [...protectedResources.apiTodoList.scopes.read, ...protectedResources.apiTodoList.scopes.write],
  scopes: [...['openid', 'profile', 'offline_access', 'https://evanscreekmedia.onmicrosoft.com/ecm/Data.Read.All']],
};