import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Button, List, ListItemButton } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { SectionContent, FormLoader, ButtonRow } from '../components';
import { CompetitorData } from './types';
import { TeamContext, DatabaseAccessContext } from './SchoolContext';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedContext } from "../contexts/authentication";
import { ACCESS_TOKEN } from "../api/endpoints";
import  RenderTeam, {RenderTeamProps}  from './TeamLayout';

const OpponentListForm: FC = () => {
  const navigate = useNavigate();
  const { selectedTeam } = useContext(TeamContext);
  const { me, token } = useContext(AuthenticatedContext);
  const db = useContext(DatabaseAccessContext);
  const [initialized, setInitialized] = useState(false);

//  const [cancelled, setCancelled] = useState(false);
  // const [selectedTeam, setSelectedTeam] = useState(JSON.parse(localStorage.getItem('selectedTeam')) || null);
  // Update the list of competitors when selectedTeam changes
  useEffect(() => {
    console.log("CompetitorListForm: selectedTeam changed: ", selectedTeam, " Current dbParam: ", db.competitorDb.param, db.brandDb.param);
    if (selectedTeam) {
      const initDataForNewTeam = async () => {
        if (selectedTeam.id > 0) {
          if (db.competitorDb.param !== selectedTeam.id.toString()) {
            db.competitorDb.setParam(selectedTeam.id.toString());
          }
          // NOTE: The Brand table does not nead the param set because it is not filtered by team id
          /*
          if (db.brandDb.param !== selectedTeam.id.toString()) {
            db.brandDb.setParam(selectedTeam.id.toString());
          }
          */
          await db.competitorDb.getList(true, selectedTeam.id.toString());
          await db.brandDb.getList(true, selectedTeam.id.toString());
          setInitialized(true);
        }
        navigate('../list');
      }
      initDataForNewTeam();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, db.competitorDb.param, db.brandDb.param]);

  // console.log("OpponentListForm: " + me.name + " " + token);
  console.log("OpponentListForm:", db.competitorDb);
  localStorage.setItem(ACCESS_TOKEN, token);

  // const updateFormValue = updateValue(setData);

  /*
  useEffect(() => {
    // If db.status or db.resultMessage changes, set a snackbar message to display the resultMessage
    if (db.competitorDb.status && db.competitorDb.resultMessage) {
      enqueueSnackbar(db.competitorDb.resultMessage, { variant: db.competitorDb.status as any });
    }
    if (db.competitorDb.httpStatus != 0) {
      console.debug("HTTP Status: ", db.competitorDb.httpStatus);
    }
  }, [db.competitorDb.status, db.competitorDb.resultMessage, db.competitorDb.httpStatus]);
  */

  const handleAdd = () => {
    console.log("Launching setup page to add a new competitor");
    navigate("../add/0");
  };

  const handleClick = useCallback(async (competitor: CompetitorData) => {
    console.log("Clicked on ", competitor);
    await db.competitorDb.getList(true, competitor.tid.toString());
    await db.brandDb.getList(true, competitor.tid.toString());
    navigate("../setup/" + competitor.id.toString());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderCompetitor = (competitor: CompetitorData) => {
    const props: RenderTeamProps = {
      competitor: competitor,
      brand: competitor.brand,
      uuid: me?.cid
    };
    return (
      <ListItemButton
        key={competitor.id}
        sx={{bgcolor: competitor.brand.color + "80"}}
        onClick={() => {handleClick(competitor);}}
      >
        {RenderTeam(props)}
      </ListItemButton>
    );
  };

  const retryLoad = () => {
    db.competitorDb.getList(true);
  };

  const content = () => {
    if (!db.competitorDb.list || !initialized) {
      return (<FormLoader onRetry={retryLoad} errorMessage={ db.competitorDb.resultMessage ? "Retry loading list of competitors ..." : undefined } />);
    }
    if (!Array.isArray(db.competitorDb.list)) {
        return (<FormLoader onRetry={retryLoad} errorMessage={ db.competitorDb.resultMessage ? "Retry loading list of competitors ..." : undefined } />);
    }

    return (
      <>
        <List dense={true} sx={{bgcolor: '#00000040'}}>
            <div>
              {db.competitorDb.list.map(renderCompetitor)}
            </div>
        </List>
        <ButtonRow mt={1}>
          <Button startIcon={<AddBoxIcon />} variant="contained" color="primary" type="button" onClick={handleAdd}>
            Add
          </Button>
        </ButtonRow>
      </>
    );
  };

  return (
    <SectionContent title='Competitors' titleGutter>
      {content()}
    </SectionContent>
  );
};

export default OpponentListForm;
