import React, { FC, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useLayoutTitle } from '../components';
import { TeamContext, DatabaseAccessContext } from './SchoolContext';
import { useSnackbar } from 'notistack';

const PlayerMain: FC = () => {
  useLayoutTitle("Manage Players");

  const { selectedTeam } = useContext(TeamContext);
  const { enqueueSnackbar } = useSnackbar();
  const db = useContext(DatabaseAccessContext);

  // const [selectedPlayer, setSelectedPlayer] = useState<PlayerData>();
  // Initial load of list of players
  useEffect(() => {
    (async () => {
    console.log("PlayerMain: Initial load of players. tid: ", selectedTeam?.id.toString() || "0");
    await db.playerDb.getList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If db.status or db.resultMessage changes, set a snackbar message to display the resultMessage
    if (db.playerDb.status && db.playerDb.resultMessage) {
      enqueueSnackbar(db.playerDb.resultMessage, { variant: db.playerDb.status as any });
      db.playerDb.clearStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.playerDb.status, db.playerDb.resultMessage]);

  return (
    <>
      <Outlet />
    </>
  );

};

export default PlayerMain;
