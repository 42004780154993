import { FC, useContext } from 'react';

import { List } from '@mui/material';
// import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SchoolIcon from '@mui/icons-material/School';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import KeyboardAltIcon from '@mui/icons-material/KeyboardAlt';
import PersonIcon from '@mui/icons-material/Person';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { AuthenticatedContext } from '../contexts/authentication';

// import { PROJECT_PATH } from '../api/env';
import LayoutMenuItem from '../components/layout/LayoutMenuItem';

const ProjectMenu: FC = () => {
  const authenticatedContext = useContext(AuthenticatedContext);
  // console.debug("ProjectMenu: authenticatedContext.me", authenticatedContext.me);
  return (
  <List>
    <LayoutMenuItem icon={SchoolIcon} label="Opponents" to={`/opponents/list`} disabled={!(authenticatedContext.me.gameMgr || authenticatedContext.me.admin)} />
    <LayoutMenuItem icon={AccountTreeIcon} label="Games" to={`/games/list`} disabled={!(authenticatedContext.me.gameMgr || authenticatedContext.me.admin)} />
    <LayoutMenuItem icon={PersonIcon} label="Players" to={`/players/list`} disabled={!(authenticatedContext.me.rostMgr || authenticatedContext.me.admin)} />
    <LayoutMenuItem icon={LocalAtmIcon} label="Sponsors" to={`/sponsors/list`} disabled={!(authenticatedContext.me.sponMgr || authenticatedContext.me.admin)} />
    <LayoutMenuItem icon={KeyboardAltIcon} label="Controller" to={`/control/score/football`} />
  </List>
)};

export default ProjectMenu;
