import { AxiosPromise, AxiosResponse } from "axios";
// import { IndexedDB } from "react-indexed-db-hook";

import { AXIOS, AXIOSAPI } from "../api/endpoints";
import { Action, GameData, GameRecord, LiveClock, SchoolData, PlayerData, SponsorData, TeamData, 
  CompetitorData, BrandData, ImageLocationItem, defaultBrand, defaultCompetitor, defaultTeam,  
  CurrentItem, OverlayTimeouts, defaultTimeouts } from "./types";

export function readLiveClock(): AxiosPromise<LiveClock> {
  return AXIOS.get('/clock');
}

export function readSchoolData(param: string): AxiosPromise<SchoolData> {
  return AXIOS.get('/db/school/' + param);
}

export function readSchoolList(): AxiosPromise<SchoolData[]> {
  return AXIOS.get('/db/schools');
}

export function updateSchoolData(schoolData: SchoolData, id: string): AxiosPromise<SchoolData> {
  return AXIOS.put('/db/school/' + id, schoolData);
}

export function createSchoolData(schoolData: SchoolData): AxiosPromise<SchoolData> {
  return AXIOS.post('/db/school', schoolData);
}

export function deleteSchoolRecord(schoolId: string): AxiosPromise<void> {
  return AXIOS.delete('/db/school/' + schoolId);
}

//  doesn't appear to be called anywhere...
export function readGameData(gameId: string): AxiosPromise<GameData> {
  return AXIOS.get('/db/game/' + gameId);
}

// functionally the same as readGameData since ?basic query no longer supported
// also note: the only reference where this is used might not ever actually be called
export function readGameRecord(gameId: string): AxiosPromise<GameRecord> {
  return AXIOS.get('/db/game/' + gameId + "?basic");
}

// this method is where we actually get most of the game data from on the client side
export function readGames(param: string): AxiosPromise<GameRecord[]> {
  return AXIOS.get('/db/games?tid=' + param);
}

export function readGameList(param: string): AxiosPromise<GameData[]> {
  return AXIOS.get('/db/gamelist?tid=' + param);
}

export function updateGameRecord(gameRecord: GameRecord, gameId: string): AxiosPromise<GameRecord> {
  console.log("updateGameRecord", gameRecord.id, gameRecord.vs, gameRecord.home, gameRecord.date, gameId);
  return AXIOS.put('/db/game/' + gameId, gameRecord);
}

export function createGameData(gameRecord: GameRecord): AxiosPromise<GameRecord> {
  return AXIOS.post('/db/game', gameRecord);
}

export function deleteGameRecord(gameId: string): AxiosPromise<void> {
  return AXIOS.delete('/db/game/' + gameId);
}

export function gameDataApi(action: Action, data?: GameRecord, id?: number, param?: string): AxiosPromise<GameRecord | GameRecord[] | void> {
  const tid = param || "0";
  id = id || 0;
  data = data || { id: 0, cid: 0, tid: 0, home: false, vs: 0, date: "", location: "", dataSource: "" };
  switch (action) {
    case "list":
      return readGames(tid);
    case "read":
      return readGameRecord(id.toString());
    case "create":
      return createGameData(data);
    case "update":
      return updateGameRecord(data, id.toString());
    case "delete":
      return deleteGameRecord(id.toString());
    default:
      return new Promise<AxiosResponse<void>>((resolve) => {
        resolve({ data: undefined, status: 400, statusText: "Requested action not supported", headers: {}, config: {
          headers: undefined
        } });
      });
  }
}

export function deleteSchoolLogo(filename: string): AxiosPromise<void> {
  return AXIOS.delete('/db/logo/' + filename);
}

export function uploadSchoolLogo(file: File, name: string): AxiosPromise<File> {
  return AXIOS.post('db/logo/' + name, file);
}

export function readPlayerData(param: string, param2: string): AxiosPromise<PlayerData> {
  return AXIOS.get('/db/player/' + param + '?tid=' + param2);
}

export function readPlayerList(param: string): AxiosPromise<PlayerData[]> {
  return AXIOS.get('/db/players?tid=' + param);
}

export function updatePlayerData(playerData: PlayerData, id: string): AxiosPromise<PlayerData> {
  return AXIOS.put('/db/player/' + id, playerData);
}

export function createPlayerData(playerData: PlayerData): AxiosPromise<PlayerData> {
  return AXIOS.post('/db/player', playerData);
}

export function deletePlayerData(playerId: string, teamId: string): AxiosPromise<void> {
  return AXIOS.delete('/db/player/' + playerId + '?tid=' + teamId);
}

export function playerDataApi(action: Action, data?: PlayerData, id?: number, param?: string): AxiosPromise<PlayerData | PlayerData[] | void> {
  const tid = param || "0";
  id = id || 0;
  data = data || { id: 0, cid: 0, tid: 0, name: "", jersey: 0, image: "", position: "", height: "", weight: 0, year: 0, school: "" };
  switch (action) {
    case "list":
      return readPlayerList(tid);
    case "read":
      return readPlayerData(id.toString(), tid);
    case "create":
      return createPlayerData(data);
    case "update":
      return updatePlayerData(data, id.toString());
    case "delete":
      return deletePlayerData(id.toString(), tid);
    default:
      return new Promise<AxiosResponse<void>>((resolve) => {
        resolve({ data: undefined, status: 400, statusText: "Requested action not supported", headers: {}, config: {
          headers: undefined
        } });
      });
  }
}

export function readSponsorData(param: string): AxiosPromise<SponsorData> {
  return AXIOS.get('/db/sponsor/' + param);
}

export function readSponsorList(): AxiosPromise<SponsorData[]> {
  return AXIOS.get('/db/sponsors');
}

export function updateSponsorData(sponsorData: SponsorData, id: string): AxiosPromise<SponsorData> {
  return AXIOS.put('/db/sponsor/' + id, sponsorData);
}

export function createSponsorData(data: SponsorData): AxiosPromise<SponsorData> {
  return AXIOS.post('/db/sponsor', data);
}

export function deleteSponsorData(id: string): AxiosPromise<void> {
  return AXIOS.delete('/db/sponsor/' + id);
}

export function sponsorDataApi(action: Action, data?: SponsorData, id?: number): AxiosPromise<SponsorData | SponsorData[] | void> {
  id = id || 0;
  data = data || { id: 0, cid: 0, tid: 0, name: "", image: "" };
  switch (action) {
    case "list":
      return readSponsorList();
    case "read":
      return readSponsorData(id.toString());
    case "create":
      return createSponsorData(data);
    case "update":
      return updateSponsorData(data, id.toString());
    case "delete":
      return deleteSponsorData(id.toString());
    default:
      return new Promise<AxiosResponse<void>>((resolve) => {
        resolve({ data: undefined, status: 400, statusText: "Requested action not supported", headers: {}, config: {
          headers: undefined
        } });
      });
  }
}

export function readImageList(folder: string, uuid: string): AxiosPromise<ImageLocationItem[]> {
  return AXIOS.get('/image/' + folder, { params: { uuid: uuid } });
}

export function deleteImage(folder: string, filename: string): AxiosPromise<void> {
  return AXIOS.delete('/image/' + folder + '/' + filename);
}

// APIs to perform REST CRUD operations on Team and Brand data
export function readTeamData(param: string): AxiosPromise<TeamData> {
  return AXIOS.get('/db/team/' + param);
}

export function readTeamList(): AxiosPromise<TeamData[]> {
  return AXIOS.get('/db/teams');
}

export function updateTeamData(data: TeamData, id: string): AxiosPromise<TeamData> {
  return AXIOS.put('/db/team/' + id, data);
}

export function createTeamData(data: TeamData): AxiosPromise<TeamData> {
  return AXIOS.post('/db/team', data);
}

export function deleteTeamData(id: string): AxiosPromise<void> {
  return AXIOS.delete('/db/team/' + id);
}

export function teamDataApi(action: Action, data?: TeamData, id?: number): AxiosPromise<TeamData | TeamData[] | void> {
  id = id || 0;
  data = data || { id: 0, cid: 0, name: "", isDefault: false, compId: 0, competitor: defaultCompetitor};
  switch (action) {
    case "list":
      return readTeamList();
    case "read":
      return readTeamData(id.toString());
    case "create":
      return createTeamData(data);
    case "update":
      return updateTeamData(data, id.toString());
    case "delete":
      return deleteTeamData(id.toString());
    default:
      return new Promise<AxiosResponse<void>>((resolve) => {
        resolve({ data: undefined, status: 400, statusText: "Requested action not supported", headers: {}, config: {
          headers: undefined
        } });
      });
  }
}

export function readBrandData(param: string): AxiosPromise<BrandData> {
  if (param === "0") { 
    return new Promise<AxiosResponse<BrandData>>((resolve) => {
      resolve({
        data: defaultBrand,
        status: 200,
        statusText: "OK",
        headers: {},
        config: {
          headers: undefined
        }
      });
    });
  }
  return AXIOS.get('/db/branding/' + param);
}

export function readBrandList(): AxiosPromise<BrandData[]> {
  return AXIOS.get('/db/branding');
}

export function updateBrandData(data: BrandData, id: string): AxiosPromise<BrandData> {
  if (data.id === 0) {
    return AXIOS.post('/db/branding', data);
  } else {
    return AXIOS.put('/db/branding/' + data.id, data);
  }
}

export function createBrandData(data: BrandData): AxiosPromise<BrandData> {
  data.id = 0;
  return AXIOS.post('/db/branding', data);
}

export function deleteBrandData(id: string): AxiosPromise<void> {
  return AXIOS.delete('/db/branding/' + id);
}

export function brandDataApi(action: Action, data?: BrandData, id?: number, param?: string): AxiosPromise<BrandData | BrandData[] | void> {
  id = id || 0;
  data = data || defaultBrand;
  switch (action) {
    case "list":
      return readBrandList();
    case "read":
      return readBrandData(id.toString());
    case "create":
      return createBrandData(data);
    case "update":
      return updateBrandData(data, id.toString());
    case "delete":
      return deleteBrandData(id.toString());
    default:
      return new Promise<AxiosResponse<void>>((resolve) => {
        resolve({ data: undefined, status: 400, statusText: "Requested action not supported", headers: {}, config: {
          headers: undefined
        } });
      });
  }
}

export function readCompetitorData(param: string, param2: string): AxiosPromise<CompetitorData> {
  if (param === "0" || param === "undefined") {
    return new Promise<AxiosResponse<CompetitorData>>((resolve) => {
      resolve({
        data: defaultCompetitor,
        status: 200,
        statusText: "OK",
        headers: {},
        config: {
          headers: undefined
        }
      });
    });
  }
  return AXIOS.get('/db/competitor/' + param + '?tid=' + param2);
}

export function readCompetitorList(param: string): AxiosPromise<CompetitorData[]> {
  return AXIOS.get('/db/competitors?tid=' + param);
}

export function updateCompetitorData(data: CompetitorData, id: string): AxiosPromise<CompetitorData> {
  if (data.id === 0) {
    return AXIOS.post('/db/competitor', data);
  } else {
    return AXIOS.put('/db/competitor/' + id, data);
  }
}

export function createCompetitorData(data: CompetitorData): AxiosPromise<CompetitorData> {
  if (data.brandId !== 0) {
    data.brand = null;
  }
  return AXIOS.post('/db/competitor', data);
}

export function deleteCompetitorData(id: string, tid: string): AxiosPromise<void> {
  return AXIOS.delete('/db/competitor/' + id + '?tid=' + tid);
}

export function competitorDataApi(action: Action, data?: CompetitorData, id?: number, param?: string): AxiosPromise<CompetitorData | CompetitorData[] | void> {
  const tid = param || "0";
  id = id || 0;
  data = data || defaultCompetitor;
  switch (action) {
    case "list":
      return readCompetitorList(tid);
    case "read":
      return readCompetitorData(id.toString(), tid);
    case "create":
      return createCompetitorData(data);
    case "update":
      return updateCompetitorData(data, id.toString());
    case "delete":
      return deleteCompetitorData(id.toString(), tid);
    default:
      return new Promise<AxiosResponse<void>>((resolve) => {
        resolve({ data: undefined, status: 400, statusText: "Requested action not supported", headers: {}, config: {
          headers: undefined
        } });
      });
  }
}

// APIs for triggering actions on the game-time overlays
// API for game-time highlight and ad overlay updates
export function updateOverlay(gameId: number, itemId: number, updateType: string): void {
  // Verify that updateType is either "hilight" or "ad"
  if (updateType !== "hilight" && updateType !== "ad") {
    console.error("updateOverlay error: Invalid updateType: ", updateType);
    return;
  }
  let team: TeamData = defaultTeam;
  try {
    team = JSON.parse(localStorage.getItem("selectedTeam") || "{id: 0, cid: 0, name: \"\", isDefault: false, compId: 0, competitor: null}");
  } catch (error) {
    console.error("updateOverlay error: No team selected: ", error);
  }
  // TODO: Read global configuration from IndexedDb
  console.debug("updateOverlay " + updateType, gameId, itemId, team);
  // Create a CurrentItem object named body to send to the server
  let body: CurrentItem = { id: itemId, cid: team.cid, tid: team.id, requester: "updateOverlay"};
  AXIOS.put('/db/' + updateType + '/' + gameId, body)
    .then((response) => {
      console.debug("updateOverlay " + updateType, response);
      if (response.status === 200 && itemId !== 0) {
        // The update was successfully sent to server, set a timer to hide it after timeout seconds
        let timeoutsJson = localStorage.getItem("overlayTimeouts") || '{"hilight": 5, "ad": 10, "goal": 5, "penalty": 5, "timeout": 5}';
        let timeouts: OverlayTimeouts = defaultTimeouts;
        try {
          timeouts = JSON.parse(timeoutsJson);
        } catch (error) {
          console.error("updateOverlay error: Invalid timeouts: ", error);
        }
        let timeout = timeouts[updateType];
        var interval = setInterval(() => {
          body = { id: 0, cid: team.cid, tid: team.id, requester: ""};
          AXIOS.put('/db/' + updateType + '/' + gameId, body)
            .then((response) => {
              console.debug("updateOverlay timeout response: ", response);
              if (response.status === 200) {
                console.debug("updateOverlay timeout ", "success");
              }
              clearInterval(interval);
            })
            .catch((error) => {
              console.error("updateOverlay timeout error: ", error);
              clearInterval(interval);
            });
      }, timeout * 1000);
    }})
    .catch((error) => {
      console.error("updateOverlay show error:", error);
    });
}

// API for sending "virtual key" events to the server
export function sendKeyEvent(gameId: number, key: string, params: string): boolean {
  let team: TeamData = defaultTeam;
  let succeeded: boolean = false;
  try {
    team = JSON.parse(localStorage.getItem("selectedTeam") || "{id: 0, cid: 0, name: \"\", isDefault: false, compId: 0, competitor: null}");
  } catch (error) {
    console.error("sendKeyEvent error: No team selected: ", error);
  }
  console.debug("sendKeyEvent " + gameId, team, key, params);
  let url = key + '/' + gameId + (!!params ? '/' + params : '') + 
    '?cid=' + team.cid + '&tid=' + team.id;
  AXIOSAPI.get(url)
  .then((response) => {
    console.debug("sendKeyEvent", response);
    if (response.status === 200) {
      console.debug("sendKeyEvent", "success");
      succeeded = true;
    } else {  
      console.error("sendKeyEvent failed: ", response.statusText);
    }
  })
  .catch((error) => {
    console.error("sendKeyEvent error:", error);
  });
  return succeeded;
}