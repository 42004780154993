// #region [Imports]
import { FC, useContext, useEffect, useState } from 'react';
import { Button, List, ListItemText, ListItemButton, Stack, Grid, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SectionContent, FormLoader, ButtonRow } from '../components';
import { getDateTimeString } from '../utils';
import { GameRecord, defaultGame, defaultCompetitor } from './types';
import { GameContext, TeamContext, DatabaseAccessContext } from './SchoolContext';
import { AuthenticatedContext } from '../contexts/authentication';
import { useNavigate, Link } from 'react-router-dom';
import { ACCESS_TOKEN } from "../api/endpoints";
import  RenderTeam, {RenderTeamProps}  from './TeamLayout';
// #endregion

// setup time locale stuff
dayjs.extend(utc);
dayjs.extend(timezone);

const GameListForm: FC = () => {
  // #region [State Init]
  const authenticatedContext = useContext(AuthenticatedContext);
  const { selectedTeam } = useContext(TeamContext);
  const gameContext = useContext(GameContext);
  // const competitors = Array.from(gameContext.competitorMap.values()).filter((c) => c.id !== selectedTeam?.id);

  const db = useContext(DatabaseAccessContext).gameDb;
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();
  localStorage.setItem(ACCESS_TOKEN, authenticatedContext.token);
  // const [teamCompId, setTeamCompId] = useState<number>(0);
  // #endregion

  // #region [Callbacks]
  const handleAdd = () => {
    console.info("Launching add page");
    // TODO: Can we eliminate the gameContext by using the dbContext?
    gameContext.selectGame(defaultGame);
    navigate("../add/0");
  };
  // #endregion

  // #region [Helper Methods]
  const format = (game: GameRecord) => {
    game.date = getDateTimeString(dayjs(game.date).utc(true).local());
    return game;
  }
  // #endregion

  // Update the list of games when selectedTeam changes
  // #region [effects]
  useEffect(() => {
    console.info("*ListForm: selectedTeam changed: ", selectedTeam, " Current dbParam: ", db.param);
    if (selectedTeam) {
      const initDataForNewTeam = async () => {
        if (selectedTeam.id > 0) {
          if (db.param !== selectedTeam.id.toString()) {
            db.setParam(selectedTeam.id.toString());
          }
          await db.getList(true, selectedTeam.id.toString());
          // TEST: Should we wait to set initialized until after the list is loaded?
          setInitialized(true);
        }
        navigate('../list');
      }
      initDataForNewTeam();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, db.param]);
  // #endregion

  // #region [Render Method]
  const renderGame = (game: GameRecord) => {
    console.debug("Team ID:", selectedTeam.id, "rendering game", game.id, game);
    if (!selectedTeam || !gameContext.competitorMap.get(game.vs)) {
      gameContext.competitorMap.set(game.vs, defaultCompetitor);
      console.warn("Invalid school added to schools map for school " + game.vs);
      return (<></>);
    }
    // Populate home team RenderTeamProps
    const homeProps: RenderTeamProps = {
      competitor: gameContext.competitorMap.get((game.home) ? selectedTeam.compId || 0 : game.vs),
      brand: gameContext.competitorMap.get((game.home) ? selectedTeam.compId || 0 : game.vs).brand,
      uuid: authenticatedContext.me.cid
    };
    // Populate away team RenderTeamProps
    const awayProps: RenderTeamProps = {
      competitor: gameContext.competitorMap.get((game.home) ? game.vs : selectedTeam.compId || 0),
      brand: gameContext.competitorMap.get((game.home) ? game.vs : selectedTeam.compId || 0).brand,
      uuid: authenticatedContext.me.cid
    };
    return (
      <ListItemButton
        sx={{bgcolor: '#000000c0'}}
        key={game.id}
        onClick={() => { if (authenticatedContext.me.admin) gameContext.selectGame(game); }}
      >
        <Grid container alignItems={'center'} sx={{bgcolor: '#000000c0'}}>
          <Grid item xs={2}>
            <Stack direction="column" spacing={0}>
              <ListItemText sx={{color: 'white', margin: 0 }} primary={
                <Typography variant='body2' sx={{textAlign: 'center'}}>
                  {dayjs(game.date).utc(false).local().format('MM-DD-YY hh:mmA')}
                </Typography>
                } />
              <ListItemText sx={{color: 'white'}} secondary={
                <Typography variant='body2' textAlign='center'>
                  {game.location || "(Location not set)"}
                </Typography>
                } />
            </Stack>
          </Grid>
          <Grid item xs={4}>
            {RenderTeam(awayProps)}
          </Grid>
          <Grid item xs={1}>
            <ListItemText sx={{color: 'white'}} primary={
              <Typography variant='body2' sx={{textAlign: 'center'}}>
                {"at"}
              </Typography>
              } />
          </Grid>
          <Grid item xs={5}>
            {RenderTeam(homeProps)}
          </Grid>
        </Grid>
      </ListItemButton>
    );
  };
  // #endregion

  // #region [Content]
  const content = () => {
    if (!db.list && !initialized) {
      return (<FormLoader onRetry={() => db.getList()} errorMessage={ db.resultMessage ? "Retry loading list of games ..." : undefined } />);
    }
    if (!Array.isArray(db.list)) {
        return (<FormLoader onRetry={() => db.getList()} errorMessage={ db.resultMessage ? "Retry loading list of games ..." : undefined } />);
    }
    if (gameContext.competitorMap.size < 2) {
      return (
        <>
          <h1>You have no opponents!</h1>
          <p>You need your team and at least one opponent in the database before you can add a game.
            Go to <Link to={`/opponents/list`}>Opponents</Link> to add your team and at least one opponent.
          </p>
        </>
      );
    }

    return (
      <>
        <List dense={true}>
            <div>
              {db.list.filter((g) => g.tid === selectedTeam.id).map(format).map(renderGame)}
            </div>
        </List>
        <ButtonRow mt={1}>
          <Button 
            startIcon={<AddBoxIcon />} 
            disabled={db.saving} 
            variant="contained" 
            color="primary" 
            type="button" 
            onClick={handleAdd}>
            Add
          </Button>
        </ButtonRow>
      </>
    );
  };
  // #endregion

  return (
    <SectionContent title={'Scheduled Games' + (selectedTeam ? ' - ' + selectedTeam?.name : '')} titleGutter>
      {content()}
    </SectionContent>
  );
};

export default GameListForm;
