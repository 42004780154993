import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { Tab } from '@mui/material';

import { RouterTabs, useRouterTab, useLayoutTitle } from '../../components';

const Security: FC = () => {
  useLayoutTitle("Security");

  const { routerTab } = useRouterTab();

  return (
    <>
      <RouterTabs value={routerTab}>
        <Tab value="users" label="Manage Users" />
        <Tab value="settings" label="Security Settings" />
      </RouterTabs>
      <Outlet />
    </>
  );

};

export default Security;
