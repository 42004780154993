import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { FormLoader, useLayoutTitle } from '../components';
import { DatabaseAccessContext } from './SchoolContext';
import { useSnackbar } from 'notistack';

const TeamMain: FC = () => {
  useLayoutTitle("Manage My Teams");

  const { enqueueSnackbar } = useSnackbar();
  const db = React.useContext(DatabaseAccessContext).teamDb;
  const [initialized, setInitialized] = React.useState(db.list !== undefined && db.list.length > 0);

  // Initial load of list of competitors
  useEffect(() => {
    (async () => {
      console.log("TeamMain: Initial load of teams.");
      await db.getList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If db.status or db.resultMessage changes, set a snackbar message to display the resultMessage
    if (db.status && db.resultMessage) {
      enqueueSnackbar(db.resultMessage, { variant: db.status as any });
      db.clearStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.status, db.resultMessage]);

  useEffect(() => {
    setInitialized(db.list !== undefined && db.list.length > 0);
  }, [db.list]);

  const content = () => {
    if (!initialized) {
      return (<FormLoader onRetry={() => db.getList()} errorMessage={db.resultMessage ? "Retry loading list of your teams ..." : undefined} />);
    }
    return (
      <>
        <Outlet />
      </>
    )
  };

  return (
    <>
      {content()}
    </>
  );

};

export default TeamMain;
