import { FC, useContext } from 'react';
import { Navigate } from "react-router-dom";

import { AuthenticatedContext } from '../../contexts/authentication';
import { Me } from '../../types';

interface RequireRoleProps {
  children: React.ReactNode;
  role: Me;
}

const RequireRole: FC<RequireRoleProps> = ({ children, role }) => {
  const authenticatedContext = useContext(AuthenticatedContext);
  let hasRole = authenticatedContext.me.admin;
  if (!hasRole) {
    // Check if the user has the required role based on matching the role prop to the authenticated user's roles
    if (role.admin) {
      hasRole = authenticatedContext.me.admin;
    } else if (role.gameMgr) {
      hasRole = authenticatedContext.me.gameMgr;
    } else if (role.rostMgr) {
      hasRole = authenticatedContext.me.rostMgr;
    } else if (role.sponMgr) {
      hasRole = authenticatedContext.me.sponMgr;
    } else if (role.advScbd) {
      hasRole = authenticatedContext.me.advScbd;
    } else if (role.stats) {
      hasRole = authenticatedContext.me.stats;
    } else {
      hasRole = true;
    }
  }
  console.debug('RequireRole:', role, "Me: ", authenticatedContext.me, 'hasRole: ', hasRole, 'children: ', children);
  return hasRole ? <>{children}</> : <Navigate replace to='..' />;
};

export default RequireRole;
