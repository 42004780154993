// #region [Imports]
import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Avatar, Button, List, ListItemAvatar, ListItemText, ListItemButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import AddBoxIcon from '@mui/icons-material/AddBox';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { SectionContent, FormLoader, ButtonRow } from '../components';
// import { useRest } from '../utils';
import { PlayerData, PlayerGroups } from './types';
import { TeamContext, DatabaseAccessContext } from './SchoolContext';
import { useNavigate } from 'react-router-dom';
import { PLAYER_IMAGE_PATH } from './projConfig';
import { AuthenticatedContext } from "../contexts/authentication";
import { ACCESS_TOKEN } from "../api/endpoints";
// #endregion

// #region [State Init]
const PlayerListForm: FC = () => {
  const navigate = useNavigate();
  // const playerContext = useContext(PlayerContext);
  const { selectedTeam } = useContext(TeamContext);
  const db = useContext(DatabaseAccessContext);
  const { me, token } = useContext(AuthenticatedContext);
  const [ opened, setOpened ] = useState([""]);
  const [initialized, setInitialized] = useState(false);
  localStorage.setItem(ACCESS_TOKEN, token);
  // #endregion
  // #region [effects]
  useEffect(() => {
    console.log("PlayerListForm: selectedTeam changed: ", selectedTeam, " Current dbParam: ", db.playerDb.param);
    if (selectedTeam) {
      const initDataForNewTeam = async () => {
        if (selectedTeam.id > 0) {
          if (db.playerDb.param !== selectedTeam.id.toString()) {
            db.playerDb.setParam(selectedTeam.id.toString());
          }
          await db.playerDb.getList(true, selectedTeam.id.toString());
          setInitialized(true);
        }
        navigate('../list');
      }
      initDataForNewTeam();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, db.playerDb.param]);
  // #endregion

  // #region [Callbacks]
  const handleAdd = () => {
    console.log("Launching setup page to add a new player");
    navigate("../add/0");
  };

  const handleBulkAdd = () => {
    console.log("Launching bulk add page");
    navigate("../bulkadd");
  }

  const handleClick = useCallback(async (player: PlayerData) => {
    console.log("Clicked on ", player);
    await db.playerDb.getList(true, player.tid.toString());
    // await db.playerDb.read(player.id, true, player.tid.toString());
    navigate("../setup/" + player.id.toString());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleToggle = (value: string) => () => {
    const currentIdx = opened.indexOf(value);
    const newOpened = [...opened];
    // toggle value inclusion in list
    if (currentIdx === -1) {newOpened.push(value);}
    else {newOpened.splice(currentIdx, 1);}
    // update list of opened drop-downs
    setOpened(newOpened);
  }
  // #endregion

  // #region [Helper Methods]
  const toGroups = (groups: PlayerGroups) => {
    // use currying to keep groups persistent through calls
    return (player: PlayerData) => {
      if (!(player.school in groups))
        groups[player.school] = [];
      groups[player.school].push(player);
    }
  }

  const _byJersey = (p1: PlayerData, p2: PlayerData) => {
    return p1.jersey - p2.jersey;
  }
  // #endregion

  // #region [Render Method]
  const renderGroups = (players: PlayerData[]) => {
    // group according to 'school' value
    let groups: PlayerGroups = {}
    players.map(toGroups(groups));
    // sort sub-groups by jersey number (just in case?)
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(groups)) {
      value.sort(_byJersey);
    }
    console.debug("rendering groups", groups, players);
    return (
      <div>{Object.entries(groups).map(renderDropdown)}</div>
    )
  }

  const renderDropdown = (entry: [string, PlayerData[]]) => {
    const [name, players] = entry;
    return (
      <div>
        <ListItemButton key={name} sx={{}} onClick={handleToggle(name)}>
          <ListItemText primary={name} sx={{color: 'lightgray'}}/>
          { opened.indexOf(name) !== -1 ? <ExpandLess/> : <KeyboardArrowRightIcon/> }
        </ListItemButton>
        <Collapse in={opened.indexOf(name) !== -1} unmountOnExit>
          <List component="div" disablePadding>
            {players.map(renderPlayer)}
          </List>
          <Divider />
        </Collapse>
      </div>
    );
  }

  const renderPlayer = (player: PlayerData) => {
    return (
      <ListItemButton
        key={player.id}
        sx={{}}
        onClick={() => handleClick(player)}
      >
        <ListItemAvatar>
          <Avatar src={PLAYER_IMAGE_PATH + player.image + "?uuid=" + me.cid} variant="square" >
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{color: 'lightgray'}}
          secondaryTypographyProps={{color: 'common.lightgray'}}
          primary={player.name}
          secondary={player.jersey + "  " + player.position + " (" + player.school + ")"}
        />
      </ListItemButton>
    );
  };
  // #endregion

  // #region [Content]
  const retryLoad = () => {
    db.playerDb.getList(true);
  };

  const content = () => {
    if (!db.playerDb.list || !initialized) {
      return (<FormLoader onRetry={retryLoad} errorMessage={db.playerDb.resultMessage ? "Retry loading player data ..." : undefined} />);
    }
    if (!Array.isArray(db.playerDb.list)) {
        return (<FormLoader onRetry={retryLoad} errorMessage={db.playerDb.resultMessage ? "Retry loading player data ..." : undefined} />);
    }

    return (
      <>
        <List sx={{bgcolor: '#000000a0'}}>
            <div>
              {renderGroups(db.playerDb.list as PlayerData[])}
            </div>
        </List>
        <ButtonRow mt={1}>
          <Button startIcon={<AddBoxIcon />} variant="contained" color="primary" type="button" onClick={handleAdd}>
            Add
          </Button>
          <Button startIcon={<UploadFileIcon />} variant="contained" color="primary" type="button" onClick={handleBulkAdd}>
            Bulk Add
          </Button>
        </ButtonRow>
      </>
    );
  };
  // #endregion

  return (
    <SectionContent title='Players' titleGutter>
      {content()}
    </SectionContent>
  );
};

export default PlayerListForm;
