import { FC, useContext } from 'react';

import { Grid } from '@mui/material';
import { Item, HomeItem, GuestItem, sendActionCmd, SelectedItemContext } from '../../Layouts/ControllerLayout';
import { Outlet } from 'react-router-dom';

const gridStyles = {
  paddingBottom: 2,
};

const ScoreController: FC = () => { 
  const { item } = useContext(SelectedItemContext);
  return (
  <div>
    <Grid container spacing={2} columns={14} sx={gridStyles}>
      { 
        // First Row of Buttons 
      }
      <Grid item xs={2}>
        <HomeItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/homescore', '1'); }} fullWidth>Home<br/>+1</HomeItem>
      </Grid>
      <Grid item xs={2}>
        <HomeItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/homescore', '-1'); }} fullWidth>Home<br/>-1</HomeItem>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'clock/Start'); }} fullWidth>Start</Item>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'clock/adjust', '2'); }} fullWidth>Time<br/>+2</Item>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'clock/adjust', '-2'); }} fullWidth>Time<br/>-2</Item>
      </Grid>
      <Grid item xs={2}>
        <GuestItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/guestscore', '1'); }} fullWidth>Guest<br/>+1</GuestItem>
      </Grid>
      <Grid item xs={2}>
        <GuestItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/guestscore', '-1'); }} fullWidth>Guest<br/>-1</GuestItem>
      </Grid>
      { 
        // Second Row of Buttons 
      }
      <Grid item xs={2}>
        <HomeItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/homescore', '2'); }} fullWidth>Home<br/>+2</HomeItem>
      </Grid>
      <Grid item xs={2}>
        <HomeItem variant='outlined' onClick={() => { 
            sendActionCmd(item, 'score/hometol', 'Down');
            sendActionCmd(item, 'score/timeout', 'Home');
          }} fullWidth>Timeout<br/>&lt;
        </HomeItem>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'clock/Stop'); }} fullWidth>Stop</Item>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'score/quarter', 'Up'); }} fullWidth>Period<br/>+1</Item>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'score/guesttol', 'Reset'); sendActionCmd(item, 'score/hometol', 'Reset'); }} fullWidth>Timeout<br/>Reset</Item>
      </Grid>
      <Grid item xs={2}>
        <GuestItem variant='outlined' onClick={() => { sendActionCmd('item, score/guestscore', '2'); }} fullWidth>Guest<br/>+2</GuestItem>
      </Grid>
      <Grid item xs={2}>
        <GuestItem variant='outlined' onClick={() => { 
            sendActionCmd(item, 'score/guesttol', 'Down');
            sendActionCmd(item, 'score/timeout', 'Guest');
          }} fullWidth>Timeout<br/>&gt;
        </GuestItem>
      </Grid>
    </Grid>
    <Outlet />
  </div>
)};

export default ScoreController;
