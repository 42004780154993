import { FC, useCallback, useContext, useEffect, useState } from 'react';

import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { SectionContent, FormLoader, ButtonRow } from '../components';
import { SponsorData } from './types';
import { TeamContext, DatabaseAccessContext } from './SchoolContext';
import { useNavigate } from 'react-router-dom';
import { SPONSOR_IMAGE_PATH } from './projConfig';
import { AuthenticatedContext } from "../contexts/authentication";
import { ACCESS_TOKEN } from "../api/endpoints";

const SponsorListForm: FC = () => {
  const navigate = useNavigate();
  // const sponsorContext = useContext(SponsorContext);
  const { selectedTeam } = useContext(TeamContext);
  const { me, token } = useContext(AuthenticatedContext);
  const db = useContext(DatabaseAccessContext).sponsorDb;
  const [initialized, setInitialized] = useState(false);

  localStorage.setItem(ACCESS_TOKEN, token);

  // #region [effects]
  useEffect(() => {
    console.log("*ListForm: selectedTeam changed: ", selectedTeam, " Current dbParam: ", db.param);
    if (selectedTeam) {
      const initDataForNewTeam = async () => {
        if (selectedTeam.id > 0) {
          if (db.param !== selectedTeam.id.toString()) {
            db.setParam(selectedTeam.id.toString());
          }
          await db.getList(true, selectedTeam.id.toString());
          setInitialized(true);
        }
        navigate('../list');
      }
      initDataForNewTeam();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, db.param]);
  // #endregion

  const handleAdd = () => {
    console.log("Launching setup page to add a new player");
    navigate("../add/0");
  };

  const handleClick = useCallback(async (selectedItem: SponsorData) => {
    console.log("Clicked on ", selectedItem);
    await db.getList(true, selectedItem.tid.toString());
    navigate("../setup/" + selectedItem.id.toString());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSponsor = (sponsor: SponsorData) => {
    return (
      <ListItem
        key={sponsor.id}
        sx={{}}
        button
        onClick={() => {handleClick(sponsor)}}
      >
        <ListItemAvatar>
          <Avatar src={SPONSOR_IMAGE_PATH + sponsor.image + "?uuid=" + me.cid} variant="square" >
          </Avatar>
        </ListItemAvatar>
        <ListItemText 
          sx={{color: 'lightgray'}}
          secondaryTypographyProps={{color: 'common.lightgray'}}
          primary={sponsor.name}
        />
      </ListItem>
    );
  };

  const content = () => {
    if (!db.list && !initialized) {
      console.debug("SponsorListForm: No sponsor list");
      return (<FormLoader onRetry={() => {db.getList(true)}} errorMessage={(db.resultMessage) ? "Retry loading sponsor list ..." : undefined} />);
    }
    if (!Array.isArray(db.list)) {
      console.debug("SponsorListForm: Sponsor list is not an array.", db.list);
      return (<FormLoader onRetry={() => {db.getList(true)}} errorMessage={(db.resultMessage) ? "Retry loading sponsor list ...": undefined} />);
    }

    return (
      <>
        <List sx={{bgcolor: '#000000c0'}}>
            <div>
              {db.list.map(renderSponsor)}
            </div>
        </List>
        <ButtonRow mt={1}>
          <Button startIcon={<AddBoxIcon />} variant="contained" color="primary" type="button" onClick={handleAdd}>
            Add
          </Button>
        </ButtonRow>
      </>
    );
  };

  return (
    <SectionContent title='Sponsors' titleGutter>
      {content()}
    </SectionContent>
  );
};

export default SponsorListForm;
