
import { FC, useState, useCallback, useEffect } from 'react';

import { TeamContext, DatabaseAccessContext } from './SchoolContext';
import { TeamData, CompetitorData, BrandData, PlayerData, SponsorData, GameRecord,
  defaultTeam, defaultCompetitor, defaultBrand, defaultPlayer, defaultSponsor, defaultGame } from './types';
import { competitorDataApi, brandDataApi, playerDataApi, sponsorDataApi, teamDataApi, gameDataApi } from './api';
import { useDatabase } from '../utils';

const ProjectContext: FC<React.PropsWithChildren> = ( {children} ) => {

  const [selectedTeam, setSelectedTeam] = useState<TeamData>();
  const teamDb = useDatabase<TeamData>({api: teamDataApi, dbName: 'teams', initialData: defaultTeam, param: selectedTeam?.id.toString() || "0"});
  const competitorDb = useDatabase<CompetitorData>({api: competitorDataApi, dbName: 'competitors', initialData: defaultCompetitor, param: selectedTeam?.id.toString() || "0"});
  const brandDb = useDatabase<BrandData>({api: brandDataApi, dbName: 'brands', initialData: defaultBrand, param: selectedTeam?.id.toString() || "0"});
  const playerDb = useDatabase<PlayerData>({api: playerDataApi, dbName: 'players', initialData: defaultPlayer, param: selectedTeam?.id.toString() || "0"});
  const sponsorDb = useDatabase<SponsorData>({api: sponsorDataApi, dbName: 'sponsors', initialData: defaultSponsor, param: selectedTeam?.id.toString() || "0"});
  const gameDb = useDatabase<GameRecord>({api: gameDataApi, dbName: 'games', initialData: defaultGame, param: selectedTeam?.id.toString() || "0"});

  const selectTeam = useCallback(async (team: TeamData) => {
    setSelectedTeam(team);
    localStorage.setItem('selectedTeam', JSON.stringify(team));
    localStorage.setItem('selectedTeamId', team.id.toString());
    console.debug('Selected team:', team, 'setting db param:', team.id.toString());
    // NOTE: The Team table does not nead the param set because it is not filtered by team id
    // teamDb.setParam(team.id.toString());
    competitorDb.setParam(team.id.toString());
    // NOTE: The Brand and Sponsor tables do not nead the param set because they are not currently filtered by team id
    // TODO: When we start filtering brands and/or sponsors by team id, we will need to set the param for those tables
    // brandDb.setParam(team.id.toString());
    // sponsorDb.setParam(team.id.toString());
    playerDb.setParam(team.id.toString());
    gameDb.setParam(team.id.toString());
    await teamDb.getList(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deselectTeam = useCallback(() => {
    // NOTE: We are not clearing the selected team from local storage because we want to remember the last selected team across app reloads
    // localStorage.removeItem('selectedTeam');
    // localStorage.removeItem('selectedTeamId');
    // TODO: Do we need to clear the selected team from the database hooks?
    setSelectedTeam(undefined);
  }, []);

  // const { loadData, saving, data, errorMessage } = useRest<TeamData[]>({ read: readTeamList });

  // The purpose of this Effect is to load the selected team from local storage or select the default team
  // As a result, we only want this to run once when the component is first mounted
  useEffect(() => {
  const updateTeam = async () => {
    let teamList = teamDb.list;
    if (!teamList) {
      teamList = await teamDb.getList(true);
    }
    console.log('Team list:', teamList, selectedTeam);
    if (!selectedTeam && !!teamList) {
      // Load the selected team id from local storage  and select the team with that id
      const selectedTeamId = localStorage.getItem('selectedTeamId');
      if (selectedTeamId) {
        const selectedTeam = teamList.find((team) => team.id === parseInt(selectedTeamId));
        if (selectedTeam) {
          console.log('Selecting team from local storage:', selectedTeam);
          selectTeam(selectedTeam);
        }
      } else {
        // Select the first team with isDefault set to true
        const defaultTeam = teamList.find((team) => team.isDefault);
        if (defaultTeam) {
          console.log('Selecting default team:', defaultTeam);
          selectTeam(defaultTeam);
        }
      }
    }
  };
  updateTeam();
    /*
    if (!data) {
      loadData();
    }
    console.log('Team list:', data, selectedTeam);
    if (!selectedTeam && !!data) {
      // Load the selected team id from local storage  and select the team with that id
      const selectedTeamId = localStorage.getItem('selectedTeamId');
      if (selectedTeamId) {
        const selectedTeam = data.find((team) => team.id === parseInt(selectedTeamId));
        if (selectedTeam) {
          console.log('Selecting team from local storage:', selectedTeam);
          selectTeam(selectedTeam);
        }
      } else {
        // Select the first team with isDefault set to true
        const defaultTeam = data.find((team) => team.isDefault);
        if (defaultTeam) {
          console.log('Selecting default team:', defaultTeam);
          selectTeam(defaultTeam);
        }
      }
    }
    */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatabaseAccessContext.Provider
      value={{
        competitorDb: competitorDb,
        brandDb: brandDb,
        playerDb: playerDb,
        sponsorDb: sponsorDb,
        teamDb: teamDb,
        gameDb: gameDb
      }}
    >
      <TeamContext.Provider
        value={{
          teamList: teamDb.list,
          selectedTeam: selectedTeam,
          selectTeam: selectTeam,
          deselectTeam: deselectTeam
        }}
      >
        { children }
      </TeamContext.Provider>
    </DatabaseAccessContext.Provider>
  );
};

export default ProjectContext;
