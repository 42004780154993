import React, { FC, useContext, useEffect} from 'react';
import { Outlet } from 'react-router-dom';

import { useLayoutTitle } from '../components';
// import { AuthenticatedContext } from '../contexts/authentication';
import { TeamContext, DatabaseAccessContext } from './SchoolContext';
import { useSnackbar } from 'notistack';

const OpponentMain: FC = () => {
  useLayoutTitle("Manage Opponents");

  // const authenticatedContext = useContext(AuthenticatedContext);
  const { selectedTeam } = useContext(TeamContext);
  const { enqueueSnackbar } = useSnackbar();
  const db = useContext(DatabaseAccessContext);

  // Initial load of list of competitors
  useEffect(() => {
    (async () => {
      console.log("OpponentMain: Initial load of competitors. tid: ", selectedTeam?.id.toString() || "0");
      await db.competitorDb.getList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If db.status or db.resultMessage changes, set a snackbar message to display the resultMessage
    if (db.competitorDb.status && db.competitorDb.resultMessage) {
      enqueueSnackbar(db.competitorDb.resultMessage, { variant: db.competitorDb.status as any });
      db.competitorDb.clearStatus();
    }
    if (db.brandDb.status && db.brandDb.resultMessage) {
      enqueueSnackbar(db.brandDb.resultMessage, { variant: db.brandDb.status as any });
      db.brandDb.clearStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.competitorDb.status, db.competitorDb.resultMessage, db.brandDb.status, db.brandDb.resultMessage]);

  return (
    <>
      <Outlet />
    </>
  );

};

export default OpponentMain;
