import React, { FC, useState, useEffect } from "react";
import { Button, Card, CardContent, CardActions, CardActionArea, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { BrandData, defaultBrand } from './types';
import { useSnackbar } from 'notistack';
import RenderTeam from './TeamLayout';
import BrandEditor, { BrandEditorProps } from './BrandEditor';
import { DatabaseHook } from '../utils';
import { FormLoader } from "../components";

export interface BrandSelectorProps {
  // brand: BrandData;
  uuid: string;
  // Function to handle image selection
  onSelect: (item: BrandData) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  brandDb: DatabaseHook<BrandData>;
};

const BrandSelectorComponent: FC<BrandSelectorProps> = ({ uuid, onSelect, open, setOpen, brandDb }) => {
  const { enqueueSnackbar } = useSnackbar();
  // const [brandList, setBrandList] = useState<BrandData[]>([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteBrandData, setDeleteBrandData] = useState(null);
  const [canceled, setCanceled] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [brandToEdit, setBrandToEdit] = useState<BrandData>(null);
  const [initialized, setInitialized] = useState(false);

  console.debug("BrandSelector: ", brandDb);

  // ref to manage the Dialog component's open state
  /*
  const [open, setOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };
  */
  const handleClose = () => {
    setOpen(false);
  };
  const id = open ? 'image-select-dialog' : undefined;

  // Event handler to handle clicks on the Add button
  const handleAddClick = () => {
    console.log("Add clicked"); 
    // Send the default brand to the BrandEditor component
    setBrandToEdit(defaultBrand);
    setEditorOpen(true);
  };

  // Event handler to handle clicks on brands in the list
  const handleBrandClick = (item: BrandData) => {
    console.log("Brand clicked: ", item.name); 
    // Send the selected brand to the parent component
    onSelect(item);
    handleClose();
  };

  /*
  const loadBrands = async () => {
    try {
      const response = await DbApi.readBrandList();
      // Iterate through the response data and add each item to a SelectableImageList array
      if (!response.data || canceled) 
        return;
      setBrandList(response.data);
    } catch (error: any) {
      console.error("Error loading brands: ", error);
    }
  };
  */
  const deleteBrand = async (brand: BrandData) => {
    console.debug("Brand to delete: %s", brand.name + " " + brand.mascot);
    if (!!brand) {
      try {
        let result = await brandDb.delete(brand.id, false);
        console.debug("Brand deleted: %s", brand.name + " " + brand.mascot, "httpStatus: ", result);
        if (result <= 204) {
          enqueueSnackbar("Deleted " + brand.name + " " + brand.mascot, { variant: 'success' });
        } else {
          enqueueSnackbar("Problem deleting " + brand.name + " " + brand.mascot, { variant: 'error' });
        }
        brandDb.clearStatus();
      } catch (error: any) {
        console.debug("Error deleting brand: %s", brand.name + " " + brand.mascot, "Status: ", brandDb.status, "Message: ", brandDb.resultMessage, "Error: ", error);
        const message = 'Problem deleting ' + brand.name + ' ' + brand.mascot + ': ' + error.message;
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
      }
      setConfirmDeleteOpen(false);
      if (!canceled) {
        await brandDb.getList(true);
      }
    }
  };

  // Wrap call to loadBrands in useEffect so it is only called on initial load of component
  useEffect(() => {
    const loadList = async () => {
      console.debug("BrandSelector useEffect: brandDb initializing: ", brandDb);
      let list = await brandDb.getList(true);
      if (list && list.length > 0) {
        console.debug("BrandSelector useEffect: brandDb initialized: ", brandDb);
        setInitialized(true);
      }
    };
    loadList();
    return () => {
      setCanceled(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialized) {
      if (brandDb.list && brandDb.list.length > 0){
        console.debug("BrandSelector useEffect: brandDb initialized: ", brandDb.list);
        setInitialized(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandDb.list]);

  const brandEditorProps: BrandEditorProps = {
    brand: brandToEdit,
    uuid: uuid,
    // brandDb: brandDb,
    open: editorOpen,
    setOpen: setEditorOpen,
    onUpdateNotify: async () => {
      console.debug("BrandEditorProps.onUpdateHook: ", brandDb);
      // This is to allow the OpponentSetup or TeamSetup components hosting the BrandEditor to sync their compound objects with the new brand data
    }
  };

  const toolTipSlotProps = {
      // Add your desired properties here
      popper: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -19],
            },
          },
        ],
      },
  };

  const renderBrandItem = (item: BrandData, index: number) => {
    return (
      <Card key={index} sx={{ maxWidth: 300, margin: 1, borderRadius: '4px' }}>
        <CardActionArea sx={{bgcolor: '#00000080'}} onClick={() => (handleBrandClick(item))}>
          <CardContent>
            <RenderTeam {...{brand: item, uuid: uuid}} />
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Tooltip title="Delete" enterDelay={500} leaveDelay={500} slotProps={toolTipSlotProps} >
            <IconButton size="small" color="primary" onClick={(e) => {
                      console.log("Delete: ", item.name); 
                      setDeleteBrandData(item);
                      setConfirmDeleteOpen(true);
                      e.stopPropagation();
                    }}
                      >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit" enterDelay={500} leaveDelay={500} slotProps={toolTipSlotProps} >
            <IconButton size="small" color="primary" onClick={(e) => {
                      console.log("Edit: ", item.name);
                      setBrandToEdit(item);
                      setEditorOpen(true);
                    }}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy" enterDelay={500} leaveDelay={500} slotProps={toolTipSlotProps} >
            <IconButton size="small" color="primary" onClick={(e) => {
                      console.log("Copy: ", item.name);
                    }}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Select" enterDelay={500} leaveDelay={500} slotProps={toolTipSlotProps} >
            <IconButton size="small" color="primary" onClick={(e) => {
                      console.log("Select: ", item.name);
                      handleBrandClick(item);
                    }}>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
    );
  };

  const content = () => {
    if (!initialized || !brandDb.list || !Array.isArray(brandDb.list)) {
      console.debug("BrandSelector: content: data not yet initialized: ", initialized, brandDb.list);
      return (<FormLoader onRetry={() => {brandDb.getList();}} errorMessage={(!initialized && brandDb.resultMessage) ? "Retry loading brand data ..." : undefined} />);
    }
    return (
      <div>
        <Dialog sx={{zIndex:200}} open={confirmDeleteOpen} id="confirm-delete-dialog">
          <DialogTitle id="confirm-delete-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent>
            <Box>
              <div>Delete {deleteBrandData?.name || 'nothing'}?</div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteBrand(deleteBrandData)} variant="contained">Yes</Button>
            <Button onClick={() => setConfirmDeleteOpen(false)} variant="contained">No</Button>
          </DialogActions>
        </Dialog>
        <Dialog sx={{zIndex:100}} open={open} id={id}>
          <DialogTitle id="image-select-dialog-title">Select Brand</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box sx={{ width: 300, height: 450, border: 1, borderColor: 'divider', backgroundColor: "white" }}>
                {brandDb.list.map((item, index) => ( renderBrandItem(item, index)))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button sx={{m:2}} autoFocus onClick={handleAddClick} variant="contained">Add</Button>
            <Button sx={{m:2}} autoFocus onClick={handleClose} variant="contained">Cancel</Button>
          </DialogActions>
        </Dialog>
        <BrandEditor {...brandEditorProps} />
      </div>
    );
  };

  return (content());
};

export default BrandSelectorComponent;
