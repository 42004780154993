import { FC, useContext, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Box, Fab, Paper, Typography } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';

// import * as AuthenticationApi from './api/authentication';
import { PROJECT_NAME } from './api/env';
import { extractErrorMessage, onEnterCallback } from './utils';
import { AuthenticationContext } from './contexts/authentication';
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { EventType, EventMessage, InteractionStatus } from "@azure/msal-browser";

const SignIn: FC = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const { enqueueSnackbar } = useSnackbar();
  const msal = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount();

  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    const callbackId = msal.instance.addEventCallback(async (event: EventMessage) => {
        if (
            (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
            event.payload && "account" in event.payload
        ) {
          console.log("MSAL useEffect " + event.eventType + " " + event.payload.account.username);
          const account = event.payload.account;
          const request = {
            account: account,
            scopes: loginRequest.scopes,
            forceRefresh: true
          };
          if (msal.inProgress !== InteractionStatus.None) {
            console.log("MSAL Interaction in progress: " + msal.inProgress);
          }
          if (event.eventType === EventType.LOGIN_SUCCESS) {
            if (msal.inProgress !== InteractionStatus.None) {
              console.log("MSAL Interaction in progress: " + msal.inProgress);
            }
            const authResult = await msal.instance.acquireTokenSilent(request);
            console.log("useEffect acquired token authResult: -" + authResult.accessToken + "-");
            authenticationContext.signIn(authResult.accessToken);
          }
        }
    });

    return () => {
        if (callbackId) {
            msal.instance.removeEventCallback(callbackId);
        }
    };
    // eslint-disable-next-line
}, [msal.instance]);

  const validateAndSignIn = async () => {
    setProcessing(true);
    try {
      if (!isAuthenticated) {
        signIn();
      } else {
        console.log("Calling acquireTokenSilent");
        const request = {
          account: account,
          scopes: loginRequest.scopes,
          forceRefresh: true
        };
        if (msal.inProgress !== InteractionStatus.None) {
          console.log("MSAL Interaction in progress: " + msal.inProgress);
        }
        await msal.instance.acquireTokenRedirect(request);
      }
    } catch (errors: any) {
      console.log("Error: " + errors);
      setProcessing(false);
    }
  };

  const signIn = async () => {
    try {
      console.log("SIGNIN: Calling loginRedirect");
      const request = {
        scopes: loginRequest.scopes,
        account: null as any,
        forceRefresh: true
      };
      if (msal.inProgress === InteractionStatus.None) {
        // await msal.instance.handleRedirectPromise();
        // TODO: Sometimes we are getting an error here: "Error: Interaction is currently in progress. Please ensure that this interaction has been completed before calling an interactive API. For more visit: aka.ms/msaljs/browser-errors."
        // The above test for InteractionStatus.None should prevent this, but it doesn't. We need to figure out how to clear the inProgress state when we get this error.
        await msal.instance.loginRedirect(request);
      }
      else {
        console.log("MSAL Interaction in progress: " + msal.inProgress);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        enqueueSnackbar("Invalid login details", { variant: "warning" });
      } else {
        console.log("Error: " + error);
        // NOTE: Here is where we can catch the "Interaction is curretly in progress" error, clear the state, and try again.
        enqueueSnackbar(extractErrorMessage(error, "Unexpected error, please try again"), { variant: "error" });
      }
      setProcessing(false);
    }
  };

  onEnterCallback(signIn);

  return (
    <Box
      display="flex"
      height="100vh"
      margin="auto"
      padding={2}
      justifyContent="center"
      flexDirection="column"
      maxWidth={(theme) => theme.breakpoints.values.sm}
    >
      <Paper
        sx={(theme) => ({
          textAlign: "center",
          padding: theme.spacing(2),
          paddingTop: "200px",
          backgroundImage: 'url("/app/icon.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50% " + theme.spacing(2),
          backgroundSize: "auto 150px",
          width: "100%"
        })}
      >
        <Typography variant="h4">{PROJECT_NAME}</Typography>
        <Fab variant="extended" color="primary" sx={{ mt: 2 }} onClick={validateAndSignIn} disabled={processing}>
          <ForwardIcon sx={{ mr: 1 }} />
          Sign In
        </Fab>
      </Paper>
    </Box>
  );
};

export default SignIn;
