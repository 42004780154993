import axios from 'axios';
import React, { FC, useState, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import HTMLButtonElement from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import * as DbApi from './api';
import { ImageLocationItem } from './types';
import { AuthenticatedContext } from "../contexts/authentication";
import { BASE_IMAGE_PATH } from './projConfig';
import styled from "@emotion/styled";
import { useSnackbar } from 'notistack';

export interface kvPair {
  key: string;
  value: string;
  [key: string]: string; // Add index signature
};

export interface ImageSelectorProps {
  folder: string;
  label: string;
  image: string;
  filter: kvPair;
  // Function to handle image selection
  onSelect: (item: ImageLocationItem) => void;
};

const ImageListItemWithStyle = styled(ImageListItem)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
    opacity: 0.8,
    border: `solid 3px red`,
    borderRadius: '4px',
  },
}));

interface SelecteableImageList {
  item: ImageLocationItem,
  isSelected: boolean
};

const ImageSelectorComponent: FC<ImageSelectorProps> = ({ folder, label, image, filter, onSelect }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [itemData, setItemData] = useState<SelecteableImageList[]>([]);
  const { me, token } = useContext(AuthenticatedContext);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteFileName, setDeleteFileName] = useState('');
  const [cancelled, setCancelled] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const id = open ? 'image-select-dialog' : undefined;

  // Event handler to handle clicks on images in the list
  const handleImageClick = (item: ImageLocationItem) => {
    console.log("Image clicked: ", item.name); 
    // Send the selected image to the parent component
    onSelect(item);
    handleClose();
  };

  const loadImages = async () => {
    try {
      const response = await DbApi.readImageList(folder, me?.cid);
      // Iterate through the response data and add each item to a SelectableImageList array
      if (!response.data || cancelled) 
        return;
      const items: SelecteableImageList[] = [];
      response.data.forEach((item: ImageLocationItem) => {
        // If the filter is not present or the filter key/value pair value matches the item's metadata value, add the item to the list
//        console.debug("Filter: ", filter, "Item: ", item.metadata);
        if (!filter || !filter.key || !item.metadata || !item.metadata.hasOwnProperty(filter.key) || !item.metadata[filter.key] || item.metadata[filter.key] === filter.value) 
          items.push({item: item, isSelected: false});
      });
      setItemData(items);
      // setItemData(response.data);
    } catch (error: any) {
      console.error("Error loading images: ", error);
    }
  };

  const deleteImage = async (imageName: string) => {
    console.log("Image to delete: %s/%s", folder.substring(0, folder.length -1), imageName);
    if (!!imageName) {
      try {
        await DbApi.deleteImage(folder.substring(0, folder.length -1), imageName);
        enqueueSnackbar("Delete successful", { variant: 'success' });
      } catch (error: any) {
        const message = 'Problem deleting ' + imageName;
        enqueueSnackbar(message, { variant: 'error' });
      } finally {
      }
      setConfirmDeleteOpen(false);
      if (!cancelled) {
        loadImages();
      }
    }
  };

  // Wrap call to loadImages in useEffect so it is only called on initial load of component
  useEffect(() => {
    loadImages();
    return () => {
      setCancelled(true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder, me?.cid]);

  // Function to upload image to Azure Blob Storage
  const doUpload = async (file: File | null) => {
    const fileData = new FormData();
    console.log("File is %s", file?.name);
    if (file) {
      fileData.append("image", file);
      await axios.post(BASE_IMAGE_PATH + folder.substring(0, folder.length -1) + "/"  + file.name, fileData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        },
        params: {
          // if filter is present, add it's name and value to the params
          ...(filter && filter.key && filter.value ? {[filter.key]: filter.value} : {})
        }
      }).then(function (response) {
        if (response.status === 200) {
          enqueueSnackbar("Player image upload successful", { variant: 'success' });
          // setUploadFile(null);
          onSelect({name: file.name, url: BASE_IMAGE_PATH + folder.substring(0, folder.length -1) + "/" + file.name, metadata: filter});
        }
      })
      .catch(function (err) {
        enqueueSnackbar("Player image upload failed", { variant: 'error' });
      })
    }
    handleClose();
  };

  return (
    <div>
      <Stack direction="row" spacing={2}>
        <HTMLButtonElement onClick={handleClick} sx={{}} >
          <Avatar 
            sx={{m: 1}} 
            src={image ? (BASE_IMAGE_PATH + folder.substring(0, folder.length -1) + "/" + image + "?uuid=" + me.cid) : undefined} 
            variant="square" >
          </Avatar>
          <Box component='fieldset' style={{ border: '1px solid grey', borderColor: "#00000061", color: "#00000061", borderRadius: '4px', textTransform: 'none' }}>
            <legend style={{color: "#00000091", fontSize: '12px'}}> {label} (Click to change)</legend>
            <div style={{color: "#000000ff"}}>{!!image ? image : '(none)'}</div>
          </Box>
        </HTMLButtonElement>
      </Stack>
      <Dialog sx={{zIndex:300}} open={confirmDeleteOpen} id="confirm-delete-dialog">
        <DialogTitle id="confirm-delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <Box>
            <div>Delete {deleteFileName}?</div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteImage(deleteFileName)} variant="contained">Yes</Button>
          <Button onClick={() => setConfirmDeleteOpen(false)} variant="contained">No</Button>
        </DialogActions>
      </Dialog>
      <Dialog sx={{zIndex:200}} open={open} id={id}>
        <DialogTitle id="image-select-dialog-title">Select {label}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        <Box sx={{ border: 1, borderColor: 'divider', backgroundColor: "white" }}>
          <ImageList sx={{ width: 300, height: 450 }} cols={3} rowHeight={100}>
            {itemData.map((image, index) => ( 
              <ImageListItemWithStyle key={index}
                onClick={() => handleImageClick(image.item)}
                onAuxClick={(e) => {console.log("Aux Click: ", image.item.name); e.stopPropagation();}}
              >
                <img
                  src={`${image.item.url}&w=100&h=100&fit=scale&auto=format`}
                  alt={image.item.name}
                  id={image.item.url}
                  loading="lazy"
                  
                />
              <ImageListItemBar 
                subtitle={image.item.name}
                sx={{p:0.1, backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white'}}
                actionIcon={
                  <IconButton
                    sx={{ color: 'rgba(255, 255, 255, 0.9)'}}
                    aria-label={`delete icon ${image.item.name}`}
                    value={image.item.name || ''}
                    onClick={(e) => {
                      console.log("Delete: ", image.item.name); 
                      // deleteImage(image.item.name); 
                      setDeleteFileName(image.item.name);
                      // handleConfirmDeleteOpen(e);
                      setConfirmDeleteOpen(true);
                      e.stopPropagation();
                    }}>
                    <DeleteIcon />
                  </IconButton>
                } />
              </ImageListItemWithStyle>
            ))}
          </ImageList>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button component="label" variant="contained">
            Add Image
            <input
              type="file"
              accept='image/png'
              name="upload-image"
              hidden
              id="upload-image"
              onChange={(e) => {
                if (e && e.target && e.target.files) {
                  console.log("File selected: ", e.target.files.item(0)?.name);
                  doUpload(e.target.files.item(0));
                }
              }}
            />
          </Button>
          <Button sx={{m:2}} autoFocus onClick={handleClose} variant="contained">Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageSelectorComponent;
