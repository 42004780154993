import React from 'react';
import { createRoot } from 'react-dom/client';
import { PublicClientApplication, EventType } from "@azure/msal-browser";

import { msalConfig } from "./authConfig";
// import { ROUTE_BASE_URL } from './api/env';

import { BrowserRouter } from 'react-router-dom';

import App from './App';

/**
* Instantiate MSAL outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
            event.payload && "account" in event.payload && event.payload.account
    ) {
        msalInstance.setActiveAccount(event.payload.account);
    }
});

msalInstance.enableAccountStorageEvents();

const root = createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App msalInstance={msalInstance} />
    </BrowserRouter>
  </React.StrictMode>
);
