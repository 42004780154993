import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { SectionContent, FormLoader, ButtonRow } from '../components';
import { updateValue } from '../utils';

import { ImageLocationItem, SponsorData, defaultSponsor } from './types';
import { TeamContext, DatabaseAccessContext } from './SchoolContext';
import { AuthenticatedContext } from "../contexts/authentication";
import { ACCESS_TOKEN } from "../api/endpoints";
import { ImageSelectorProps } from './ImageSelector';
import ImageSelectorComponent from './ImageSelector';

const SponsorSetupForm: FC = () => {
  const { selectedTeam } = useContext(TeamContext);
  const { id } = useParams();
  const idNum: number = parseInt(id) || 0;
  const db = useContext(DatabaseAccessContext).sponsorDb;
  const { token } = useContext(AuthenticatedContext);
  const [initialized, setInitialized] = useState(false);
  const [label, setLabel] = useState("Sponsor Info");
  const [startingName, setStartingName] = useState("");
  const [cancelled, setCancelled] = useState(false);
  const adding: boolean = !(idNum > 0);
  const newItem = { ...defaultSponsor } as SponsorData;
  localStorage.setItem(ACCESS_TOKEN, token);

  const goBack = () => {
    db.setData(undefined);
    navigate("../list");
  };

  // Effect to run on component mount to make sure data is loaded
  useEffect(() => {
    const loadData = async () => {
      console.debug("Initial load data: ", db.data);
      if (adding) {
        db.setData({...newItem, cid: selectedTeam?.cid || 0, tid: selectedTeam?.id || 0});
        setInitialized(true);
      } else {
        let dbItem = await db.read(idNum, true);
        if (dbItem?.id > 0 && dbItem.id === idNum) {
          setStartingName(dbItem.name);
          setInitialized(true);
        } else {
          console.debug("SponsorSetupForm: data not loaded in initialization useEffect: ", id, db.data);
        }
      }
    };
    loadData();
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect to run when the data is loaded into the useDatabase hook
  useEffect(() => {
    console.debug("*SetupForm: selected item loaded from DB: ", db.data, "id: ", id, "initialized: ", initialized);
    if (!initialized) {
      if (db.data?.id > 0 && db.data.id.toString() === id) {
        setStartingName(db.data.name);
        setInitialized(true);
      }
    }
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.data, id]);

  useEffect(() => {
    console.debug("*SetupForm: selected item changed: ", startingName, initialized, adding);
    if (initialized) {
      if (adding) {
        setLabel("New Sponsor Setup");
      } else if (startingName !== "") {
        setLabel("Sponsor Info - " + startingName);
      } else {
        setLabel("Sponsor Info");
      }
    }
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, adding, startingName]);

  const updateFormValue = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("*SetupForm: updateFormValue: ", event.target.name, event.target.value, initialized);
      db.setData({ ...db.data, [event.target.name]: event.target.value });
    }
  };

  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  const processSave = async () => {
    if (adding) {
      console.debug("*SetupForm: processSave: item to create: ", db.data);
      await db.create(db.data, true);
    } else {
      if (db.isModified) {
        console.debug("*SetupForm: processSave: item changed: ", db.data);
        await db.update();
      }
    }
    navigate('../list');
  };

  const processDelete = async () => {
    console.log("Item to delete: %d", idNum);
    if (idNum > 0 && db.data && db.data.id === idNum && selectedTeam) {
      db.delete(idNum, true, selectedTeam.id.toString());
    }
    navigate("../list");
  };

  // If selectedTeam changes navigate back to list
  useEffect(() => {
    if (initialized && !cancelled) {
       db.setData(undefined);
       navigate("../list");
     };
     return () => {
       // Do any needed cleanup work
       setCancelled(true);
     };
   // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [selectedTeam, navigate]);

 const imageSelectProps = {
    folder: "sponsors",
    label: "Main Sponsor Logo (5:2)",
    image: db.data?.image,
    filter: {key: "size", value: "standard"},
    onSelect: (item: ImageLocationItem) => { 
      db.setData({ ...db.data, image: item.name })},
  } as ImageSelectorProps;

  const rotorImageSelectProps = {
    folder: "sponsors",
    label: "Wide Sponsor Logo (9:2)",
    image: db.data?.image_scroll,
    filter: {key: "size", value: "wide"},
    onSelect: (item: ImageLocationItem) => { 
      db.setData({ ...db.data, image_scroll: item.name })},
  } as ImageSelectorProps;

  const squareImageSelectProps = {
    folder: "sponsors",
    label: "Square Sponsor Logo (1:1)",
    image: db.data?.image_square,
    filter: {key: "size", value: "square"},
    onSelect: (item: ImageLocationItem) => { 
      db.setData({ ...db.data, image_square: item.name })},
  } as ImageSelectorProps;

  const content = () => {
    if (!initialized || !db.data) {
      console.debug("*SetupForm: content: Not initialized: ", db.data, "id: ", id);
      return (<FormLoader onRetry={() => {db.read(idNum, true)}} errorMessage={(!db.data) ? "Retry loading sponsor data ..." : undefined} />);
    }

    return (
      <>
        <TextField
          name="name"
          label="Name"
          fullWidth
          variant="outlined"
          value={db.data.name}
          onChange={updateFormValue}
          margin="normal"
        />
        <ImageSelectorComponent {...imageSelectProps} />
        <ImageSelectorComponent {...rotorImageSelectProps} />
        <ImageSelectorComponent {...squareImageSelectProps} />
        <TextField
          name="plan"
          label="Advertising tier"
          fullWidth
          variant="outlined"
          value={db.data.plan || ''}
          onChange={updateFormValue}
          margin="normal"
        />
        <ButtonRow mt={1}>
          <Button startIcon={<CancelIcon />} disabled={db.saving || !db.isModified} variant="contained" color="primary" type="button" onClick={goBack}>
            Cancel
          </Button>
          <Button startIcon={<DeleteIcon />} disabled={db.saving || idNum <= 0} variant="contained" color="primary" type="button" onClick={processDelete}>
            Delete
          </Button>
          <Button startIcon={<SaveIcon />} disabled={db.saving || !db.isModified} variant="contained" color="primary" type="submit" onClick={processSave}>
            Save
          </Button>
        </ButtonRow>
      </>
    );
  };

  return (
    <SectionContent title={label} titleGutter>
      {content()}
    </SectionContent>
  );
};

export default SponsorSetupForm;
