export const DBConfig = {
  name: "LocalDb",
  version: 3,
  objectStoresMeta: [
    {
      store: "sponsors",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "updateTime", keypath: "updateTime", options: { unique: false } }
      ],
    },
    {
      store: "players",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "updateTime", keypath: "updateTime", options: { unique: false } }
      ],
    },
    {
      store: "games",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "updateTime", keypath: "updateTime", options: { unique: false } }
      ],
    },
    {
      store: "teams",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "compid", keypath: "data.compId", options: { unique: false } },
        { name: "brandid", keypath: "data.competitor.brandId", options: { unique: false } },
        { name: "updateTime", keypath: "updateTime", options: { unique: false } }
      ],
    },
    {
      store: "brands",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "updateTime", keypath: "updateTime", options: { unique: false } }
      ],
    },
    {
      store: "competitors",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
        { name: "brandid", keypath: "data.brandId", options: { unique: false } },
        { name: "updateTime", keypath: "updateTime", options: { unique: false } }
      ],
    },
    {
      store: "syncstatus",
      storeConfig: { keyPath: "name", autoIncrement: false },
      storeSchema: [
        { name: "value", keypath: "value", options: { unique: false } }
      ],
    }
  ],
};