
import { FC, useContext, useState, useCallback } from 'react';

import { Button, Box, Menu, MenuItem, Typography, styled, TypographyProps } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import { TeamContext } from './SchoolContext';
import { TeamData } from './types';
// import { readTeamList } from './api';
// import { useRest } from '../utils';
import { useNavigate } from 'react-router-dom';

const ItemTypography = styled(Typography)<TypographyProps>({
  maxWidth: '250px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const ProjectAppBar: FC = () => {

  const { teamList, selectedTeam, selectTeam } = useContext(TeamContext);
  // const db = useContext(DatabaseAccessContext).teamDb;
  const navigate = useNavigate();

  const handleSelectTeam = useCallback((team: TeamData) => {
    selectTeam(team);
    localStorage.setItem('selectedTeam', JSON.stringify(team));
    localStorage.setItem('selectedTeamId', team.id.toString());
    handleClose();
  }, [selectTeam]);

//  const { data } = useRest<TeamData[]>({ read: readTeamList });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleManageTeams = () => {
    navigate('/home');
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = anchorEl ? 'team-select-menu' : undefined;

  // Function to render each team in 'data' list as a menu item
  const renderTeam = (team: TeamData) => {
    return (
      <MenuItem onClick={() => { handleSelectTeam(team); }} key={team.id}>
        <ItemTypography variant="body1">
          {team.name}
        </ItemTypography>
      </MenuItem>
    );
  };

  return (
    <>
      <Box pl={2}  >
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          aria-label="select active team"
          onClick={handleClick}
          sx={{ padding: 1}}
          endIcon={<GroupsIcon />}
        >
          <Typography variant="body2">
            {selectedTeam ? selectedTeam.name : 'Select Team'}
          </Typography>
        </Button>
      </Box>
      <Menu
        id={id}
        sx={{ mt: 1, maxWidth: '250px', maxHeight: '300px', padding: 1}}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          teamList?.map(renderTeam)
        }
        <MenuItem onClick={() => handleManageTeams()}>
        <ItemTypography variant="body1">
          <i><b>Manage Teams&nbsp;</b></i>
        </ItemTypography>
      </MenuItem>
      </Menu>
    </>
  );
};

export default ProjectAppBar;
