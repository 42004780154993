import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, Checkbox, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, TextField, Typography } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import HTMLButtonElement from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { BrandData, CompetitorData, TeamData, defaultBrand, defaultCompetitor, defaultTeam } from '../../project/types';
// import { useNavigate } from 'react-router-dom';
import { TEAM_IMAGE_PATH } from '../../project/projConfig';
import { AuthenticatedContext } from "../../contexts/authentication";
import { FormLoader, useLayoutTitle, ButtonRow } from '../../components';
import { DatabaseAccessContext } from '../../project/SchoolContext';
import BrandEditor, { BrandEditorProps } from '../../project/BrandEditor';
import { useSnackbar } from 'notistack';
import { updateValue } from '../../utils';
import RenderTeam, { RenderTeamProps } from '../../project/TeamLayout';
import BrandSelectorComponent, { BrandSelectorProps } from '../../project/BrandSelector';

const HomeMain: FC = () => {
  useLayoutTitle("Dashboard");
//   const navigate = useNavigate();
  const { me } = useContext(AuthenticatedContext);
  const cid: number = me?.seq || 0;

  const { enqueueSnackbar } = useSnackbar();
  const teamDb = useContext(DatabaseAccessContext).teamDb;
  const db = useContext(DatabaseAccessContext);
  const [initialized, setInitialized] = React.useState(teamDb.list !== undefined && teamDb.list.length > 0);
  const [defaultTeamId, setDefaultTeam] = React.useState<number>(teamDb.list !== undefined && teamDb.list.length > 0 ? teamDb.list.find((t: TeamData) => t.isDefault)?.id || 0 : 0);
  const startingDefaultTeamId = useRef<number>(teamDb.list !== undefined && teamDb.list.length > 0 ? teamDb.list.find((t: TeamData) => t.isDefault)?.id || 0 : 0);
  // let startingDefaultTeamId = teamDb.list !== undefined && teamDb.list.length > 0 ? teamDb.list.find((t: TeamData) => t.isDefault).id : 0;
  const [open, setOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [brandToEdit, setBrandToEdit] = useState<BrandData>(teamDb.list !== undefined && teamDb.list.length > 0 ? teamDb.list.find((t: TeamData) => t.isDefault)?.competitor?.brand || defaultTeam.competitor.brand : defaultTeam.competitor.brand);
  const newBrand = { ...defaultBrand, cid: cid } as BrandData;
  const newCompetitor = { ...defaultCompetitor, cid: cid, tid: startingDefaultTeamId.current, brand: newBrand } as CompetitorData;
  const newTeam = { ...defaultTeam, cid: cid, competitor: newCompetitor } as TeamData;
  const [startTeam, setStartTeam] = useState<TeamData>(newTeam);
  const [editedTeam, setEditedTeam] = useState<TeamData>(newTeam);
  const [startCompetitor, setStartCompetitor] = useState<CompetitorData>(newCompetitor);
  const [editedCompetitor, setEditedCompetitor] = useState<CompetitorData>(newCompetitor);
  const [adding, setAdding] = useState(false);
  // We need to provide our own isModified functions here because we are not updating the data in the useDatabase hooks
  const isEqual = require('lodash/isEqual');
  // boolean result to indicate whether the competitor data has been modified
  let isCompetitorModified: boolean = adding || !isEqual(editedCompetitor, startCompetitor);
  // boolean result to indicate whether the team data has been modified
  let isTeamModified: boolean = adding || !isEqual(editedTeam, startTeam);
  // TODO: We need to write an API that will call a stored procedure to change the customer's default team to the newly selected team
  const defaultTeamChanged = !isEqual(startingDefaultTeamId, defaultTeamId);

  useEffect(() => {
    // If db.status or db.resultMessage changes, set a snackbar message to display the resultMessage
    if (db.teamDb.status && db.teamDb.resultMessage) {
      enqueueSnackbar(db.teamDb.resultMessage, { variant: db.teamDb.status as any });
      db.teamDb.clearStatus();
    }
    if (db.competitorDb.status && db.competitorDb.resultMessage) {
      enqueueSnackbar(db.competitorDb.resultMessage, { variant: db.competitorDb.status as any });
      db.competitorDb.clearStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.teamDb.status, db.teamDb.resultMessage, db.competitorDb.status, db.competitorDb.resultMessage]);

  const openBrandSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const openBrandEditor = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!editedCompetitor.brand || editedCompetitor.brand.id === 0) {
      openBrandSelector(event);
    } else {
      setBrandToEdit(editedCompetitor?.brand || newBrand);
      setEditorOpen(true);
    }
  };

  const updateCompetitorReferencesInCache = async (competitor: CompetitorData) => {
    // const competitors = await db.competitorDb.getList();
    console.debug("HomeMain: updateCompetitorReferencesInCache: ", competitor);
    const teams = await db.teamDb.getList();
    /*
    if (competitors) {
      competitors.forEach((competitor) => async () => {
        if (competitor.brandId === brand.id) {
          await db.competitorDb.update({ ...competitor, brand: brand }, false, "", true);
        }
      });
      db.competitorDb.clearStatus();
    };
    */
    if (teams) {
      console.debug("HomeMain: updateCompetitorReferencesInCache: teams: ", teams);
      teams.forEach(function(team) {
        console.debug("HomeMain: Evaluating: team: ", team, competitor);
        if (team.compId === competitor.id) {
          db.teamDb.update({ ...team, competitor: competitor}, false, "", true);
        }
      });
      db.teamDb.clearStatus();
    } else {
      console.warn("HomeMain: updateCompetitorReferencesInCache: No teams found to update: ", db.teamDb.list, teams);
    }
  };
    
  // Initial load of list of competitors
  useEffect(() => {
    (async () => {
      console.log("TeamMain: Initial load of teams.");
      await teamDb.getList();
      /* // Let's try deferring this to the teamDb.list useEffect
      console.debug("Reading team data: ", teamDb.data, teamDb.list, "initialized: ", initialized);
      // Call the useDatabase hook to read the team data, passing true so that setData will be called with the data to trigger the useEffect below
      let team = await db.teamDb.read(startingDefaultTeamId, true);
      if (team?.id > 0) {
        setStartTeam(team);
        setEditedTeam(team);
        setStartCompetitor(team?.competitor || newCompetitor);
        setEditedCompetitor(team?.competitor || newCompetitor);
      } else {
        console.debug("OpponentSetupForm: team data not loaded in intialization useEffect: ", team, "initialized: ", initialized);
      }
      */
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If db.status or db.resultMessage changes, set a snackbar message to display the resultMessage
    if (teamDb.status && teamDb.resultMessage) {
      enqueueSnackbar(teamDb.resultMessage, { variant: teamDb.status as any });
      teamDb.clearStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDb.status, teamDb.resultMessage]);

  useEffect(() => {
    (async () => {
      console.debug("Reading team data: ", teamDb.data, teamDb.list, "initialized: ", initialized);
      let isInitialized = teamDb.list !== undefined && teamDb.list.length > 0;
      if (isInitialized) {
        let team = teamDb.list[0];
        setInitialized(isInitialized);
        startingDefaultTeamId.current = teamDb.list.find((t: TeamData) => t.isDefault)?.id || team.id;
        setDefaultTeam(startingDefaultTeamId.current);
        if (team?.id > 0) {
          // TODO: Evaluate whether we need to use startTeam and editedTeam at all, or just edit the competitor data directly
          // The only change we will make to the team data is to set the default team id, which is a separate API call
          setStartTeam(team);
          setEditedTeam(team);
          setStartCompetitor(team?.competitor || newCompetitor);
          setEditedCompetitor(team?.competitor || newCompetitor);
        } else {
          console.warn("HomeMain: default team data not loaded inteamDb.list useEffect: ", team, "initialized: ", initialized);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDb.list]);

  // #region [Event Handlers]
  const handleClick = (team: TeamData) => {
    console.log("Getting data to edit team: ", team);
    // TODO: If the team has been edited, prompt the user to save or discard changes
    setStartCompetitor(team.competitor);
    setEditedCompetitor(team.competitor);
  };

  const handleAdd = () => {
    console.log("Setting up a new team");
    setAdding(true);
    let newTeam = { ...defaultTeam, cid: cid, competitor: {...defaultTeam.competitor, cid: cid, brand: {...defaultTeam.competitor.brand, cid: cid}}};
    setEditedTeam(newTeam);
    setStartTeam(newTeam);
    setEditedCompetitor(newTeam.competitor);
    setStartCompetitor(newTeam.competitor);
  };

  const handleChangeDefault = (team: TeamData) => {
    console.log("Changing default team: ", team);
    setDefaultTeam(team.id);
  };

  const processChangeDefault = async () => {
    console.log("Changing default team to: ", defaultTeamId);
    // call new API to change the default team
  };

  const updateCompetitor = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("OpponentSetupForm: updateCompetitor: ", event.target.name, event.target.value, initialized);
      setEditedCompetitor({ ...editedCompetitor, [event.target.name]: event.target.value });
    }
  };

  /*
  // TODO: Delete this after implementing a new API to update the default team
  const updateTeam = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("OpponentSetupForm: updateTeam: ", event.target.name, event.target.value, initialized);
      setEditedTeam({ ...editedTeam, [event.target.name]: event.target.value });
    }
  };
  */

  // Callback for processing brand data not yet loaded retry event
  const retryBrandLoad = async () => {
    console.warn("HomeMain: retryBrandLoad: THIS SHOULDN'T HAPPEN: ", editedCompetitor, " adding: ", adding);
    // Since this is an unexpected condition, the "fix" is just to read the current comptitor data again, which should have brand data with it.
    await teamDb.read(startingDefaultTeamId.current, true);
  };

  const processSave = async () => {
    if (adding) {
      // NOTE: when creating a new brand and new competitor at the same time, it can be done in one call to create a new competitor with the new brand embedded.
      // Set the competitor's brandId to zero, set the brand object to the new brand data, and set the brand's id to zero. The server will create both, and set
      // the brandId in the competitor to the new brand's id.
      console.debug("HomeMain: processSave: competitor to create: ", teamDb.data);
      await teamDb.create(editedTeam, true);
    } else {
      if (db.brandDb.isModified && !isCompetitorModified) {
        console.warn("HomeMain: processSave: brand changed: ", db.brandDb.data);
        await db.brandDb.update();
      }
      if (isCompetitorModified) {
        console.debug("HomeMain: processSave: competitor changed: ", editedCompetitor, editedTeam, "teamModified: ", isTeamModified, "competitorModified: ", isCompetitorModified);
        if (isCompetitorModified) {
          var updatedCompetitor: CompetitorData = await db.competitorDb.update(editedCompetitor, false);
          setEditedCompetitor(updatedCompetitor);
          setStartCompetitor(updatedCompetitor);
          await updateCompetitorReferencesInCache(updatedCompetitor);
          var updatedTeam: TeamData = { ...editedTeam, competitor: updatedCompetitor, name: updateCompetitor.name };
          // Update the team data with the updated competitor data
          setEditedTeam(updatedTeam);
          setStartTeam(updatedTeam);
          // update the team data in the indexedDb (it's already been updated in the db)
        }
        if (isTeamModified) {
          console.warn("HomeMain: processSave: team changed. WE WEREN'T GOING TO DO THIS!: ", editedTeam);
          updatedTeam = await teamDb.update(editedTeam);
          setEditedTeam(updatedTeam);
          setStartTeam(updatedTeam);
        }
      }
    }
  };

  const processDelete = async () => {
    console.log("Team to delete: %d", startTeam.id);
    // NOTE: This deletes the Competitor record, but not the Brand record
    if (startTeam.id > 0) {
      db.teamDb.delete(startTeam.id, false);
    }
  };

  const processCancel = () => {
    console.log("Cancelling changes to team: ", editedTeam);
    if (isTeamModified) {
      setEditedTeam(startTeam);
    }
    if (isCompetitorModified) {
      setEditedCompetitor(startCompetitor);
    }
  };
  // #endregion
  
  // #region [Component Props]
  const renderTeamProps: RenderTeamProps = {
    brand: editedCompetitor.brand,
    uuid: me?.cid || ""
  };

  const brandSelectorProps = {
    onSelect: (item: BrandData) => {
      console.debug("HomeMain: brandSelectorProps: ", item, isCompetitorModified);
      setEditedCompetitor({ ...editedCompetitor, brandId: item.id, brand: item  });
    },
    uuid: me?.cid || "",
    open: open,
    setOpen: setOpen,
    brandDb: db.brandDb
  } as BrandSelectorProps;

  const brandEditorProps: BrandEditorProps = {
    brand: brandToEdit,
    uuid: me?.cid || "",
    open: editorOpen,
    setOpen: setEditorOpen,
    onUpdateNotify: async (item) => {
      console.debug("HomeMain.onUpdateHook: ", editedCompetitor);
      // This is to allow the OpponentSetup or TeamSetup components hosting the BrandEditor to sync their compound objects with the new brand data
      db.competitorDb.setData({ ...db.competitorDb.data, brandId: item.id, brand: item });
      setEditedCompetitor({ ...editedCompetitor, brandId: item.id, brand: item });
      teamDb.setData({ ...teamDb.data, competitor: { ...teamDb.data.competitor, brandId: item.id, brand: item } });
      setEditedTeam({ ...editedTeam, competitor: { ...editedTeam.competitor, brandId: item.id, brand: item } });
      setEditorOpen(false);
    }
  };
  // #endregion
  // #region [Brand Content UI]
  const brandContent = () => {
    console.debug("HomeMain: rendering brandContent: ", startCompetitor, editedCompetitor, "adding: ", adding);
    if (!adding && (!editedCompetitor?.brand || !editedCompetitor.brand.hasOwnProperty('id') || editedCompetitor.brand.id === 0)) {
      console.debug("brandContent: data is null or uninitialized: ", editedCompetitor, adding);
      return (<FormLoader onRetry={() => {retryBrandLoad()}} errorMessage={db.competitorDb.resultMessage? "Opponent data not loaded" : undefined} />);
    }
    return (
      <Card sx={{mt:1}}>
        <CardContent>
          <Typography variant="h6" color="textSecondary" component="p">
            Branding for the {startCompetitor.brand?.name + " " + startCompetitor.name} team.
          </Typography>
            <HTMLButtonElement onClick={openBrandEditor} sx={{}} >
              <Box component='fieldset' style={{ border: '1px solid grey', borderColor: "#00000061", color: "#00000061", borderRadius: '4px', textTransform: 'none' }}>
                <legend style={{color: "#00000091", fontSize: '12px'}}> Team Branding (Click to edit/select)</legend>
                <RenderTeam {...renderTeamProps} />
              </Box>
            </HTMLButtonElement>
            <Button onClick={openBrandSelector} variant="text" color="primary">
              Select Existing Brand
            </Button>
          <BrandSelectorComponent {...brandSelectorProps} />
          <BrandEditor {...brandEditorProps} />
        </CardContent>
      </Card>
    );
  }
  // #endregion
  // #region [Render Methods]
  const renderTeamListItem = (team: TeamData) => {
    return (
      <ListItem
        key={team.id}
        sx={{bgcolor: team.competitor.brand.color + "80"}}
        onClick={() => handleClick(team)}
      >
        <ListItemButton selected={team.competitor.id === startTeam.competitor.id}>
          <ListItemAvatar>
            <Avatar src={TEAM_IMAGE_PATH + team.competitor.brand.logo + "?uuid=" + me.cid} variant="square" >
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            sx={{color: 'lightgray'}}
            secondaryTypographyProps={{color: 'common.lightgray'}}
            primary={team.name}
            secondary={team.competitor.brand.mascot + " (Record: " + team.competitor.win + "-" + team.competitor.loss + ")"}
          />
        </ListItemButton>
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            checked={team.id === defaultTeamId}
            onChange={() => {
              handleChangeDefault(team);
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const content = () => {
    if (!initialized) {
      return (<FormLoader onRetry={() => teamDb.getList()} errorMessage={teamDb.resultMessage ? "Retry loading list of your teams ..." : undefined} />);
    }

    return (
      <>
        {brandContent()}
        <Card sx={{mt:1}}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Typography variant="h6" color="textSecondary" component="p">Your Teams</Typography>
              </Grid>
              <Grid item xs={3} container justifyContent={"flex-end"}>
                <Typography variant="h6" color="textSecondary" component="p">Default</Typography>
              </Grid>
            </Grid>
            <List sx={{bgcolor: '#000000c0'}}>
                <div>
                  {teamDb.list.map(renderTeamListItem)}
                </div>
            </List>
            <Grid container spacing={1} paddingTop={1}>
              <Grid item xs={9}>
              <Button startIcon={<AddBoxIcon />} variant="contained" color="primary" type="button" onClick={handleAdd}>
                Add
              </Button>
              </Grid>
              <Grid item xs={3} container justifyContent={"flex-end"}>
              <Button disabled={teamDb.saving || !(defaultTeamChanged)} variant="contained" color="primary" type="button" onClick={processChangeDefault}>
                Update Default
              </Button>
              </Grid>
            </Grid>
            <TextField
              name="name"
              label="Name"
              fullWidth
              variant="outlined"
              value={editedCompetitor.name || ""}
              placeholder={"Enter the team name here ..."}
              onChange={updateCompetitor}
              margin="normal"
            />
            <TextField
              name="win"
              label="Wins this season"
              fullWidth
              variant="outlined"
              // eslint-disable-next-line eqeqeq
              value={editedCompetitor.win == undefined ? "" : editedCompetitor.win.toString()}
              onChange={updateCompetitor}
              margin="normal"
            />
            <TextField
              name="loss"
              label="Losses this season"
              fullWidth
              variant="outlined"
              // eslint-disable-next-line eqeqeq
              value={editedCompetitor.loss == undefined ? "" : editedCompetitor.loss.toString()}
              onChange={updateCompetitor}
              margin="normal"
            />
            <ButtonRow mt={1}>
              <Button startIcon={<CancelIcon />} disabled={teamDb.saving || !isCompetitorModified} variant="contained" color="primary" type="button" onClick={processCancel}>
                Cancel
              </Button>
              <Button startIcon={<DeleteIcon />} disabled={teamDb.saving} variant="contained" color="primary" type="button" onClick={processDelete}>
                Delete
              </Button>
              <Button startIcon={<SaveIcon />} disabled={teamDb.saving || !isCompetitorModified} variant="contained" color="primary" type="submit" onClick={processSave}>
                Save
              </Button>
            </ButtonRow>
          </CardContent>
        </Card>
      </>
    )
  };

  return (
    <>
      {content()}
    </>
  );

};

export default HomeMain;
