import { AxiosPromise } from "axios";
import * as H from 'history';
import jwtDecode from 'jwt-decode';
import { Path } from "react-router-dom";

import { Me, SignInRequest, SignInResponse } from "../types";

import { ACCESS_TOKEN, AXIOS } from "./endpoints";
// import { PROJECT_PATH } from './env';
// import { getServers } from "dns";

export const SIGN_IN_PATHNAME = 'loginPathname';
export const SIGN_IN_SEARCH = 'loginSearch';

export const getDefaultRoute = (me: Me) => (me.gameMgr ? "/opponents/list" : "/control");

// These next two functions are not currently used, but we may come back to them as we implement custom token issuer.
export function verifyAuthorization(): AxiosPromise<void> {
  return AXIOS.get('/verifyAuthorization');
}

export function signIn(request: SignInRequest): AxiosPromise<SignInResponse> {
  return AXIOS.post('/signIn', request);
}

/**
 * Fallback to sessionStorage if localStorage is absent. WebView may not have local storage enabled.
 */
export function getStorage() {
  return localStorage || sessionStorage;
}

export function storeLoginRedirect(location?: H.Location) {
  console.log("Auth: storing loc: %s", location?.pathname);
  if (location) {
    getStorage().setItem(SIGN_IN_PATHNAME, location.pathname);
    getStorage().setItem(SIGN_IN_SEARCH, location.search);
    console.log("Auth: Stored path: %s", localStorage.getItem(SIGN_IN_PATHNAME));
  }
}

export function clearLoginRedirect() {
  getStorage().removeItem(SIGN_IN_PATHNAME);
  getStorage().removeItem(SIGN_IN_SEARCH);
}

export function fetchLoginRedirect(me: Me): Partial<Path> {
  const signInPathname = getStorage().getItem(SIGN_IN_PATHNAME);
  const signInSearch = getStorage().getItem(SIGN_IN_SEARCH);
  // clearLoginRedirect();
  return {
    pathname: signInPathname || getDefaultRoute(me),
    search: (signInPathname && signInSearch) || undefined
  };
}

export const clearAccessToken = () => localStorage.removeItem(ACCESS_TOKEN);
export const decodeMeJWT = (accessToken: string): Me => jwtDecode(accessToken) as Me;
export const decodeAADB2CIdToken = (idToken: string) => {
  const decoded = jwtDecode(idToken) as any;
  return {
    sub: decoded.oid,
    name: decoded.name,
    customer: decoded.jobTitle,
    admin: decoded.extension_admin,
    cid: decoded.extension_uuid,
    seq: decoded.extension_cid,
    aud: decoded.aud,
    uid: decoded.extension_uid,
    advScbd: decoded.extension_advScoreboard,
    gameMgr: decoded.extension_gameManager,
    rostMgr: decoded.extension_rosterManager,
    sponMgr: decoded.extension_sponsorManager,
    stats: decoded.extension_stats
    // iot: decoded.extension_isDevice
  } as Me;
};

export function addAccessTokenParameter(url: string) {
  const accessToken = getStorage().getItem(ACCESS_TOKEN);
  if (!accessToken) {
    return url;
  }
  const parsedUrl = new URL(url);
  parsedUrl.searchParams.set(ACCESS_TOKEN, accessToken);
  return parsedUrl.toString();
}

export function getAccessToken() {
  return getStorage().getItem(ACCESS_TOKEN);
}