import React, { FC } from "react";

import { ListItem, ListItemText, ListItemAvatar, Avatar } from "@mui/material";
import { BrandData, CompetitorData } from './types';
// import { AuthenticatedContext } from "../contexts/authentication";

import { TEAM_IMAGE_PATH } from './projConfig';

export interface RenderTeamProps {
  brand: BrandData;
  competitor?: CompetitorData;
  uuid: string;
};

const invalidBrand : BrandData = {
  id: 0,
  cid: 0,
  name: "Invalid School",
  color: "#000000",
  color2: "#000000",
  logo: undefined,
  mascot: "Invalid"
};

const invalidCompetitor : CompetitorData = {
  id: 0,
  cid: 0,
  tid: 0,
  brandId: 0,
  brand: invalidBrand,
  win: 0,
  loss: 0,
  name: "Invalid School"
};

const RenderTeam: FC<RenderTeamProps> = ({brand, competitor: comp, uuid}) => {
  if (!brand) {
    brand = invalidBrand;
  }
  if (!comp) {
    comp = invalidCompetitor;
  }
  const bgColor = !!brand?.color ? brand.color + "80" : "#00000080";
  
  return (
    <ListItem
      key={brand.id}
      sx={{bgcolor: bgColor}}
      disablePadding={true}
      // onClick={() => schoolContext.selectSchool(school)}
    >
      <ListItemAvatar>
        <Avatar src={brand.logo ? (TEAM_IMAGE_PATH + brand.logo + "?uuid=" + uuid) : undefined} variant="square" >
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        sx={{color: 'lightgray'}}
        secondaryTypographyProps={{color: 'common.lightgray'}}
        primary={brand.name + " " + brand.mascot}
        secondary={((comp?.id > 0) ? (comp.name + " (Record: " + comp.win + "-" + comp.loss + ")") : undefined)}
      />
    </ListItem>
  );
};

export default RenderTeam;
