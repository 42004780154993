import axios, { CancelToken } from 'axios';

export const REST_BASE_URL = '/rest/';
export const API_BASE_URL = '/api/';
export const ACCESS_TOKEN = 'app_access_token';

export const AXIOS = axios.create({
  baseURL: REST_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [(data, headers) => {
    if (headers) {
      if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
      }
      if (headers['Content-Type'] !== 'application/json') {
        return data;
      }
    }
    return JSON.stringify(data);
  }]
});

export const AXIOSAPI = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [(data, headers) => {
    if (headers) {
      if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
      }
      if (headers['Content-Type'] !== 'application/json') {
        return data;
      }
    }
    return JSON.stringify(data);
  }]
});

export interface FileUploadConfig {
  cancelToken?: CancelToken;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}
