import { FC, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import * as AuthenticationApi from '../../api/authentication';
import { LoadingSpinner } from '../../components';
import { Me } from '../../types';
import { AuthenticationContext } from './context';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from '../../authConfig';


const Authentication: FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [initialized, setInitialized] = useState<boolean>(false);
  const [me, setMe] = useState<Me>();
  const [token, setToken] = useState<string>("");
  const msal = useMsal();

  const signIn = (accessToken: string) => {
    try {
      if (accessToken === undefined) {
        console.log("signIn: accessToken is undefined");
        throw new Error("SignIn failed - no access token provided!");
      }
      console.log("signIn: " + accessToken );
      // AuthenticationApi.getStorage().setItem(ACCESS_TOKEN, accessToken);
      setToken(accessToken);
      const decodedMe = AuthenticationApi.decodeAADB2CIdToken(accessToken);
      setMe(decodedMe);
      enqueueSnackbar(`Logged in as ${decodedMe.name}`, { variant: 'success' });
    } catch (error: any) {
      setMe(undefined);
      throw new Error("Signin error " + error.message);
    }
  };

  const signOut = async (redirect: boolean) => {
    AuthenticationApi.clearAccessToken();
    setMe(undefined);
    if (msal.inProgress === InteractionStatus.None) {
      await msal.instance.logoutRedirect();
    } else {
      console.log("signOut: Interaction in progress: " + msal.inProgress);
      await msal.instance.handleRedirectPromise();
      // tslint:disable-next-line: 2367
      if (!msal.inProgress) {
        await msal.instance.logoutRedirect();
      }
    }
    if (redirect) {
      navigate('/signin');
    }
  };

  const refresh = useCallback(async () => {
    // TODO: Replace this with call to MSAL acquireTokenSilent
    // const accessToken = AuthenticationApi.getStorage().getItem(ACCESS_TOKEN);
    const request = {
      account: msal.accounts[0],
      scopes: loginRequest.scopes,
      forceRefresh: false
    };
    var accessToken = undefined;
    if (request.account !== undefined) {
      try {
      const authResult = await msal.instance.acquireTokenSilent(request);
      accessToken = authResult.accessToken;
      } catch (error: any) {
        if (error.errorMessage.indexOf("AADB2C90118") !== -1) {
          console.log("refresh: AADB2C90118 error - user needs to sign in again");
          await msal.instance.loginRedirect();
          return;
        } else if (error.errorMessage.indexOf("AADB2C90077") !== -1) {
          console.log("refresh: AADB2C90077 error - user needs to sign in again");
          await msal.instance.loginRedirect();
          return;
        }
      }
    }

    if (accessToken) {
      try {
        // await AuthenticationApi.verifyAuthorization();
        setToken(accessToken);
        setMe(AuthenticationApi.decodeAADB2CIdToken(accessToken));
        setInitialized(true);
      } catch (error: any) {
        setToken("");
        setMe(undefined);
        setInitialized(true);
      }
    } else {
      setToken("");
      setMe(undefined);
      setInitialized(true);
    }
  }, [msal]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (initialized) {
    return (
      <AuthenticationContext.Provider
        value={{
          signIn,
          signOut,
          me,
          token,
          refresh
        }}
      >
        {children}
      </AuthenticationContext.Provider >
    );
  }

  return (
    <LoadingSpinner height="100vh" />
  );
};

export default Authentication;
