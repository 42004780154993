import axios from 'axios';
import { FC, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Button, TextField, Stack, Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import { SectionContent, ButtonRow } from '../components';
import { extractErrorMessage } from '../utils';

import * as DbApi from './api';
import { ACCESS_TOKEN } from '../api/endpoints';
import { PlayerData } from './types';
import { PLAYER_IMAGE_PATH } from './projConfig';
import { AuthenticatedContext } from "../contexts/authentication";


const PlayerBulkAddForm: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  console.log("Loading PlayerBulkAddForm");
  const [saving, setSaving] = useState<boolean>(false);
  const [data, setData] = useState<PlayerData[]>([]);
  const [filesLabel, setFilesLabel] = useState<string | undefined>(undefined);
  const [files, setFiles] = useState<FileList | null>(null);
  const [readyToUpload, setReadyToUpload] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<File | null>(null);

  const { me } = useContext(AuthenticatedContext);

  const goBack = () => {
    navigate("list");
  };

  const save = useCallback(async (toSave: PlayerData) => {
    setSaving(true);
    // setErrorMessage(undefined);
    try {
      await DbApi.createPlayerData(toSave);
      enqueueSnackbar("Update successful", { variant: 'success' });
    } catch (error: any) {
      const message = extractErrorMessage(error, 'Problem saving data');
      enqueueSnackbar(message, { variant: 'error' });
      // setErrorMessage(message);
    } finally {
      setSaving(false);
    }
  }, [enqueueSnackbar]);

  // const updateFormValue = updateValue(setData);
  const navigate = useNavigate();

  const processSave = async (player: PlayerData) => {
    if (player) {
      console.log("processSave for player %s", player.name);
      await save(player);
    }
  };

  // convert height in inches to feet and inches
  const heightInFeet = (height: string) => {
    // Parse height in inches from string to number
    const heightInInches = parseInt(height);
    const feet = Math.floor(heightInInches / 12);
    const inches = heightInInches % 12;
    return feet + "'" + inches + '"';
  };

  const saveAllPlayers = async () => {
    if (data) {
      console.log("saveAllPlayers");
      for (let i = 0; i < data.length; i++) {
        if ((data[i].height + "").indexOf("'") === -1) {
          data[i].height = heightInFeet(data[i].height);
        }
        await processSave(data[i]);
      }
      navigate('list');
    }
  };

  const doUpload = async () => {
    const fileData = new FormData();
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // TODO: Show a warning snackbar if file is not a PNG or file size is greater than 1.5MB.
        if (files.item(i) === null || files.item(i).size > 1500000) continue;
        fileData.set("image", files.item(i));
        console.log("Uploading file: ", files.item(i).name + " size: " + files.item(i).size + " type: " + files.item(i).type);
        await axios.post(PLAYER_IMAGE_PATH + files.item(i).name, fileData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
          }
        }).then(function (response) {
          if (response.status === 201) {
            enqueueSnackbar("Player image upload successful", { variant: 'success' });
          }
        })
        .catch(function (err) {
          enqueueSnackbar("Player image upload failed" + err, { variant: 'error' });
        })
        setReadyToUpload(false);
      }
    }
  };

  const uploadRoster = async () => {
    const fileData = new FormData();
    console.log("File is %s", uploadFile?.name);
    if (uploadFile) {
      fileData.append("roster", uploadFile);
      await axios.post('./rest/db/roster', fileData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
        }
      }).then(function (response) {
        if (response.status === 200) {
          enqueueSnackbar("Roster upload successful", { variant: 'success' });
          setUploadFile(null);
          // response includes JSON array of Player objects.
          // Save response array to "data" state variable.
          setData(response.data);
        }
      })
      .catch(function (err) {
        enqueueSnackbar("Roster upload failed", { variant: 'error' });
      })
    }
  };

  const renderPlayer = (player: PlayerData) => {
    return (
      <ListItem
        key={player.id}
        sx={{}}
      >
        <ListItemAvatar>
          <Avatar src={PLAYER_IMAGE_PATH + player.image + "?uuid=" + me.cid} variant="square" >
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{color: 'lightgray'}}
          secondaryTypographyProps={{color: 'common.lightgray'}}
          primary={player.name + " | " + heightInFeet(player.height) + " | " + player.weight + "lbs | " + player.image}
          secondary={player.jersey + "  " + player.position + " (" + player.school + ")"}
        />
      </ListItem>
    );
  };

  const content = () => {
    return (
      <>
        <Stack direction="row" spacing="1">
          <TextField
            name="logoPrefix"
            value={filesLabel ? filesLabel : 'Player images'}
            disabled={true}
            variant='outlined'
            margin="none"
          />
          <input
            accept='image/*'
            name="logo"
            type="file"
            multiple={true}
            // value={data?.logo}
            style={{'margin':1, 'padding':15, 'outline':'solid gray 1.5px', 'borderRadius':5}}
            id="icon-button-file"
            onChange={(e) => {
              if (e && e.target && e.target.files) {
                setFiles(e.target.files);
                setReadyToUpload(true);
                // setUploadFile(e.target.files.item(0));
                setFilesLabel(e.target.files.length + " Player Images");
                // TODO: If file is a PNG, and file size is less than 1MB, then upload file.
              }
            }}
          >
          </input>
          <Button
            startIcon={<UploadFileIcon />}
            disabled={!readyToUpload}
            variant="contained"
            color="secondary"
            type="button"
            sx={{'margin':2}}
            onClick={doUpload}
          >
              Upload images
          </Button>
        </Stack>
        <Stack direction="row" spacing="1">
          <TextField
            name="rosterPrefix"
            value={'Roster file (CSV)'}
            disabled={true}
            variant='outlined'
            margin="none"
          />
          <input
            accept='text/csv'
            name="roster"
            type="file"
            multiple={false}
            // value={data?.logo}
            style={{'margin':1, 'padding':15, 'outline':'solid gray 1.5px', 'borderRadius':5}}
            id="icon-button-file"
            onChange={(e) => {
              if (e && e.target && e.target.files) {
                setUploadFile(e.target.files.item(0));
                // setFilename(e.target.files.item(0)?.name);
              }
            }}
          >
          </input>
          <Button
            startIcon={<UploadFileIcon />}
            disabled={!uploadFile}
            variant="contained"
            color="secondary"
            type="button"
            sx={{'margin':2}}
            onClick={uploadRoster}
          >
              Upload roster
          </Button>
        </Stack>
        <List sx={{bgcolor: '#000000c0'}}>
            <div>
              {data.map(renderPlayer)}
            </div>
        </List>
        <ButtonRow mt={1}>
          <Button startIcon={<CancelIcon />} disabled={saving} variant="contained" color="primary" type="button" onClick={goBack}>
            Cancel
          </Button>
          <Button startIcon={<SaveIcon />} disabled={saving} variant="contained" color="primary" type="submit" onClick={saveAllPlayers}>
            Save
          </Button>
        </ButtonRow>
      </>
    );
  };

  return (
    <SectionContent title='Bulk Add Player Info' titleGutter>
      {content()}
    </SectionContent>
  );
};

export default PlayerBulkAddForm;
