import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { AxiosPromise } from "axios";
import { extractErrorMessage } from ".";

export interface RestRequestOptions<D> {
  read: (param1?: string, param2?: string) => AxiosPromise<D>;
  update?: (value: D, param1?: string, param2?: string) => AxiosPromise<D>;
  param1?: string;
  param2?: string;
}

export const useRest = <D>({ param1, param2, read, update }: RestRequestOptions<D>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saving, setSaving] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);
  const [data, setData] = useState<D>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const loadData = useCallback(async () => {
    setData(undefined);
    setErrorMessage(undefined);
    if (!canceled) {
      try {
        var result = (await read(param1, param2)).data;
        if (!canceled) {
          setData(result);
        }
      } catch (error: any) {
        const message = extractErrorMessage(error, 'Problem loading data');
        enqueueSnackbar(message, { variant: 'error' });
        setErrorMessage(message);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [read, enqueueSnackbar, param1, param2]);

  const save = useCallback(async (toSave: D) => {
    if (!update) {
      return;
    }
    setSaving(true);
    setErrorMessage(undefined);
    try {
      var result = (await update(toSave, param1, param2)).data;
      if (!canceled) {
        setData(result);
      }
      enqueueSnackbar("Update successful", { variant: 'success' });
    } catch (error: any) {
      const message = extractErrorMessage(error, 'Problem saving data');
      enqueueSnackbar(message, { variant: 'error' });
      setErrorMessage(message);
    } finally {
      setSaving(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, enqueueSnackbar, param1, param2]);

  const saveData = () => data && save(data);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return { loadData, saveData, saving, setData, data, canceled, setCanceled, errorMessage } as const;
};
