import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { Box, Button, TextField, Card, CardContent, Typography, FormControlLabel, Switch } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import HTMLButtonElement from '@mui/material/Button';

import { SectionContent, FormLoader, ButtonRow } from '../components';
import { updateValue } from '../utils';

import { BrandData, CompetitorData, TeamData, defaultBrand, defaultCompetitor, defaultTeam } from './types';
import { TeamContext, DatabaseAccessContext } from './SchoolContext';
import { AuthenticatedContext } from "../contexts/authentication";
import { ACCESS_TOKEN } from "../api/endpoints";
// import { ImageSelectorProps } from './ImageSelector';
import RenderTeam, { RenderTeamProps } from './TeamLayout';
import BrandSelectorComponent, {BrandSelectorProps} from './BrandSelector';
import BrandEditor, { BrandEditorProps } from './BrandEditor';

const OpponentSetupForm: FC = () => {
  const { selectedTeam } = useContext(TeamContext);
  const { id } = useParams();
  const db = useContext(DatabaseAccessContext);
  const [initialized, setInitialized] = useState(false);
  const [label, setLabel] = useState("Team UI Info");
  const [open, setOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [brandToEdit, setBrandToEdit] = useState<BrandData>(null);
  // const [newBrandRequested, setNewBrandRequested] = useState(false);
  // const [adding, setAdding] = useState(false);
  const adding: boolean = id === undefined || id === "0";
  const navigate = useNavigate();
  const location = useLocation();
  const managingTeam: boolean = location.pathname.includes("myteams/") ? true : false;
  const { me, token } = useContext(AuthenticatedContext);
  const cid: number = me?.seq || 0;
  const newBrand = { ...defaultBrand, cid: cid } as BrandData;
  const newCompetitor = { ...defaultCompetitor, cid: cid, tid: selectedTeam?.id || 0, brand: newBrand } as CompetitorData;
  const newTeam = { ...defaultTeam, cid: cid, competitor: newCompetitor } as TeamData;
  // let startTeam: TeamData = newTeam;
  const [startTeam, setStartTeam] = useState<TeamData>(newTeam);
  const [editedTeam, setEditedTeam] = useState<TeamData>(startTeam);
  // let startCompetitor: CompetitorData = newCompetitor;
  const [startCompetitor, setStartCompetitor] = useState<CompetitorData>(newCompetitor);
  const [editedCompetitor, setEditedCompetitor] = useState<CompetitorData>(startCompetitor);


  // We need to provide our own isModified functions here because we are not updating the data in the useDatabase hooks
  const isEqual = require('lodash/isEqual');
  // boolean result to indicate whether the competitor data has been modified
  let isCompetitorModified: boolean = adding || !isEqual(editedCompetitor, startCompetitor);
  // boolean result to indicate whether the team data has been modified
  let isTeamModified: boolean = adding || !isEqual(editedTeam, startTeam);

  const openBrandSelector = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const openBrandEditor = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!editedCompetitor.brand || editedCompetitor.brand.id === 0) {
      openBrandSelector(event);
    } else {
      setBrandToEdit(editedCompetitor?.brand || newBrand);
      setEditorOpen(true);
    }
  };

  console.debug("OpponentSetupForm: ", "initialized: ", initialized, "id: ", id, "location: ", location.pathname, "managingTeam: ", managingTeam, "startTeam: ", startTeam, "startCompetitor: ", startCompetitor);

  localStorage.setItem(ACCESS_TOKEN, token);

  // #region [Side Effects]
  // If selectedTeam changes navigate back to list
  useEffect(() => {
    console.debug("OpponentSetupForm: selectedTeam changed: ", selectedTeam, initialized);
    if (initialized) {
      navigate("../list");
    };
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedTeam, navigate]);

  // Effect to run on component mount to copy the selected team data to the local state or start load of the data
  // NOTE: We purposely do not call the setSelectedItem method in the useDatabase hooks because we don't want to trigger the associated updates
  // when we are managing a team. 
  useEffect(() => {
    const loadData = async () => {
      console.debug("Initial load data: ", "Competitor: ", db.competitorDb.data, db.competitorDb.list, "Team: ", db.teamDb.data, db.teamDb.list, "Initialized: ", initialized);
      if (managingTeam) {
        if (adding) {
          setEditedTeam(newTeam);
          setEditedCompetitor(newCompetitor);
          setInitialized(true);
        } else {
          console.debug("Reading team data: ", id, db.teamDb.data, db.teamDb.list, "initialized: ", initialized);
          // Call the useDatabase hook to read the team data, passing true so that setData will be called with the data to trigger the useEffect below
          let team = await db.teamDb.read(parseInt(id || "0"), true);
          if (team?.id > 0 && team.id.toString() === id) {
            setStartTeam(team);
            setEditedTeam(team);
            setStartCompetitor(team?.competitor || newCompetitor);
            setEditedCompetitor(team?.competitor || newCompetitor);
            setInitialized(true);
          } else {
            console.debug("OpponentSetupForm: team data not loaded in intialization useEffect: ", team, "id: ", id, "initialized: ", initialized);
          }
        }
      } else {
        if (adding) {
          setEditedCompetitor(newCompetitor);
          setInitialized(true);
        } else {
          let competitor = await db.competitorDb.read(parseInt(id || "0"), true);
          if (competitor?.id > 0 && competitor.id.toString() === id) {
            setStartCompetitor(competitor);
            setEditedCompetitor(competitor);
            setInitialized(true);
          } else {
            console.debug("OpponentSetupForm: competitor data not loaded in intialization useEffect: ", competitor, "id: ", id, "initialized: ", initialized);
          }
        }
      }
    };
    loadData();
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { 
    console.debug("OpponentSetupForm: competitor or team data changed: ", startCompetitor, startTeam, "initialized", initialized);
    if (initialized) {
      if (adding) {
        setLabel("New Team Setup");
      } else if (startCompetitor?.brand && startCompetitor?.name) {
        setLabel("Team Info - " + startCompetitor.brand.name + " " + startCompetitor.name);
      } else {
        setLabel("Team Info");
      }
    }
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, startCompetitor.brand.name, startCompetitor.name, startTeam, adding]);

  useEffect(() => {
    console.debug("OpponentSetupForm: competitor or team data loaded from DB: ", db.teamDb.data, db.competitorDb.data, "id: ", id, "initialized", initialized);
    if (!initialized) {
      if (managingTeam) {
        // We got data from the db, set it into our local team variables
        if (db.teamDb.data && db.teamDb.data.id > 0 && db.teamDb.data.id.toString() === id) {
          setStartTeam(db.teamDb.data);
          setEditedTeam(db.teamDb.data);
          setStartCompetitor(db.teamDb.data?.competitor || newCompetitor);
          setEditedCompetitor(db.teamDb.data?.competitor || newCompetitor);
          setInitialized(true);
          console.debug("OpponentSetupForm: team data loaded, setting initialized: ", db.teamDb.data, "id: ", id);
        } else {
          console.debug("OpponentSetupForm: team data not loaded in useEffect: ", db.teamDb.data, "id: ", id, "initialized: ", initialized);
        }
      } else {
        if (db.competitorDb.data && db.competitorDb.data.id > 0 && db.competitorDb.data.id.toString() === id) {
          setStartCompetitor(db.competitorDb.data);
          setEditedCompetitor(db.competitorDb.data);
          setInitialized(true);
          console.debug("OpponentSetupForm: competitor data loaded, setting initialized: ", db.competitorDb.data, "id: ", id);
        } else {
          console.debug("OpponentSetupForm: competitor data not loaded in useEffect: ", db.competitorDb.data, "id: ", id, "initialized: ", initialized);
        }
      }
    }
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.teamDb.data, db.competitorDb.data, id, managingTeam]);

  // #endregion

  // #region [Event Handlers]
  const goBack = () => {
    navigate("../list");
  };

  const updateCompetitor = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("OpponentSetupForm: updateCompetitor: ", event.target.name, event.target.value, initialized);
      setEditedCompetitor({ ...editedCompetitor, [event.target.name]: event.target.value });
    }
  };

  const updateTeam = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("OpponentSetupForm: updateTeam: ", event.target.name, event.target.value, initialized);
      setEditedTeam({ ...editedTeam, [event.target.name]: event.target.value });
    }
  };

  // Callback for processing brand data not yet loaded retry event
  const retryBrandLoad = async () => {
    console.warn("OpponentSetupForm: retryBrandLoad: THIS SHOULDN'T HAPPEN: ", editedCompetitor, " adding: ", adding);
    // Since this is an unexpected condition, the "fix" is just to read the current comptitor data again, which should have brand data with it.
    await db.competitorDb.read(parseInt(id || "0"), true);
    if (managingTeam) {
      await db.teamDb.read(parseInt(id || "0"), true);
    }
  };
  // #endregion

  const processSave = async () => {
    if (adding) {
      // NOTE: when creating a new brand and new competitor at the same time, it can be done in one call to create a new competitor with the new brand embedded.
      // Set the competitor's brandId to zero, set the brand object to the new brand data, and set the brand's id to zero. The server will create both, and set
      // the brandId in the competitor to the new brand's id.
      console.debug("OpponentSetupForm: processSave: competitor to create: ", db.competitorDb.data);
      if (managingTeam) {
        await db.teamDb.create(editedTeam, true);
      } else {
        await db.competitorDb.create(editedCompetitor, true);
      }
    } else {
      if (db.brandDb.isModified && !isCompetitorModified) {
        console.warn("OpponentSetupForm: processSave: brand changed: ", db.brandDb.data);
        await db.brandDb.update();
        // Since this shouldn't be happening (all brand editing is now in the BrandEditor)
        // We won't bother updating references to this brand in the competitor or team data caches here
      }
      // Get the brand id from the brand data
      if (isCompetitorModified || (managingTeam && isTeamModified)) {
        console.debug("OpponentSetupForm: processSave: competitor or team changed: ", editedCompetitor, editedTeam, "teamModified: ", isTeamModified, "competitorModified: ", isCompetitorModified);
        if (managingTeam) {
          await db.teamDb.update(editedTeam);
          // There are no references to team data in the database, so no need to update anything else
        } else {
          const newCompetitor = await db.competitorDb.update(editedCompetitor);
          // Now update references to the competitor in the team data
          const teamList = await db.teamDb.getList();
          if (teamList) {
            teamList.forEach((team) => async () => {
              if (team.compId === newCompetitor.id) {
                await db.teamDb.update({ ...team, competitor: newCompetitor}, false, "", true);
              }
            });
            db.teamDb.clearStatus();
          }
        }
      }
    }
    navigate('../list');
  };

  const processDelete = async () => {
    console.log("Opponent or team to delete: %d", id);
    // NOTE: This deletes the Competitor record, but not the Brand record
    let idToDelete = parseInt(id || "0");
    if (managingTeam && idToDelete > 0) {
      db.teamDb.delete(idToDelete, false);
    } else if (db.competitorDb.data && selectedTeam) {
      db.competitorDb.delete(db.competitorDb.data.id, false, selectedTeam.id.toString());
    }
    navigate("../list");
  };
  // #endregion

  const renderTeamProps: RenderTeamProps = {
    brand: editedCompetitor.brand,
    uuid: me?.cid || ""
  };

  const brandSelectorProps = {
    onSelect: (item: BrandData) => {
      console.debug("OpponentSetupForm: brandSelectorProps: ", item, isCompetitorModified);
      setEditedCompetitor({ ...editedCompetitor, brandId: item.id, brand: item });
      if (managingTeam) {
        setEditedTeam({ ...editedTeam, competitor: { ...editedTeam.competitor, brandId: item.id, brand: item } });
      }
    },
    uuid: me?.cid || "",
    open: open,
    setOpen: setOpen,
    brandDb: db.brandDb
  } as BrandSelectorProps;

  const brandEditorProps: BrandEditorProps = {
    brand: brandToEdit,
    uuid: me?.cid || "",
    open: editorOpen,
    setOpen: setEditorOpen,
    onUpdateNotify: async (item) => {
      console.debug("BrandEditorProps.onUpdateHook: ", editedCompetitor);
      // This is to allow the OpponentSetup or TeamSetup components hosting the BrandEditor to sync their compound objects with the new brand data
      db.competitorDb.setData({ ...db.competitorDb.data, brandId: item.id, brand: item });
      setEditedCompetitor({ ...editedCompetitor, brandId: item.id, brand: item });
      if (managingTeam) {
        db.teamDb.setData({ ...db.teamDb.data, competitor: { ...db.teamDb.data.competitor, brandId: item.id, brand: item } });
        setEditedTeam({ ...editedTeam, competitor: { ...editedTeam.competitor, brandId: item.id, brand: item } });
      }
      setEditorOpen(false);
    }
  };
  // #endregion

  // #region [Brand Content UI]
  const brandContent = () => {
    console.debug("OpponentSetupForm: rendering brandContent: ", startCompetitor, editedCompetitor, "adding: ", adding);
    if (!adding && (!editedCompetitor?.brand || !editedCompetitor.brand.hasOwnProperty('id') || editedCompetitor.brand.id === 0)) {
      console.warn("brandContent: data is null or uninitialized: ", editedCompetitor, adding);
      return (<FormLoader onRetry={() => {retryBrandLoad()}} errorMessage={db.competitorDb.resultMessage? "Opponent data not loaded" : undefined} />);
    }
    return (
      <Card sx={{mt:2}}>
        <CardContent>
          <Typography variant="h6" color="textSecondary" component="p">
            Branding for the {startCompetitor.brand?.name + " " + startCompetitor.name} team.
          </Typography>
          <Stack direction="row" spacing={2}>
            <HTMLButtonElement onClick={openBrandEditor} sx={{}} >
              <Box component='fieldset' style={{ border: '1px solid grey', borderColor: "#00000061", color: "#00000061", borderRadius: '4px', textTransform: 'none' }}>
                <legend style={{color: "#00000091", fontSize: '12px'}}> Team Branding (Click to edit/select)</legend>
                <RenderTeam {...renderTeamProps} />
              </Box>
            </HTMLButtonElement>
            <Button onClick={openBrandSelector} variant="contained" color="primary">
              Select Existing Brand
            </Button>
          </Stack>
          <BrandSelectorComponent {...brandSelectorProps} />
          <BrandEditor {...brandEditorProps} />
        </CardContent>
      </Card>
    );
  }
  // #endregion

  // #region [Team Content UI]
  const teamContent = () => {
    console.debug("OpponentSetupForm: rendering teamContent: ", editedTeam, "adding: ", adding);
    if (!managingTeam) {
      return (<></>);
    } else if (!adding && (!editedTeam || !editedTeam.hasOwnProperty('id') || editedTeam.id === 0)) {
      console.warn("teamContent: data is null or uninitialized: ", editedTeam, adding);
      return (<FormLoader onRetry={() => {db.teamDb.read()}} errorMessage={db.teamDb.resultMessage? "Team data not loaded" : undefined} />);
    } else {
      return (
        // Checkbox for setting the team as the default team for the school
        <Stack direction="row" spacing={2}>
          <FormControlLabel
            name="isDefault"
            label="Default team?"
            control={<Switch 
              onChange={updateTeam}
              checked={editedTeam.isDefault}
            />}
          />
        </Stack>
      );
    }
  }
  // #endregion

  // #region [Content]
  const content = () => {
    if (!initialized || !editedCompetitor || (managingTeam && !editedTeam)) {
      console.debug("OpponentSetupForm: content: data not yet initialized: ", initialized, "competitor: ", editedCompetitor, "team: ", editedTeam);
      return (<FormLoader onRetry={() => {db.competitorDb.read();}} errorMessage={(!editedCompetitor && db.competitorDb.resultMessage) ? "Retry loading data ..." : undefined} />);
    }
    console.debug("OpponentSetupForm: content: rendering form: ", startCompetitor, editedCompetitor, startTeam, editedTeam, "adding: ", adding, "initialized: ", initialized);
    return (
      <>
        {teamContent()}
        <TextField
          name="name"
          label="Name"
          fullWidth
          variant="outlined"
          value={editedCompetitor.name || ""}
          placeholder={"Enter the team name here ..."}
          onChange={updateCompetitor}
          margin="normal"
        />
        <TextField
          name="win"
          label="Wins this season"
          fullWidth
          variant="outlined"
          // eslint-disable-next-line eqeqeq
          value={editedCompetitor.win == undefined ? "" : editedCompetitor.win.toString()}
          onChange={updateCompetitor}
          margin="normal"
        />
        <TextField
          name="loss"
          label="Losses this season"
          fullWidth
          variant="outlined"
          // eslint-disable-next-line eqeqeq
          value={editedCompetitor.loss == undefined ? "" : editedCompetitor.loss.toString()}
          onChange={updateCompetitor}
          margin="normal"
        />
        <ButtonRow mt={1}>
          <Button startIcon={<CancelIcon />} disabled={db.competitorDb.saving} variant="contained" color="primary" type="button" onClick={goBack}>
            Cancel
          </Button>
          <Button startIcon={<DeleteIcon />} disabled={db.competitorDb.saving} variant="contained" color="primary" type="button" onClick={processDelete}>
            Delete
          </Button>
          <Button startIcon={<SaveIcon />} disabled={db.competitorDb.saving || (!isCompetitorModified && !(managingTeam && isTeamModified))} variant="contained" color="primary" type="submit" onClick={processSave}>
            Save
          </Button>
        </ButtonRow>
        {brandContent()}
      </>
    );
  };

  return (
    <SectionContent title={label} titleGutter>
      {content()}
    </SectionContent>
  );
  // #endregion
};

export default OpponentSetupForm;
