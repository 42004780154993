import { kvPair } from "./ImageSelector";

export interface DbItem {
  id: number;
  cid: number;
}

export interface LiveClock extends DbItem {
  tid: number;
  clock: string;
  playClock: string;
  timeoutClock: string;
}

export interface SchoolData extends DbItem {
  tid: number;
  name: string;
  mascot: string;
  color: string;
  color2: string;
  logo: string;
  win: number;
  loss: number;
}

export interface SchoolsList {
  schools: SchoolData[];
}

export interface SchoolMap {
  schools: Map<number, SchoolData>;
}

export interface GameRecord extends DbItem{
  tid: number;
  vs: number;
  home: boolean;
  date: string;
  location?: string;
  url?: string;
  dataSource?: string;
}

// export a default value for the GameRecord type
export const defaultGame: GameRecord = {
  id: 0,
  cid: 0,
  tid: 0,
  vs: 0,
  home: false,
  date: "",
  location: "",
  url: "",
  dataSource: ""
};

export interface GameData extends DbItem {
  tid: number;
  gameDate: string;
  homeName: string;
  homeMascot: string;
  homeColor: string;
  homeIcon: string;
  homeRecord: string;
  guestName: string;
  guestMascot: string;
  guestColor: string;
  guestIcon: string;
  guestRecord: string;
  home: boolean;
  location: string;
  url: string;
  dataSource: string;
}

export interface GameList {
  games: GameData[];
}

export interface GameMap {
  games: Map<number, GameData>;
}

export interface PlayerData extends DbItem{
  tid: number;
  name: string;
  jersey: number;
  image: string;
  position: string;
  height: string;
  weight: number;
  year: number;
  school: string;
}

export interface PlayerList {
  players: PlayerData[];
}

export interface PlayerGroups {
  [key:string]: PlayerData[];
}

// export a default value for the PlayerData type
export const defaultPlayer: PlayerData = {
  id: 0,
  cid: 0,
  tid: 0,
  name: "",
  jersey: 0,
  image: "",
  position: "",
  height: "",
  weight: 0,
  year: 0,
  school: ""
};

export interface SponsorData extends DbItem{
  tid: number;
  name: string;
  image: string;
  image_scroll?: string;
  image_square?: string;
  plan?: string;
}

export interface SponsorList {
  sponsors: SponsorData[];
}

// export a default value for the SponsorData type
export const defaultSponsor: SponsorData = {
  id: 0,
  cid: 0,
  tid: 0,
  name: "",
  image: "",
  image_scroll: null,
  image_square: null,
  plan: null
};

export interface ImageLocationItem {
  name: string;
  url: string;
  metadata: kvPair;
}

export interface ImageLocationList {
  images: ImageLocationItem[];
}

export interface ImageLocationMap {
  images: Map<string, ImageLocationItem>;
}

export interface BrandData extends DbItem {
  name: string;
  mascot: string;
  color: string;
  color2: string;
  logo: string;
}

export interface BrandList {
  brands: BrandData[];
}

export interface BrandMap {
  brands: Map<number, BrandData>;
}

// Export a default value for the BrandData type
export const defaultBrand: BrandData = {
  id: 0,
  cid: 0,
  name: "",
  mascot: "",
  color: "#000000",
  color2: "#000000",
  logo: ""
};

export interface CompetitorData extends DbItem{
  tid: number;
  brandId: number;
  win?: number;
  loss?: number;
  name?: string;
  brand?: BrandData;
}

export interface CompetitorList {
  competitors: CompetitorData[];
}

export interface CompetitorMap {
  competitors: Map<number, CompetitorData>;
}

// Export a default value for the CompetitorData type
export const defaultCompetitor: CompetitorData = {
  id: 0,
  cid: 0,
  tid: 0,
  brandId: 0,
  win: 0,
  loss: 0,
  name: "",
  brand: defaultBrand
};

export interface TeamData extends DbItem{
  name: string;
  isDefault: boolean;
  compId: number;
  competitor?: CompetitorData;
}

export interface TeamList {
  teams: TeamData[];
}

export interface TeamMap {
  teams: Map<number, TeamData>;
}

export const defaultTeam: TeamData = {
  id: 0,
  cid: 0,
  name: "(Select Team)",
  isDefault: false,
  compId: 0,
  competitor: defaultCompetitor
};

export interface CurrentItem extends DbItem {
  tid: number;
  requester: string;
};

export interface OverlayTimeouts {
  hilight: number;
  ad: number;
  goal: number;
  penalty: number;
  timeout: number;
};

export const defaultTimeouts: OverlayTimeouts = {
  hilight: 10,
  ad: 5,
  goal: 5,
  penalty: 5,
  timeout: 5
};

// export enumerated list of valid actions (list, create, read, update, delete)
export type Action = 'list' | 'create' | 'read' | 'update' | 'delete' | 'refresh' | 'revert' | 'init' | 'none';
