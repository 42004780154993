import { FC, useContext } from 'react';

import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { SectionContent, FormLoader, ButtonRow } from '../components';
// import { useRest } from '../utils';
// import * as SbApi from './api';
import { TeamData, defaultTeam } from './types';
import { DatabaseAccessContext } from './SchoolContext';
import { useNavigate } from 'react-router-dom';
import { TEAM_IMAGE_PATH } from './projConfig';
import { AuthenticatedContext } from "../contexts/authentication";
import { ACCESS_TOKEN } from "../api/endpoints";

const TeamListForm: FC = () => {
  const navigate = useNavigate();
  const db = useContext(DatabaseAccessContext).teamDb;

  const { me, token } = useContext(AuthenticatedContext);
  const cid: number = me?.seq || 0;
  // console.log("TeamListForm: " + me.name + " " + token);
  localStorage.setItem(ACCESS_TOKEN, token);

  const handleAdd = () => {
    console.log("Launching add page");
    // We don't actually want to change the selected team
    db.setData({...defaultTeam, cid: cid, competitor: {...defaultTeam.competitor, cid: cid, brand: {...defaultTeam.competitor.brand, cid: cid}}});
    navigate("../add/0");
  };

  const handleClick = (team: TeamData) => {
    console.log("Launching setup page for team: ", team);
    // We don't actually want to change the selected team, but we do want to read the team to edit into the "data" field
    db.read(team.id);
    navigate("../setup/" + team.id);
  }

  const renderTeam = (team: TeamData) => {
    return (
      <ListItem
        key={team.id}
        sx={{bgcolor: team.competitor.brand.color + "80"}}
        button
        onClick={() => handleClick(team)}
      >
        <ListItemAvatar>
          <Avatar src={TEAM_IMAGE_PATH + team.competitor.brand.logo + "?uuid=" + me.cid} variant="square" >
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{color: 'lightgray'}}
          secondaryTypographyProps={{color: 'common.lightgray'}}
          primary={team.name}
          secondary={team.competitor.brand.mascot + " (Record: " + team.competitor.win + "-" + team.competitor.loss + ")"}
        />
      </ListItem>
    );
  };

  const content = () => {
    if (!db.list) {
      return (<FormLoader onRetry={() => db.getList()} errorMessage={db.resultMessage ? "Retry loading list of your teams ..." : undefined} />);
    }
    if (!Array.isArray(db.list)) {
        return (<FormLoader onRetry={() => db.getList()} errorMessage={db.resultMessage ? "Retry loading list of your teams ..." : undefined} />);
    }

    return (
      <>
        <List sx={{bgcolor: '#000000c0'}}>
            <div>
              {db.list.map(renderTeam)}
            </div>
        </List>
        <ButtonRow mt={1}>
          <Button startIcon={<AddBoxIcon />} variant="contained" color="primary" type="button" onClick={handleAdd}>
            Add
          </Button>
        </ButtonRow>
      </>
    );
  };

  return (
    <SectionContent title='Your Teams' titleGutter>
      {content()}
    </SectionContent>
  );
};

export default TeamListForm;
