// #region [Imports]
import { FC, useContext, useEffect, useRef, useState } from 'react';

import { Button, MenuItem, TextField, TextFieldProps, Switch, FormControlLabel } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { SectionContent, FormLoader, ButtonRow } from '../components';
import { updateValue, getDateTimeString, toUTC } from '../utils';
// import * as DbApi from './api';
import { GameRecord, CompetitorData, defaultGame } from './types';
import { GameContext, TeamContext, DatabaseAccessContext } from './SchoolContext';
import { useNavigate, useParams } from 'react-router-dom';
// #endregion

// setup time locale stuff
dayjs.extend(utc);
dayjs.extend(timezone);

const GameSetupForm: FC = () => {
  // #region [State Initialization]
  const { selectedGame, competitorMap } = useContext(GameContext);
  const { selectedTeam } = useContext(TeamContext);
  const competitors = Array.from(competitorMap.values()).filter((c) => c.id !== selectedTeam?.id);
  const { id } = useParams();
  const idNum: number = parseInt(id) || 0;
  const db = useContext(DatabaseAccessContext).gameDb;
  const [initialized, setInitialized] = useState(false);
  const [isHomeGame, setIsHomeGame] = useState<boolean>(selectedGame?.home);
  const [opposingTeamId, setOpposingTeamId] = useState(selectedGame?.vs || competitors[0].id);
  const [datetime, setDateTime] = useState<dayjs.Dayjs>(dayjs.utc(selectedGame?.date || undefined));
  const [gameLocation, setGameLocation] = useState(selectedGame?.location || "");
  const [gameUrl, setGameUrl] = useState(selectedGame?.url || "");
  const [scoreSource, setScoreSource] = useState(selectedGame?.dataSource || "");
  const [isModified, setIsModified] = useState(false);
  const homeTeamField = useRef<TextFieldProps>(null);
  const opposingTeamField = useRef<TextFieldProps>(null);
  const gameDateTime = useRef(null);
  const adding: boolean = !(idNum > 0);
  const [label, setLabel] = useState("Game Info");
  const newItem = { ...defaultGame } as GameRecord;

  const navigate = useNavigate();
  console.debug("GameSetupForm rendering game " + id, selectedGame);
  // #endregion

  // #region [Helper Methods]
  function _newDate() {
    console.debug("datetime", datetime);
    console.debug('UTC check:', getDateTimeString(datetime), getDateTimeString(datetime.utc()));
    return getDateTimeString(datetime.utc());
  }
  // #endregion

  // #region [Effects]
  useEffect(() => {
    const loadData = async () => {
      console.debug("Initial load data: ", db.data, initialized);
      if (adding) {
        db.setData({...newItem, cid: selectedTeam?.cid, tid: selectedTeam?.id});
        setLabel("New Game Setup");
        setInitialized(true);
      } else {
        let dbItem = await db.read(idNum, true);
        setLabel("Game Info");
        if (dbItem?.id > 0 && dbItem.id === idNum) {
          setInitialized(true);
        } else {
          console.debug("GameSetupForm: data not loaded in initialization useEffect: ", id, idNum, db.data);
        }
      }
    };
    loadData();
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect to run when the data is loaded into the useDatabase hook
  useEffect(() => {
    console.debug("*SetupForm: selected item loaded from DB: ", db.data, "id: ", id, "initialized: ", initialized);
    if (!initialized) {
      if (db.data?.id > 0 && db.data.id === idNum) {
        setInitialized(true);
      }
    }
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.data, id]);

  // #region [Callbacks]
  const listCompetitors = (competitor: CompetitorData) => {
    return (
      <MenuItem
        key={competitor.id}
        value={competitor.id}
      >
        {competitor.brand.name + " " + competitor.brand.mascot}
      </MenuItem>
    );
  };

  const goBack = () => {
    db.setData(undefined);
    navigate("../list");
  };

  const processSave = async () => {
    var game: GameRecord = {id: selectedGame?.id || 0, 
      cid: selectedGame?.cid || selectedTeam?.cid || 0,
      tid: selectedGame?.tid || selectedTeam?.id || 0,
      home: isHomeGame || false, 
      vs: opposingTeamId || 0, 
      date: _newDate(),
      location: gameLocation,
      url: gameUrl,
      dataSource: scoreSource
    }
    game.date = toUTC(game.date);
    if (game.id === 0) {
      console.debug("Adding game.", selectedGame, game);
      await db.create(game);
    } else {
      console.debug("Updating game.", selectedGame, game);
      await db.update(game);
    }
    navigate("../list");
  };

  const addOpponent = () => {
    navigate("../../opponents/add/0");
  };

  const changeIsHomeGame = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("changeIsHomeGame event", event.target.checked)
      setIsModified(true);
      setIsHomeGame(event.target.checked);
    }
  };

  const changeOpposingTeam = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("Opponent changed to %s", event.target.value);
      setIsModified(true);
      setOpposingTeamId(event.target.value);
    }
  };

  const _changeDate = (d: any) => {
    // TODO: validate constraints?
    setDateTime(d);
    setIsModified(true);
    // data object is made in processSave, not stored in state
    // console.debug('DateTime change:', d);
  }

  const changeLocation = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("Location changed to %s", event.target.value);
      setIsModified(true);
      setGameLocation(event.target.value);
    }
  }

  const changeUrl = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("URL changed to %s", event.target.value);
      setIsModified(true);
      setGameUrl(event.target.value);
    }
  }

  const changeScoreSource = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("Score source changed to %s", event.target.value);
      setIsModified(true);
      setScoreSource(event.target.value);
    }
  }

  const _openPicker = (d: any) => {
    console.debug('Opened DateTime Picker:', d);
    // console.debug('dt:', datetime);
  }
  // #endregion

  // #region [Component Hooks]

  // If selectedTeam changes navigate back to list
  // NOTE: We don't want this to execute on the initial load during which selectedTeam will change, only after the component is initialized
  // As a result, we purposely don't include initialized in the dependency array
  useEffect(() => {
    if (initialized) {
      navigate("../list");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, navigate]);
  // #endregion

  // #region [Content]
  const content = () => {
    if (!initialized || !db.data) {
      return (<FormLoader onRetry={() => {db.read()}} errorMessage={ db.resultMessage ? "Retry loading game data ..." : undefined } />);
    }

    return (
      <>
        <TextField
          name="gameId"
          label="Game ID"
          value={selectedGame.id}
          disabled
          margin='normal'
          fullWidth
          onClick={() => {navigate("/scorebughd?" + selectedGame.id.toString());}}
        >
        </TextField>
        <br />
        <FormControlLabel
          inputRef={homeTeamField}
          name="homeGame"
          label="Home game?"
          control={<Switch 
            onChange={changeIsHomeGame}
            checked={isHomeGame}
          />}
        />
        <br />
        <TextField
          inputRef={opposingTeamField}
          name="awayId"
          label="Opponent"
          fullWidth
          select
          value={opposingTeamId}
          onChange={changeOpposingTeam}
          margin="normal"
        >
          {competitors.map(listCompetitors)}
        </TextField>
        <br />
        <DateTimePicker 
          label={"Game Time (" + dayjs.tz.guess() + ")"}
          value={datetime}
          inputRef={gameDateTime}
          onChange={_changeDate}
          onViewChange={_openPicker}
        />
        <TextField
          name="location"
          label="Location"
          value={gameLocation}
          margin='normal'
          fullWidth
          onChange={changeLocation}
        >
        </TextField>
        <TextField
          name="url"
          label="Game Stream URL"
          value={gameUrl}
          margin='normal'
          fullWidth
          onChange={changeUrl}
        >
        </TextField>
        <TextField
          name="dataSource"
          label="Game Score Source"
          value={scoreSource}
          margin='normal'
          fullWidth
          onChange={changeScoreSource}
        >
        </TextField>
        <ButtonRow mt={1}>
          <Button startIcon={<CancelIcon />} disabled={db.saving} variant="contained" color="primary" type="button" onClick={goBack}>
            Cancel
          </Button>
          <Button startIcon={<DeleteIcon />} disabled={db.saving || idNum <= 0} variant="contained" color="primary" type="button" onClick={() => {db.delete(); navigate("../list");}}>
            Delete
          </Button>
          <Button startIcon={<SaveIcon />} disabled={db.saving || !isModified} variant="contained" color="primary" type="submit" onClick={processSave}>
            Save
          </Button>
          <Button disabled hidden />
          <Button startIcon={<AddBoxIcon />} variant="contained" color="secondary" type="button" onClick={addOpponent}>
            Add Opponent
          </Button>
        </ButtonRow>
      </>
    );
  };
  // #endregion

  return (
    <SectionContent title={label} titleGutter>
      {content()}
    </SectionContent>
  );
};

export default GameSetupForm;
