import { FC, useContext } from 'react';

import { Grid } from '@mui/material';
import { Item, HomeItem, GuestItem, sendActionCmd, SelectedItemContext } from '../../Layouts/ControllerLayout';

const FootballScoreController: FC = () => { 
  const { item } = useContext(SelectedItemContext);
  return (
  <div>
    <Grid container spacing={2} columns={14}>
      { 
        // Third Row of Buttons 
      }
      <Grid item xs={2}>
        <HomeItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/homescore', '3'); }} fullWidth>Home<br/>+3</HomeItem>
      </Grid>
      <Grid item xs={2}>
        <HomeItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/hometol', 'Reset'); }} fullWidth>Timeout<br/>Reset</HomeItem>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'score/togo', '-5'); }} fullWidth>To Go<br/>-5</Item>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'score/down', 'Up'); }} fullWidth>Down<br/>+1</Item>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'score/togo', '5'); }} fullWidth>To Go<br/>+5</Item>
      </Grid>
      <Grid item xs={2}>
        <GuestItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/guestscore', '3'); }} fullWidth>Guest<br/>+3</GuestItem>
      </Grid>
      <Grid item xs={2}>
        <GuestItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/guesttol', 'Reset'); }} fullWidth>Timeout<br/>Reset</GuestItem>
      </Grid>
      { 
        // Fourth Row of Buttons
      }
      <Grid item xs={2}>
        <HomeItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/homescore', '6'); }} fullWidth>Home<br/>+6</HomeItem>
      </Grid>
      <Grid item xs={2}>
        <HomeItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/changeposs', 'Home'); }} fullWidth>Poss<br/>&lt;</HomeItem>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'score/togo', '-1'); }} fullWidth>To Go<br/>-1</Item>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'score/down', 'Reset'); }} fullWidth>First<br/>Down</Item>
      </Grid>
      <Grid item xs={2}>
        <Item variant='outlined' onClick={() => { sendActionCmd(item, 'score/togo', '1'); }} fullWidth>To Go<br/>+1</Item>
      </Grid>
      <Grid item xs={2}>
        <GuestItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/guestscore', '6'); }} fullWidth>Guest<br/>+6</GuestItem>
      </Grid>
      <Grid item xs={2}>
        <GuestItem variant='outlined' onClick={() => { sendActionCmd(item, 'score/changeposs', 'Guest'); }} fullWidth>Poss<br/>&gt;</GuestItem>
      </Grid>
    </Grid>
  </div>
)};

export default FootballScoreController;
