import React, { FC, useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useLayoutTitle } from '../components';
// import { AuthenticatedContext } from '../contexts/authentication';
import { TeamContext, DatabaseAccessContext } from './SchoolContext'; 
// import { SponsorContext } from './OverlayAssetsContext';
// import SponsorAddForm from './SponsorAddForm';
import { useSnackbar } from 'notistack';

const SponsorMain: FC = () => {
  useLayoutTitle("Manage Sponsors");

  const { selectedTeam } = useContext(TeamContext);
  const { enqueueSnackbar } = useSnackbar();
  const db = useContext(DatabaseAccessContext);

  // Initial load of list of sponsors
  useEffect(() => {
    (async () => {
    console.log("SponsorMain: Initial load of sponsors. tid: ", selectedTeam?.id.toString() || "0");
    await db.sponsorDb.getList();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If db.status or db.resultMessage changes, set a snackbar message to display the resultMessage
    if (db.sponsorDb.status && db.sponsorDb.resultMessage) {
      enqueueSnackbar(db.sponsorDb.resultMessage, { variant: db.sponsorDb.status as any });
      db.sponsorDb.clearStatus();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.sponsorDb.status, db.sponsorDb.resultMessage]);

  return (
    <>
      <Outlet />
    </>
  );

};

export default SponsorMain;
