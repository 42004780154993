import { FC, useContext } from 'react';
import { Divider, List } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ProjectMenu from '../../project/ProjectMenu';
import { AuthenticatedContext } from '../../contexts/authentication';
import LayoutMenuItem from './LayoutMenuItem';

const LayoutMenu: FC = () => {
  const authenticatedContext = useContext(AuthenticatedContext);

  return (
    <>
      <List disablePadding component="nav">
        <ProjectMenu />
        {authenticatedContext.me.admin && (
        <>
          <Divider />
          <LayoutMenuItem icon={LockIcon} label="Security" to="/security" disabled={!authenticatedContext.me.admin} />
        </>
        )}
      </List>
    </>
  );
};

export default LayoutMenu;
