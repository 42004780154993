// This custom hook is designed to access and manage data from a database that is accessed via REST API calls defined in the project/api.ts file.
// The useDatabase hook is used to manage the state of the data being accessed, including loading, saving, and deleting data.
// To use the hook, provide the API function that will be used to access the data, as well as any initial data that should be loaded for the
// currently selected object and a param that is sent to the api with every call. 
// The API function should support 5 access actions, 'list', 'read', 'update', 'create', and 'delete'.
// To use the hook, after initializing the hook with the API function, initial data value, and parameter, call the setSelectedItemId function
// to set the id of the database item to be accessed. Then call the desired action to trigger the API call.
// The hook will handle the API call and manage the state of the data, including loading, saving, and deleting data.
// To access data from the hook, use the data and list properties, which contain the current data object and list of data objects, respectively.
// The saving property indicates whether the data is currently being saved, and the resultMessage property contains any messages returned from the API call.
// Callers should use a useEffect hook to monitor changes to the data and list properties, and the status and resultMessage properties to handle any errors or success messages.

import { useCallback, useRef, useState } from "react";
import { AxiosPromise } from "axios";
import { Action, DbItem } from "../project/types";
import { useIndexedDB } from 'react-indexed-db-hook';

import { extractErrorMessage } from ".";

export interface DataRequestOptions<D> {
  api: (action: Action, data?: D, id?: number, param?: string) => AxiosPromise<D | D[] | void>;
  dbName?: string;
  initialData?: D;
  param?: string;
}

// Define a data type for the returned values and methods of the useDatabase hook
export interface DatabaseHook<D> {
  saving: boolean;
  data: D;
  list: D[];
  resultMessage: string;
  status: string;
  param: string;
  selectedItemId: number;
  httpStatus: number;
  setData: (data: D) => void;
  setSelectedItemId: (id: number) => void;
  setParam: (param: string) => void;
  isModified: boolean;
  getList: (update?: boolean, param?: string) => Promise<D[]>;
  read: (id?: number, update?: boolean, param?: string) => Promise<D>;
  update: (updateObject?: D, update?: boolean, param?: string, updateCacheOnly?: boolean) => Promise<D>;
  create: (updateObject?: D, update?: boolean) => Promise<D>;
  delete: (id?: number, update?: boolean, param?: string) => Promise<number>;
  init: (objectId?: number, param?: string) => Promise<void>;
  clearStatus: () => void;
}

// TODO: Add a copy of data that reflects what was last read from or written to the database
// Use this copy to determine whether the data has changed and needs to be saved, and to provide
// a way to revert to the last saved state if the user decides to cancel the changes, and to 
// provide a isModified flag that can be used to enable/disable the save button in UI.
// The dbItemCopy does not need to be provided to the caller, but should be used internally to
// manage the state of the data object, and only the isModified flag should be exposed to the caller.

// TODO: Add a 'refresh' action that will force reload the data from the database. This will be useful
// because we are going to avoid reloading from the network when the data is already in the list, but
// we want to allow the user to force a reload in case the data has changed in the database.

export const useDatabase = <D extends DbItem>({ api, dbName, initialData, param }: DataRequestOptions<D>) => {
// #region [State Variables]
  // const [name, setName] = useState<string>(dbName || 'Database'); // This is used to store the name of the table in indexedDb
  const [saving, setSaving] = useState<boolean>(false);
  const [canceled, setCanceled] = useState<boolean>(false);
  const [data, setData] = useState<D>(initialData || ({} as D));
  // const [dbView, setDbView] = useState<D>({} as D); // This is used to store the last read or written data
  const [list, setList] = useState<D[]>(); // This will be used for list actions
  const [resultMessage, setResultMessage] = useState<string>();
  const [status, setStatus] = useState<string>(''); // This will be 'success' or 'error' or empty
  // const [action, setAction] = useState<Action>(undefined); // Leaving this as undefined so that when caller sets it to 'list' it will trigger the loadList function
  const [selectedItemId, setSelectedItemId] = useState<number>(0);
  const [dbParam, setDbParam] = useState<string>(param || '');
  const [httpStatus, setHttpStatus] = useState<number>(0); // This will be used to store the HTTP status code of the last API call to allow callers to detect 403 errors.
  // const [loading, setLoading] = useState<boolean>(false);
  const dataRequested = useRef(false);
// #endregion

  const isEqual = require('lodash/isEqual');
  const indexedDb = useIndexedDB(dbName);
  const statusDb = useIndexedDB('syncstatus');

  // #region [Helper Functions]
  const setParam = async (param: string) => {
    console.debug("useDatabase: setParam ", param, " dbParam: ", dbParam, " list: ", list, " table: ", dbName);
    if (param === dbParam)
      return;
    setList(undefined);
    setData(initialData || {} as D);
    setSelectedItemId(0);
    if (indexedDb !== undefined) {
      indexedDb.clear();
      if (statusDb !== undefined) {
        statusDb.deleteRecord(dbName);
      }
    }
    setDbParam(param);
  };

  // This function is called to set the selected item in the list. 
  /* CAUTIONS: 
      1. The list must be iniitalized before calling this funtion.
      2. The id must be a valid id in the list.
  */
  const setSelectedItem = async (id: number) => {
    console.debug("useDatabase: setSelectedItem ", id, " selectedItemId: ", selectedItemId, " list: ", list, " data: ", data);
    if (id === 0) {
      console.debug("Setting selected item to 0");
      setSelectedItemId(0);
      setData(initialData || undefined); // TODO: Or should we always set it to undefined?
      return;
    }
    if (list && list.length > 0 && list.find(item => item.id === id)) {
      if (id === selectedItemId && id === data.id) {
        console.debug("Selected item is already set to ", id);
        return;
      }
      setSelectedItemId(id);
      setData(list.find(item => item.id === id));
      console.debug("Selected item found, set to ", id, " data: ", data);
    } else {
      console.debug("Selected item not found, set to 0");
      setSelectedItemId(0);
      // setData(initialData || {} as D);
      setData(undefined);
    }
    // setSelectedItemId(id);
    return;
  }

  // boolean result to indicate whether the data has been modified since the last read from database
  let isModified: boolean = !(list && isEqual(data, list.find(item => item.id === selectedItemId)));
  //#endregion

  // #region [List Action]
  // 'list' action
  const loadList = useCallback(async (updateList: boolean = true, param: string = dbParam, forceRefresh: boolean = false) => {
    if (resultMessage && !canceled)
      setResultMessage(undefined);
    if (status && !canceled)
      setStatus(undefined);
    // If the list is already loaded, don't reload it. If any key parameters chanage we will clear the list in the useEffect hook.
    // We will also provide a 'refresh' action that will force reload the list from the database.
    if (list && list.length > 0 && !forceRefresh) {
      if (param === dbParam) {
        console.debug("useDatabase: loadList cache hit " + dbName, list, "param: ", param, " dataRequested: ", dataRequested.current, " updateList: ", updateList);
        return list;
      }
    }
    if (list && list.length > 0 && forceRefresh) {
      setList([]);
      if (indexedDb !== undefined) {
        indexedDb.clear();
        if (statusDb !== undefined) {
          statusDb.deleteRecord(dbName);
        }
      }
    }
    // If the list is not already loaded, see if it is in indexedDb
    if (indexedDb !== undefined) {
      const dbList = (await indexedDb.getAll()) as {id: number, data: D, updateTime: number}[];
      if (!canceled && dbList && dbList.length > 0) {
        const dbData = dbList.map(item => item.data);
        if (dbData && dbData.length > 0) {
          console.debug("useDatabase: loadList from indexedDb " + dbName, dbData);
          setList(dbData);
          return dbData;
        }
      }
    }
    if (!canceled && (!dataRequested.current || !updateList)) {
      console.debug("useDatabase: loadList cache miss, loading list from database " + dbName, "List: ", list, "param: ", param, " dataRequested: ", dataRequested.current, " updateList: ", updateList);
      try {
        if (updateList) {
          dataRequested.current = true;
        }
        var result = (await api('list', data, selectedItemId, param));
        if (!canceled) {
          setHttpStatus(result.status);
          if (updateList) {
            console.debug("useDatabase: loadList cache miss, loaded list from database " + dbName, result.data);
            setList(result.data as D[]);
            dataRequested.current = false;
            if (indexedDb !== undefined) {
              indexedDb.clear();
              // iterate through the list and add each item to the indexedDb
              for (let i = 0; i < (result.data as D[]).length; i++) {
                indexedDb.add({id: (result.data as D[])[i].id, data: (result.data as D[])[i], updateTime: new Date().getTime()});
              }
              if (statusDb !== undefined) {
                statusDb.update({name: dbName, syncTime: new Date().getTime()});
              }
            }
          }
          setStatus('success');
          return result.data as D[];
          // See if the new list contains the currenlty selected item, and if it doesn't reset data and selectedItemId to the first item in the list
          // TODO: Determine whether there are legitimate cases where we would not want to clear the data and selectedItemId when the list is reloaded
          /*
          if (selectedItemId > 0 && result.data && result.data.constructor === Array) {
            const item = (result.data as D[]).find(item => item.id === selectedItemId);
            if (!item) {
              setData((result.data as D[])[0]);
              // setDbView((result.data as D[])[0]);
              setSelectedItemId((result.data as D[])[0].id);
            }
          }
          */
          // setData(initialData || {} as D);
          // setSelectedItemId(0);
        }
      } catch (error: any) {
        // TODO: Consider whether we should clear the list if there is an error loading it from the database
        setHttpStatus(error?.response?.status || 400);
        setResultMessage(extractErrorMessage(error, 'Problem loading data'));
        setStatus('error');
      }
    }
    // This will only be reached if the current list is empty or doesn't match the passed in param and no data was loaded from the database
    // NOTE: It may be better to return an empty array, which should be done in the catch block above if there is an error loading the data
    return list;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, data, list, selectedItemId, dbParam]);
  // #endregion

  // #region [Read Action]
  // 'read' action
  const loadData = useCallback(async (objectId: number = selectedItemId, updateData: boolean = true, param: string = dbParam, forceRefresh: boolean = false) => {
    // setData(undefined);
    if (resultMessage && !canceled)
      setResultMessage(undefined);
    if (status && !canceled)
      setStatus(undefined);
    // Check to see if the requested item is in the list based on objectId
    if (list && list.length > 0 && !forceRefresh) {
      const item = list.find(item => item.id === objectId);
      if (item && (param === dbParam)) {
        console.debug("useDatabase: loadData cache hit " + dbName, item, "param: ", param, " updateData: ", updateData);
        if (!canceled) {
          if (updateData)
            setData(item);
          setStatus('success');
          setHttpStatus(200);
        }
        return item;
      }
    }
    // Check to see if the requested item is in indexedDb
    if (indexedDb !== undefined) {
      const dbItem = (await indexedDb.getByID(objectId)) as {id: number, data: D, updateTime: number};
      if (dbItem && (param === dbParam)) {
        console.debug("useDatabase: loadData from indexedDb " + dbName, dbItem);
        if (!canceled) {
          if (updateData)
            setData(dbItem.data);
          setStatus('success');
          setHttpStatus(200);
        }
        return dbItem.data;
      }
    }
    if (!canceled) {
      try {
        var result = (await api('read', data, objectId, param));
        if (!canceled) {
          setHttpStatus(result.status);
          if (updateData)
            setData(result.data as D);
          setStatus('success');
        }
        return result.data as D;
      } catch (error: any) {
        if (!canceled) {
          if (forceRefresh) {
            setData(initialData || {} as D);
          }
          setHttpStatus(error?.response?.status || 400);
          setResultMessage(extractErrorMessage(error, 'Problem loading data'));
          setStatus('error');
        }
      }
    }
    // This will only be reached if the data was not loaded from the list or the database
    return data;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, data, selectedItemId, dbParam]);
  // #endregion

  // #region [Update Action]
  // 'update' action
  // Call with updateCacheOnly=true to just update the cache and indexedDb without updating the cloud database
  // This will be used when updating internal nested object inside of compound objects, such as 
  // updating the brand object inside of a competitor object or the competitor object inside of a team object.
  const saveData = useCallback(async (updateObject: D = data, updateData: boolean = true, 
                                param: string = dbParam, updateCacheOnly: boolean = false) => {
    if (!canceled) {
      setSaving(true);
      if (resultMessage)
        setResultMessage(undefined);
      if (status)
        setStatus(undefined);
    }
    // Bail out if the data is not valid or the data has not changed
    if (!updateObject || !updateObject.hasOwnProperty('id') || updateObject.id <= 0 || 
          (updateData && isEqual(updateObject, data) && !isModified)) {
      setSaving(false);
      if (!canceled) {
        if (!updateObject) {
          setStatus('error');
          setResultMessage('No data to save');
          setHttpStatus(400);
        } else {
          setStatus('success');
          setResultMessage('No changes to save');
          setHttpStatus(201);
        }
      }
      return updateObject || initialData || {} as D;
    }
    // Update the data in the database
    try {
      var newData: D = {} as D;
      var httpStatus: number = 0;
      if (updateCacheOnly) {
        // console.debug("useDatabase: saveData updateCacheOnly " + dbName, updateObject);
        newData = updateObject;
        httpStatus = 200;
      } else {
        var result = (await api('update', updateObject, updateObject.id, param));
        newData = result.data as D;
        httpStatus = result.status;
      }
      // Update the indexedDb copy of the data with the response from the service
      if (indexedDb !== undefined) {
        indexedDb.update({id: (newData as D).id, data: newData as D, updateTime: new Date().getTime()});
        /* // For now we won't update the table-wide sync status, but we may want to do this in the future
        if (statusDb !== undefined) {
          statusDb.update({name: dbName, syncTime: new Date().getTime()});
        }
        */
      }
      if (!canceled) {
        setHttpStatus(httpStatus);
        if (updateData)
          setData(newData as D);
        // Update value of selectedItem in the list even if we don't update the data object
        // NOTE that this may cause isModified to become true if an updateObject is passed in
        // and that object has the same id as the selectedItemId.
        if (list) {
          const index = list.findIndex(item => item.id === newData.id);
          if (index >= 0) {
            if (updateCacheOnly) {
              // console.debug("useDatabase: saveData updateCacheOnly updating list item " + dbName, newData);
            }
            const newList = [...list];
            newList[index] = newData as D;
            setList(newList);
          }
        }
        if (!updateCacheOnly) {
          setStatus('success');
          setResultMessage('Successfully updated');
        }
      } else {
        console.debug("useDatabase: saveData canceled " + dbName, updateObject);
      }
      return newData as D;
    } catch (error: any) {
      if (!canceled) {
        setHttpStatus(error?.response?.status || 400);
        setStatus('error');
        setResultMessage(extractErrorMessage(error, 'Problem saving data'));
      }
    } finally {
      if (!canceled)
        setSaving(false);
    }
    return data || initialData || {} as D;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, data, selectedItemId, dbParam]);
  // #endregion

  // #region [Create Action]
  // This will be used to create a new item in the database
  // 'create' action
  const createData = useCallback(async (updateObject: D = data, updateData: boolean = true) => {
    if (!canceled) {
      setSaving(true);
      if (resultMessage)
        setResultMessage(undefined);
      if (status)
        setStatus(undefined);
    }
    // Bail out if the data is not valid or the data has not changed
    if (!updateObject || !updateObject.hasOwnProperty('id')) {
      if (!canceled) {
        setSaving(false);
        setStatus('error');
        setResultMessage('No data to save');
        setHttpStatus(400);
      }
      return updateObject || initialData || {} as D;
    }
    // Since we don't have an id for the new object yet, we can't add it to indexedDb until after we add it to the database
    try {
      var result = (await api('create', updateObject, updateObject.id, dbParam));
      // Add the new item to the indexedDb copy of the data
      if (indexedDb !== undefined) {
        indexedDb.add({id: (result.data as D).id, data: result.data as D, updateTime: new Date().getTime()});
        /* // For now we won't update the table-wide sync status, but we may want to do this in the future
        if (statusDb !== undefined) {
          statusDb.update({name: dbName, syncTime: new Date().getTime()});
        }
        */
      }
      if (!canceled) {
        setHttpStatus(result.status);
        if (updateData)
          setData(result.data as D);
        // Add new object to the list regardless of the value of updateData
        // TODO: If the updateObject is compound, but the result.data is not, we may need to update the result.data with the updateObject
        if (list) {
          const newList = [...list];
          newList.push(result.data as D);
          setList(newList);
        }
        if (updateData)
          setSelectedItemId((result.data as D).id);
        setStatus('success');
        setResultMessage('Successfully added');
      }
      return result.data as D;
    } catch (error: any) {
      if (!canceled) {
        setHttpStatus(error?.response?.status || 400);
        setStatus('error');
        setResultMessage(extractErrorMessage(error, 'Problem saving data'));
      }
    } finally {
      if (!canceled)
        setSaving(false);
    }
    return updateObject || initialData || {} as D;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, data, selectedItemId, dbParam, list]);
  // #endregion

  // #region [Delete Action]
  // 'delete' action
  const deleteData = useCallback(async (objectId: number = selectedItemId, updateData: boolean = true, param: string = dbParam) => {
    let responseValue: number = 200; // optomistic response value
    if (!canceled) {
      if (resultMessage)
        setResultMessage(undefined);
      if (status)
        setStatus(undefined);
    }
    try {
      var result = (await api('delete', data, objectId, param));
      // Delete the item from the indexedDb
      if (indexedDb !== undefined) {
        indexedDb.deleteRecord(objectId);
        /* // For now we won't update the table-wide sync status, but we may want to do this in the future
        if (statusDb !== undefined) {
          statusDb.update({name: dbName, syncTime: new Date().getTime()});
        }
        */
      }
      responseValue = result.status;
      if (!canceled) {
        setHttpStatus(result.status);
        // Update value of selectedItem in the list
        if (list) {
          const newList = list.filter(item => item.id !== objectId);
          setList(newList);
        }
        setStatus('success');
        // setResultMessage('Successfully deleted');
        if (objectId === selectedItemId || updateData) {
          setSelectedItemId(0);
        }
        if (updateData)
          setData(initialData || {} as D);
      }
    } catch (error: any) {
      responseValue = error?.response?.status || 400;
      if (!canceled) {
        setHttpStatus(error?.response?.status || 400);
        setStatus('error');
        setResultMessage(extractErrorMessage(error, 'Problem deleting data'));
      }
    }
    return responseValue;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, data, selectedItemId, dbParam]);
  // #endregion

  // #region [Initialization of database hook]
  const initialize = useCallback(async (objectId: number = selectedItemId, param: string = dbParam) => {
    if (!canceled) {
      if (resultMessage)
        setResultMessage(undefined);
      if (status)
        setStatus(undefined);
      setList(undefined);
      setCanceled(false);
    }
    await loadList(true, param);
    if (list && list.length > 0) {
      if (objectId || data) {
        const item = list.find(item => item.id === objectId || data.id);
        if (!canceled) {
          if (item) {
            setData(item); // Ensures the data object is synced with the database view of the item
            setSelectedItemId(item.id);
          } else {
            setData(initialData || {} as D);
            setSelectedItemId(0);
          }
        }
      }
    }
    if (param !== dbParam && !canceled)
      setDbParam(param);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // #endregion

  // #region [resetStatus]
  const clearStatus = useCallback(() => {
    if (!canceled) {
      if (status)
        setStatus(undefined);
      if (resultMessage)
        setResultMessage(undefined);
      if (httpStatus)
        setHttpStatus(0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // #region [useEffect Hook]
  /*
  useEffect(() => {
    setCanceled(false);
    setStatus(undefined);
    setResultMessage(undefined);
    setHttpStatus(0);
    // If action has not been set, no action is taken. This way the caller can change the 
    // selectedItemId or dbParam object without triggering an action
    console.debug("useDatabase: action ", action, " selectedItemId: ", selectedItemId, " dbParam: ", dbParam, " data: ", data, " list: ", list);
    switch (action) {
      case 'list':
        loadList(true);
        break;
      case 'read':
        loadData(selectedItemId, true);
        break;
      case 'update':
        saveData(data, true);
        break;
      case 'create':
        createData(data, true);
        break;
      case 'delete':
        deleteData(selectedItemId, true);
        break;
      case 'refresh':
        loadList(true);
        if (selectedItemId > 0) {
          loadData(selectedItemId, true);
        } else {
          setData(initialData || {} as D);
          // setDbView({} as D);
        }
        break;
      case 'revert':
        if (selectedItemId && list && isModified) {
          setData(list.find(item => item.id === selectedItemId) || initialData || ({} as D));
        }
        break;
      case 'init':
        initialize();
        break;
      default:
        break;
    }
    setAction('none');
    return () => {
      setCanceled(true);
    };
  }, [action, loadList, loadData, saveData, createData, deleteData, selectedItemId, dbParam, canceled]);
  */
  // #endregion
  // return a DatabaseHook object with the necessary properties and methods
  return {saving, data, list, resultMessage, status, param: dbParam, selectedItemId, httpStatus, isModified, 
    setData, setSelectedItemId: setSelectedItem, setParam, 
    read: loadData, getList: loadList, update: saveData, create: createData, delete: deleteData, init: initialize, clearStatus} as DatabaseHook<D>;
};
