import React from 'react';
import { SchoolData, GameRecord, TeamData, CompetitorData, SponsorData, BrandData, PlayerData } from './types';
import { DatabaseHook } from '../utils';

export interface SchoolContextValue {
  selectedSchool?: SchoolData;
  selectSchool: (school: SchoolData) => void;
  deselectSchool: () => void;
}

export interface GameContextValue {
  selectedGame?: GameRecord;
  selectGame: (game: GameRecord) => void;
  deselectGame: () => void;
  competitorMap: Map<number, CompetitorData>;
}

export interface TeamContextValue {
  teamList: TeamData[];
  selectedTeam?: TeamData;
  selectTeam: (team: TeamData) => void;
  deselectTeam: () => void;
}

export interface CompetitorContextValue {
  selectedCompetitor?: CompetitorData;
  selectCompetitor: (competitor: CompetitorData) => void;
  deselectCompetitor: () => void;
}

export interface DatabaseAccessContextValue {
  // Include a useDatabase instance for SponsorData and PlayerData types
  competitorDb: DatabaseHook<CompetitorData>;
  brandDb: DatabaseHook<BrandData>;
  gameDb: DatabaseHook<GameRecord>;
  playerDb: DatabaseHook<PlayerData>;
  sponsorDb: DatabaseHook<SponsorData>;
  teamDb: DatabaseHook<TeamData>;
}

const SchoolContextDefaultValue = {} as SchoolContextValue;
export const SchoolContext = React.createContext(
    SchoolContextDefaultValue
);

const GameContextDefaultValue = {} as GameContextValue;
export const GameContext = React.createContext(
  GameContextDefaultValue
);

const TeamContextDefaultValue = {} as TeamContextValue;
export const TeamContext = React.createContext(
  TeamContextDefaultValue
);

const CompetitorContextDefaultValue = {} as CompetitorContextValue;
export const CompetitorContext = React.createContext(
  CompetitorContextDefaultValue
);

const DatabaseAccessContextDefaultValue = {} as DatabaseAccessContextValue;
export const DatabaseAccessContext = React.createContext(
  DatabaseAccessContextDefaultValue
);