import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { SectionContent, FormLoader, ButtonRow, ValidatedTextField } from '../components';
import { numberValue, updateValue } from '../utils';
import { PlayerData, ImageLocationItem, defaultPlayer } from './types';
import { TeamContext, DatabaseAccessContext } from './SchoolContext';
// import { useSnackbar } from 'notistack';
import { PLAYER_DATA_VALIDATOR } from './validators';
import { ValidateFieldsError } from 'async-validator';
import { validate } from "../validators";
import { AuthenticatedContext } from "../contexts/authentication";
import { ACCESS_TOKEN } from "../api/endpoints";
import { ImageSelectorProps } from './ImageSelector';
import ImageSelectorComponent from './ImageSelector';

const PlayerSetupForm: FC = () => {
  const { selectedTeam } = useContext(TeamContext);
  const { id } = useParams();
  const idNum: number = parseInt(id) || 0;
  const db = useContext(DatabaseAccessContext);
  const { token } = useContext(AuthenticatedContext);
  const [initialized, setInitialized] = useState(false);
  const [label, setLabel] = useState("Player UI Info");
  const [startingName, setStartingName] = useState("");
  const [cancelled, setCancelled] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const adding: boolean = !(idNum > 0);
  const newPlayer = { ...defaultPlayer } as PlayerData;
  localStorage.setItem(ACCESS_TOKEN, token);

  const goBack = () => {
    navigate("../list");
  };

  // Effect to run on component mount to make sure data is loaded
  useEffect(() => {
    const loadData = async () => {
      console.debug("Initial load data: ", db.playerDb.data, "id: ", id);
      if (adding) {
        db.playerDb.setData({...newPlayer, cid: selectedTeam?.cid, tid: selectedTeam?.id});
        setInitialized(true);
      } else {
        let dbItem = await db.playerDb.read(idNum, true);
        if (dbItem?.id > 0 && dbItem.id === idNum) {
          setStartingName(dbItem.name);
          setInitialized(true);
        } else {
          console.debug("PlayerSetupForm: data not loaded in initialization useEffect: ", id, idNum, db.playerDb.data);
        }
      }
    };
    loadData();
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Effect to run when the data is loaded into the useDatabase hook
  useEffect(() => {
    console.debug("*SetupForm: selected item loaded from DB: ", db.playerDb.data, "id: ", id, "initialized: ", initialized);
    if (!initialized) {
      if (db.playerDb.data?.id > 0 && db.playerDb.data.id === idNum) {
        setStartingName(db.playerDb.data.name);
        setInitialized(true);
      }
    }
    return () => {
      // Do any needed cleanup work
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db.playerDb.data, id]);

  useEffect(() => {
    console.debug("PlayerSetupForm: selectedPlayer changed: ", startingName, initialized, adding)
    if (initialized) {
      if (adding) {
        setLabel("New Player Setup");
      } else if (startingName !== "") {
        setLabel("Player Info - " + startingName);
      } else {
        setLabel("Player Info");
      }
    }
    return () => {
      // Do any needed cleanup work
      setCancelled(true);
    };
  }, [initialized, adding, startingName]);

  const updateFormValue = (event: any) => {
    updateValue(event);
    if (event != null) {
      console.debug("PlayerSetupForm: updateFormValue: ", event.target.name, event.target.value, initialized);
      db.playerDb.setData({ ...db.playerDb.data, [event.target.name]: event.target.value });
    }
  };
  const navigate = useNavigate();
  // const { enqueueSnackbar } = useSnackbar();

  const processSave = async () => {
    if (adding) {
      console.debug("PlayerSetupForm: processSave: player to create: ", db.playerDb.data);
      await db.playerDb.create(db.playerDb.data, true);
    } else {
      if (db.playerDb.isModified) {
        console.debug("PlayerSetupForm: processSave: player changed: ", db.playerDb.data);
        await db.playerDb.update();
      }
    }
    navigate('../list');
  };

  const validateAndSubmit = async () => {
    try {
      setFieldErrors(undefined);
      console.debug("Player data: ", db.playerDb.data);
      if (db.playerDb.data) {
        await validate(PLAYER_DATA_VALIDATOR(db.playerDb.data), db.playerDb.data);
        await processSave();
      } else {
        // enqueueSnackbar("No player data", { variant: "warning" });
      }
    } catch (errors: any) {
      setFieldErrors(errors);
    }
  };

  const processDelete = async () => {
    console.log("Player to delete: %d", db.playerDb.data?.id);
    if (db.playerDb.data && selectedTeam) {
      db.playerDb.delete(db.playerDb.data.id, true, selectedTeam.id.toString());
    }
    navigate("../list");
  };

  // If selectedTeam changes navigate back to list
  useEffect(() => {
     if (initialized && !cancelled) {
        navigate("../list");
      };
      return () => {
        // Do any needed cleanup work
        setCancelled(true);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeam, navigate]);

  // useEffect(() => deselectPlayer, [deselectPlayer]);

  const imageSelectProps = {
    folder: "players",
    label: "Player Image",
    image: db.playerDb.data?.image,
    filter: {key: "teamid", value: selectedTeam?.id.toString() || "0"},
    onSelect: (item: ImageLocationItem) => { 
      db.playerDb.setData({ ...db.playerDb.data, image: item.name })},
  } as ImageSelectorProps;

  const content = () => {
    if (!initialized || !db.playerDb.data) {
      console.debug("PlayerSetupForm: content: Not initialized: ", db.playerDb.data);
        return (<FormLoader onRetry={() => {db.playerDb.read();}} errorMessage={(!db.playerDb.data) ? "Retry loading player data ..." : undefined} />);
    }

    return (
      <>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          name="name"
          label="Name"
          fullWidth
          variant="outlined"
          value={db.playerDb.data?.name}
          onChange={updateFormValue}
          margin="normal"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          name="jersey"
          label="Jersey number (0-199)"
          fullWidth
          variant="outlined"
          value={numberValue(db.playerDb.data?.jersey)}
          onChange={updateFormValue}
          margin="normal"
        />
        <ImageSelectorComponent {...imageSelectProps} />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          name="position"
          label="Position"
          fullWidth
          variant="outlined"
          value={db.playerDb.data?.position}
          onChange={updateFormValue}
          margin="normal"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          name="height"
          label={"Height (e.g. 6'2\")"}
          fullWidth
          variant="outlined"
          value={db.playerDb.data?.height}
          onChange={updateFormValue}
          margin="normal"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          name="weight"
          label="Weight"
          fullWidth
          variant="outlined"
          value={numberValue(db.playerDb.data?.weight)}
          onChange={updateFormValue}
          margin="normal"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          name="year"
          label="Year"
          fullWidth
          variant="outlined"
          value={numberValue(db.playerDb.data?.year)}
          onChange={updateFormValue}
          margin="normal"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          name="school"
          label="School/Team"
          fullWidth
          variant="outlined"
          value={db.playerDb.data?.school}
          onChange={updateFormValue}
          margin="normal"
        />
        <ButtonRow mt={1}>
          <Button startIcon={<CancelIcon />} disabled={db.playerDb.saving || !db.playerDb.isModified} variant="contained" color="primary" type="button" onClick={goBack}>
            Cancel
          </Button>
          <Button startIcon={<DeleteIcon />} disabled={db.playerDb.saving || idNum <= 0} variant="contained" color="primary" type="button" onClick={processDelete}>
            Delete
          </Button>
          <Button startIcon={<SaveIcon />} disabled={db.playerDb.saving || !db.playerDb.isModified} variant="contained" color="primary" type="submit" onClick={validateAndSubmit}>
            Save
          </Button>
        </ButtonRow>
      </>
    );
  };

  return (
    <SectionContent title={label} titleGutter>
      {content()}
    </SectionContent>
  );
};

export default PlayerSetupForm;
